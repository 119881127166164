/* eslint-disable @typescript-eslint/unbound-method, no-param-reassign */ import { useContext, useEffect, useMemo } from "react";
import { NavigationListContext } from "./NavigationListContext";
/**
 * ActionRegistryGlobalHandlers are responsible for handling actions that are
 * not handled by the individual lists themselves; it's also the only way of
 * supporting multiselect actions.
 *
 * If multiple ActionRegistryGlobalHandlers are registered, the order in which
 * they are called is undefined, so make sure that ordering issues do not
 * occur.
 */ export default function useActionRegistryGlobalHandlers(props) {
    const { actions , performAction , actionWasPerformed  } = props;
    const globalHandler = useMemo(()=>({
            actions,
            performAction,
            actionWasPerformed
        }), [
        actions,
        performAction,
        actionWasPerformed
    ]);
    const { registerGlobalHandler  } = useContext(NavigationListContext);
    useEffect(()=>registerGlobalHandler(globalHandler), [
        globalHandler,
        registerGlobalHandler
    ]);
};
