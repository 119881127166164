function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
export default function loadIntercom(APP_ID) {
    const w = window;
    const d = document;
    function l() {
        var ref;
        const s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${APP_ID}`;
        const x = d.getElementsByTagName("script")[0];
        (ref = x.parentNode) === null || ref === void 0 ? void 0 : ref.insertBefore(s, x);
    }
    const ic = w.Intercom;
    if (typeof ic === "function") {
        // @ts-ignore
        ic("reattach_activator");
        ic("update", w.intercomSettings);
    } else {
        const i = (...args)=>i.c(args);
        i.q = [];
        i.c = (args)=>{
            i.q.push(args);
        };
        w.Intercom = i;
        // @ts-ignore
        if (w.attachEvent) // @ts-ignore
        w.attachEvent("onload", l);
        else w.addEventListener("load", l, false);
    }
    return w.Intercom;
};
let isIntercomBooted = false;
export function bootIntercom(currentUser) {
    if (currentUser) {
        const { primaryEmail , id , createdAt , admin , firstName , lastName , website , bio , avatar ,  } = currentUser;
        window.intercomSettings = {
            email: primaryEmail ? primaryEmail.email : undefined,
            user_id: String(id),
            created_at: createdAt || undefined,
            admin,
            first_name: firstName,
            last_name: lastName,
            website,
            bio,
            avatar,
            active: currentUser.active
        };
        if (!isIntercomBooted && typeof window.Intercom === "function") {
            window.Intercom("boot", _objectSpread({}, window.intercomSettings, {
                app_id: window.SPROUT_CONFIG.INTERCOM_APP_ID,
                user_hash: window.SPROUT_USER_CONFIG.INTERCOM_USER_HASH,
                custom_launcher_selector: "#IntercomDefaultWidget",
                hide_default_launcher: true
            }));
            isIntercomBooted = true;
        }
    } else window.intercomSettings = {};
    if (typeof window.Intercom === "function") window.Intercom("update", window.intercomSettings);
}
