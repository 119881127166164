import { createGlobalStyle as css } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
const GlobalStyles = css`
  html {
    box-sizing: border-box;
    height: 100%;
    text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 100%;
    line-height: 1.15;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    min-height: 100%;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
      Arial, sans-serif;

    color: ${themeGet("colors.black.87")};
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  button {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
  }

  button,
  input,
  textarea {
    font-family: inherit;
    font-size: inherit;
  }
`;
export default GlobalStyles;
