const isOSX = typeof navigator !== "undefined" ? /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) : false;
export function isCtrlKeyCommand(event) {
    return !!event.ctrlKey && !event.altKey;
}
export function hasCommandModifier(event) {
    return isOSX ? !!event.metaKey && !event.altKey : isCtrlKeyCommand(event);
}
export { default as getReadableDescription } from "./getReadableDescription";
export { default as getItemUrl } from "./getItemUrl";
export { default as isNotNull, isNotNullish } from "./isNotNull";
export { default as isPresent } from "./isPresent";
export { default as FriendlyPoller } from "./FriendlyPoller";
export { default as getHistory } from "./history";
export { allowedNumberOfPrivateTopics } from "./allowedNumberOfPrivateTopics";
export { default as slugify } from "./slugify";
export { default as checkIsSproutUrl } from "./checkIsSproutUrl";
