export const grey = {
    50: "#fafafa",
    100: "#f5f5f5",
    200: "#eeeeee",
    300: "#e0e0e0",
    400: "#bdbdbd",
    500: "#9e9e9e",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121"
};
export const black = {
    100: "rgba(0, 0, 0, 1)",
    87: "rgba(0, 0, 0, 0.87)",
    60: "rgba(0, 0, 0, 0.60)",
    54: "rgba(0, 0, 0, 0.54)",
    42: "rgba(0, 0, 0, 0.42)",
    40: "rgba(0, 0, 0, 0.40)",
    38: "rgba(0, 0, 0, 0.38)",
    30: "rgba(0, 0, 0, 0.30)",
    24: "rgba(0, 0, 0, 0.24)",
    16: "rgba(0, 0, 0, 0.16)",
    14: "rgba(0, 0, 0, 0.14)",
    12: "rgba(0, 0, 0, 0.12)",
    10: "rgba(0, 0, 0, 0.10)",
    9: "rgba(0, 0, 0, 0.09)",
    8: "rgba(0, 0, 0, 0.08)",
    // 6: "rgba(0, 0, 0, 0.06)",
    6: "#FDF6F0",
    5: "rgba(0, 0, 0, 0.05)",
    4: "rgba(0, 0, 0, 0.04)"
};
export const white = {
    100: "rgba(255, 255, 255, 1)",
    95: "rgba(255, 255, 255, 0.95)",
    87: "rgba(255, 255, 255, 0.87)",
    80: "rgba(255, 255, 255, 0.80)",
    70: "rgba(255, 255, 255, 0.70)",
    60: "rgba(255, 255, 255, 0.60)",
    54: "rgba(255, 255, 255, 0.54)",
    40: "rgba(255, 255, 255, 0.40)",
    12: "rgba(255, 255, 255, 0.12)",
    4: "rgba(255, 255, 255, 0.04)"
};
export const blue = {
    // 40: "#a2fff6", // 85.86%
    40: "#fff",
    50: "#dbeaf9",
    100: "#4991cc",
    200: "#3785c4",
    300: "#3177b1",
    400: "#1565c0",
    // 500: "#3147ef", // 11.39%
    500: "#269388"
};
export const yellow = {
    50: "#fff3ca",
    100: "#f8f2e4",
    200: "#ffd953"
};
