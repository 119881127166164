import { themeGet } from "sprout-ui-theme";
import { Flex } from "sprout-ui-primitives";
import styled from "styled-components";
const PageActions = styled(Flex)`
  display: flex;
  flex-direction: column;
  background: #f7f8fc;
  border-radius: 12px;

  > * {
    :first-child {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    :last-child {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  > * + * {
    &::before {
      position: absolute;
      content: "";
      height: 1px;
      background: ${themeGet("colors.black.12")};
      top: 0;
      left: ${({ offsetIcon =true  })=>offsetIcon ? "64px" : 0};
      right: 0;
    }
  }
`;
export default PageActions;
