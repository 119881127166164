import { unescapeBrackets } from "../utils";
import format from "./format";
import processOutputMarkdown from "./processOutputMarkdown";
export const num2alpha = (number)=>String(number).replace(/[0-9]/g, (numeral)=>({
            1: "a",
            2: "b",
            3: "c",
            4: "d",
            5: "e",
            6: "f",
            7: "g",
            8: "h",
            9: "i",
            0: "j"
        })[numeral]);
export default function formatToRawDraftJS(text, // mode?: "BASIC" | "SIMPLE" | "FULL"
mode) {
    const result = {
        blocks: [],
        entityMap: {}
    };
    let pos;
    let output;
    let block;
    function addRaw(rawText) {
        pos += rawText.length;
        output += rawText;
    }
    function addOutput(outputText) {
        const textWithUnescapedBrackets = unescapeBrackets(outputText);
        const { text: processedText , styles , charactersProcessed ,  } = processOutputMarkdown(output.length, textWithUnescapedBrackets);
        pos += outputText.length - charactersProcessed;
        output += processedText;
        block.inlineStyleRanges.push(...styles);
    }
    let nextKey = 0;
    function addEntity(offset, length, entity) {
        result.blocks[0].entityRanges.push({
            offset,
            length,
            key: nextKey
        });
        result.entityMap[nextKey] = entity;
        nextKey++;
    }
    function addSpecial(type, data, fallback) {
        if (type === "mention") {
            const { userId: id , displayText , username: name  } = data;
            if (displayText) {
                const posBefore = pos;
                addOutput(displayText);
                addEntity(posBefore, pos - posBefore, {
                    type: "mention",
                    mutability: "IMMUTABLE",
                    data: {
                        mention: {
                            // TODO: Convert to immutable?
                            name,
                            id
                        }
                    }
                });
            } else addRaw(`[someone]`);
        } else if (type === "raw") addRaw(fallback);
        else addOutput(fallback);
    }
    function sealBlock() {
        if (block) block.text = output;
    }
    let key = 11111;
    function addBlock(type = "unstyled") {
        sealBlock();
        output = "";
        pos = 0;
        block = {
            key: num2alpha(key),
            text: "",
            type,
            entityRanges: [],
            inlineStyleRanges: []
        };
        key += 1;
        result.blocks.push(block);
    }
    format(text, {
        addOutput,
        addSpecial,
        addBlock
    }, mode);
    sealBlock();
    // Failsafe: draftJS will crash if there's not at least one block, so populate
    // a block with the text we were given.
    if (result.blocks.length === 0) {
        // This SHOULD never be needed.
        console.error("ERROR: formatToRawDraftJS failsafe was invoked; bad copy follows (JSON.stringified):");
        console.error(JSON.stringify(text));
        addBlock();
        output = String(text || "");
        sealBlock();
    }
    return result;
};
