import linkify from "../../linkify";
import { escapeBrackets, trimWww, trimProtocol } from "../utils";
function applyLengthLimit(text, limit = 43) {
    if (text.length < limit + 3) return text;
    else return `${text.substr(0, limit)}…`;
}
export function formatURL(href) {
    return applyLengthLimit(trimWww(trimProtocol(href)));
}
const openMarkdownFromStyle = {
    BOLD: "*",
    ITALIC: "_",
    STRIKETHROUGH: "~",
    CODE: "`"
};
function isAKey(obj, candidate) {
    return candidate in obj;
}
function asKey(obj, candidate) {
    return isAKey(obj, candidate) ? candidate : null;
}
const closeMarkdownFromStyle = openMarkdownFromStyle;
function extractLinkFromText(inText) {
    let text = inText;
    const links = linkify.match(text);
    let firstLink = null;
    let hasNonLinkText = false;
    if (links && links.length) for(let i = links.length - 1; i >= 0; i--){
        const link = links[i];
        const { schema , index , lastIndex , // raw: _raw,
        text: normalizedText , url ,  } = link;
        if ([
            "",
            "http:",
            "https:"
        ].includes(schema)) {
            firstLink = url; // Always overwrite because we're looping backwards
            if (index !== 0 || lastIndex !== text.length) hasNonLinkText = true;
            const origText = escapeBrackets(formatURL(normalizedText));
            const orig = origText === url ? "" : `|${origText}`;
            const head = text.substr(0, index);
            const end = lastIndex;
            const tail = text.substr(end);
            text = `${head}<${url}${orig}>${tail}`;
        }
    }
    else if (text.length > 0) hasNonLinkText = true;
    return {
        link: firstLink,
        text,
        hasNonLinkText
    };
}
// TODO: make this more efficient!
function markdownify(text, startOffset, inlineStyleRanges) {
    const output = [];
    for(let i = 0, l = text.length; i < l; i++){
        // Loop forwards
        for(let styleIndex = 0, styleCount = inlineStyleRanges.length; styleIndex < styleCount; styleIndex++){
            const inlineStyle = inlineStyleRanges[styleIndex];
            if (inlineStyle.offset === i + startOffset) {
                const key = asKey(openMarkdownFromStyle, inlineStyle.style);
                const open = key ? openMarkdownFromStyle[key] : null;
                if (open) output.push(open);
            }
        }
        output.push(text[i]);
        // Loop backwards so the symbols balance correctly
        for(let styleIndex1 = inlineStyleRanges.length - 1; styleIndex1 >= 0; styleIndex1--){
            const inlineStyle = inlineStyleRanges[styleIndex1];
            if (inlineStyle.offset + inlineStyle.length - 1 === i + startOffset) {
                const key = asKey(closeMarkdownFromStyle, inlineStyle.style);
                const close = key ? closeMarkdownFromStyle[key] : null;
                if (close) output.push(close);
            }
        }
    }
    return output.join("");
}
export default function parseText(sourceText, returnTextIfEmpty = false, startOffset = 0, inlineStyleRanges = []) {
    const markdownifiedText = markdownify(sourceText, startOffset, inlineStyleRanges);
    // Escape brackets, but ensure we keep the '>' at the beginning of lines for quoting.
    const placeholder = String(Math.random());
    const escapedText = escapeBrackets(markdownifiedText.replace(/(^|\n)>/g, `$1${placeholder}`)).replace(new RegExp(placeholder, "g"), ">");
    const { link: firstLink , text , hasNonLinkText ,  } = extractLinkFromText(escapedText);
    return {
        text: returnTextIfEmpty || hasNonLinkText ? text : "",
        link: firstLink,
        hasNonLinkText
    };
};
