import { Box } from "sprout-ui-primitives";
import { themeGet } from "sprout-ui-theme";
import styled from "styled-components";
const LandingVideo = styled(Box)`
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  box-shadow: 0 1px 20px ${themeGet("colors.black.10")};

  video,
  iframe {
    /* https://stackoverflow.com/questions/20771971/video-player-shows-black-border */
    clip-path: inset(1px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
LandingVideo.defaultProps = {
    borderRadius: "30px"
};
export default LandingVideo;
