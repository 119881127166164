import styled, { css, keyframes } from "styled-components";
import { color, height, width, fontSize, fontWeight } from "styled-system";
import shouldForwardProp from "@styled-system/should-forward-prop";
import theme, { media, themeGet } from "sprout-ui-theme";
const pulsingGlow = keyframes`
  0% {
    box-shadow: 0 0 0 0 ${theme.colors.blue[500]};
  }

  33% {
    box-shadow: 0 0 3px 5px ${theme.colors.blue[500]};
  }
`;
export const pulseStyles = css`
  animation: ${pulsingGlow} 1.3s infinite both;
`;
const disableVariantStyles = css`
  :disabled {
    color: ${themeGet("colors.black.16")};
    background: ${themeGet("colors.grey.100")};
    border-color: ${themeGet("colors.grey.100")};
  }
`;
export const baseVariantStyles = css`
  justify-content: center;

  height: 36px;
  padding: 0 16px;
  border-radius: 30px;
  font-size: 15px;
  font-weight: ${themeGet("fontWeights.bold")};
  letter-spacing: 0.04em;
  transition: background 0.15s linear, border-color 0.15s linear,
    color 0.15s linear;

  ${disableVariantStyles};
`;
const focusStyles = css`
  .js-focus-visible & {
    :focus:not(.focus-visible) {
      outline: 0;
    }
    &.focus-visible {
      outline: 0;
      box-shadow: ${themeGet("colors.white.100")} 0 0 0 2px,
        ${themeGet("colors.blue.500")} 0 0 0 4px;
    }
  }
`;
const Button = styled("button").attrs(({ type  })=>({
        type: type || "button"
    })).withConfig({
    shouldForwardProp: (prop)=>shouldForwardProp(prop)
})`
  display: inline-flex;
  align-items: center;
  padding: 0;
  user-select: none;
  appearance: none;
  text-decoration: none;
  border: 0;
  background: transparent;

  * {
    pointer-events: none;
  }

  ${focusStyles};

  ${({ pulse  })=>pulse && pulseStyles};

  ${media.target("pointer")} {
    :not(:disabled) {
      cursor: pointer;
    }

    :disabled {
      cursor: not-allowed;
    }
  }

  ${({ variant  })=>variant === "bright-blue" && css`
      ${baseVariantStyles};
      color: ${themeGet("colors.white.100")};
      background: ${themeGet("colors.blue.500")};

      :not(:disabled) {
        ${media.target("pointer")} {
          :hover {
            background: #2c3fd7;
          }
        }

        :active {
          background: #2738bf;
        }
      }
    `}

    ${({ variant  })=>variant === "orange" && css`
        ${baseVariantStyles};
        color: ${themeGet("colors.white.100")};
        background: #f68639;

        :not(:disabled) {
          ${media.target("pointer")} {
            :hover {
              background: #dd7833;
            }
          }

          :active {
            background: #c46b2d;
          }
        }
      `}

  ${({ variant  })=>variant === "bright-teal" && css`
      ${baseVariantStyles};
      color: ${themeGet("colors.black.87")};
      background: ${themeGet("colors.blue.40")};

      :not(:disabled) {
        ${media.target("pointer")} {
          :hover {
            background: #91e5dd;
          }
        }

        :active {
          background: #81ccc4;
        }
      }
    `}

    ${({ variant  })=>variant === "purple" && css`
        ${baseVariantStyles};
        color: ${themeGet("colors.black.87")};
        background: #f0e5fe;

        :not(:disabled) {
          ${media.target("pointer")} {
            :hover {
              background: #d8cee4;
            }
          }

          :active {
            background: #c0b7cb;
          }
        }
      `}

    ${({ variant  })=>variant === "yellow" && css`
        ${baseVariantStyles};
        color: ${themeGet("colors.black.87")};
        background: #ffd953;

        :not(:disabled) {
          ${media.target("pointer")} {
            :hover {
              background: #f0cd50;
            }
          }

          :active {
            background: #d6b84b;
          }
        }
      `}

    ${({ variant  })=>variant === "dark-purple" && css`
        ${baseVariantStyles};
        color: ${themeGet("colors.white.100")};
        // background: #822af1;
        background: #269388;

        :not(:disabled) {
          ${media.target("pointer")} {
            :hover {
              // background: #7525d8;
              background: #1b746b;
            }
          }

          :active {
            // background: #6821c0;
            background: #1d5f58;
          }
        }
      `}

    ${({ variant  })=>variant === "black" && css`
        ${baseVariantStyles};
        color: ${themeGet("colors.white.100")};
        background: ${themeGet("colors.primary")};

        :not(:disabled) {
          ${media.target("pointer")} {
            :hover {
              background: ${themeGet("colors.grey.800")};
            }
          }

          :active {
            background: ${themeGet("colors.grey.700")};
          }
        }
      `}

    ${({ variant  })=>variant === "green" && css`
        ${baseVariantStyles};
        color: ${themeGet("colors.white.100")};
        background: #72c467;

        :not(:disabled) {
          ${media.target("pointer")} {
            :hover {
              background: #6ebb55;
            }
          }

          :active {
            background: #62a64c;
          }
        }
      `}

    ${({ variant  })=>variant === "outline" && css`
        color: ${themeGet("colors.blue.500")};
        ${baseVariantStyles};
        border: 1px solid currentColor;
      `}


    ${({ variant  })=>variant === "flat" && css`
        ${baseVariantStyles}
        color: ${themeGet("colors.black.60")};

        :not(:disabled) {
          ${media.target("pointer")} {
            :hover {
              background: ${themeGet("colors.grey.100")};
            }
          }

          :active {
            background: ${themeGet("colors.grey.300")};
          }
        }

        :disabled {
          color: ${themeGet("colors.black.12")};
          cursor: not-allowed;
        }
      `}

    ${({ variant  })=>{
    return (variant === null || variant === void 0 ? void 0 : variant.includes("circle")) && css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: ${themeGet("colors.black.60")};
        width: 48px;
        height: 48px;
        border-radius: 50%;
        transition: background 0.15s linear;

        :not(:disabled) {
          ${media.target("pointer")} {
            :hover {
              background: ${themeGet(variant === "circle" ? "colors.grey.100" : "colors.black.16")};
            }
          }

          :active {
            background: ${themeGet(variant === "circle" ? "colors.grey.300" : "colors.black.24")};
          }
        }

        :disabled {
          color: ${themeGet("colors.black.24")};
        }
      `;
}}


  ${({ size  })=>size === "sm" && css`
      height: 32px;
    `}

  ${color};
  ${height};
  ${width};
  ${fontSize};
  ${fontWeight};
`;
export default Button;
