import { Text } from "sprout-ui-primitives";
import styled from "styled-components";
const LandingH3 = styled(Text).attrs({
    as: "h3"
})``;
LandingH3.defaultProps = {
    lineHeight: 1,
    textAlign: "center",
    fontSize: [
        4,
        null,
        5
    ],
    fontWeight: "bold"
};
export default LandingH3;
