import { usePrevious } from "sprout-hooks";
import { useEffect } from "react";
import { MoveItemsDialogView } from "./MoveItemsDialogContext";
const MoveItemsDialogReset = (props)=>{
    const { clearSelection , view  } = props;
    const prevView = usePrevious(view);
    useEffect(()=>{
        if (prevView === MoveItemsDialogView.ADD && view === MoveItemsDialogView.CLEAN) // reset
        clearSelection();
    }, [
        clearSelection,
        prevView,
        view
    ]);
    return null;
};
export default MoveItemsDialogReset;
