import { themeGet } from "sprout-ui-theme";
import styled from "styled-components";
import { ScrollContainer } from "../ScrollContainer";
const BottomSheetSheet = styled(ScrollContainer)`
  position: fixed;
  left: 50%;
  bottom: 0;

  max-width: 788px;
  max-height: calc(100% - 56px);
  width: 100%;

  z-index: 101;

  box-shadow: 0 1px 4px ${themeGet("colors.black.16")};

  opacity: 0.010000001;
  transform: translate3d(-50%, 100%, 0);
  transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1),
    opacity 0.15s cubic-bezier(0.4, 0, 1, 1);

  [class*="enter-"] & {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
    transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
  }
`;
// https://github.com/styled-system/styled-system/issues/1058
BottomSheetSheet.defaultProps = {
    bg: "white.100",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px"
};
export default BottomSheetSheet;
