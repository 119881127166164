function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import { isNotNull } from "sprout-lib";
import { useCallback, useRef, useState } from "react";
import isURL from "validator/lib/isURL";
/**
 * Does nothing; NO OPeration -> NOOP
 */ export function noop() {
/* noop */ }
const getTypeFromItem = (item)=>{
    const { kind , type  } = item;
    if (kind === "string") {
        if (type === "text/uri-list") return "url";
        if (type === "text/plain") return "text";
    }
    return "unknown";
};
const isSproutUrl = (url)=>{
    try {
        const { host  } = new URL(url);
        const { host: rootHost  } = new URL(process.env.ROOT_URL);
        if (host === rootHost || host === "sprout.io") return true;
        else return false;
    } catch (e) {
        return true;
    }
};
export default function usePageDroppable({ onDrop: onDropProp  } = {}) {
    const [dragging, setDragging] = useState(false);
    const draggingRef = useRef(dragging);
    const onDragOver = useCallback((event)=>{
        event.preventDefault();
        if (!draggingRef.current) {
            draggingRef.current = true;
            setDragging(true);
        }
    }, []);
    const onDragLeave = useCallback((event)=>{
        if (event.target !== event.currentTarget) return;
        if (draggingRef.current) {
            draggingRef.current = false;
            setDragging(false);
        }
    }, []);
    const onDrop = useCallback(function() {
        var _ref = _asyncToGenerator(function*(event) {
            event.preventDefault();
            if (draggingRef.current) {
                draggingRef.current = false;
                setDragging(false);
            }
            const items = yield Promise.all(Array.from(event.dataTransfer.items).map((item)=>{
                const type = getTypeFromItem(item);
                if ([
                    "text",
                    "url"
                ].includes(type)) return new Promise((resolve)=>{
                    return item.getAsString((content)=>{
                        if (type === "text" && isURL(content)) {
                            resolve({
                                type: "url",
                                content
                            });
                            return;
                        }
                        resolve({
                            type,
                            content
                        });
                    });
                });
                return null;
            }));
            // (text and urls) we assume there's only one thing
            const [droppedItem] = items.filter(isNotNull).filter((item)=>{
                if (item.type === "url" && isSproutUrl(item.content)) return false;
                return true;
            });
            if (onDropProp && droppedItem) onDropProp(droppedItem);
        });
        return function(event) {
            return _ref.apply(this, arguments);
        };
    }(), [
        onDropProp
    ]);
    return {
        isDraggingOver: dragging,
        onDrop: onDropProp ? onDrop : noop,
        onDragOver: onDropProp ? onDragOver : noop,
        onDragLeave: onDropProp ? onDragLeave : noop
    };
};
