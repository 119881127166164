import { Flex } from "sprout-ui-primitives";
import styled from "styled-components";
export const MenuMenu = styled(Flex)`
  min-width: ${({ $size  })=>$size === "lg" ? "320px" : $size === "md" ? "280px" : $size || 0};
  max-height: inherit;
`;
MenuMenu.defaultProps = {
    overflow: "hidden",
    flexDirection: "column",
    bg: "blue.500",
    pt: 3,
    pb: 3,
    borderRadius: "30px"
};
