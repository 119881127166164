import parseTipTapRaw from "sprout-lib/dist/parser/parse/tiptap";
export const emptyEditorState = {
    type: "doc",
    content: [
        {
            type: "paragraph"
        }, 
    ]
};
export function getEditorStateHelpers(editor) {
    return {
        setState: (content)=>{
            editor === null || editor === void 0 ? void 0 : editor.commands.setContent(content);
        },
        clearState: ()=>{
            editor === null || editor === void 0 ? void 0 : editor.commands.clearContent();
        },
        getStateAsJson: ()=>{
            return (editor === null || editor === void 0 ? void 0 : editor.getJSON()) || emptyEditorState;
        },
        getStateAsText: (returnTextIfEmpty)=>{
            return parseTipTapRaw({
                type: "tiptap",
                json: (editor === null || editor === void 0 ? void 0 : editor.getJSON()) || emptyEditorState
            }, returnTextIfEmpty);
        },
        blur: ()=>{
            editor === null || editor === void 0 ? void 0 : editor.commands.blur();
        },
        focus: ()=>{
            editor === null || editor === void 0 ? void 0 : editor.commands.focus("end");
        },
        select: ()=>{
            if (editor === null || editor === void 0 ? void 0 : editor.isEmpty) editor.commands.focus("end");
            else editor === null || editor === void 0 ? void 0 : editor.commands.focus("all");
        },
        isEmpty: ()=>{
            return !!(editor === null || editor === void 0 ? void 0 : editor.isEmpty);
        },
        isFocused: ()=>{
            return !!(editor === null || editor === void 0 ? void 0 : editor.isFocused);
        }
    };
}
