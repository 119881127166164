import { Flex } from "sprout-ui-primitives";
import { media, themeGet } from "sprout-ui-theme";
import styled, { css } from "styled-components";
export const DialogRoot = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 101;

  pointer-events: none;

  ${media.up("small")} {
    align-items: flex-start;
  }

  ${({ $variant  })=>$variant === "confirmation" && css`
      background: ${themeGet("colors.black.10")};
      pointer-events: auto;
    `}
`;
export const DialogWrapper = styled(Flex)`
  flex-direction: column;

  color: ${themeGet("colors.white.100")};
  background: ${themeGet("colors.blue.500")};
  box-shadow: 0 1px 4px ${themeGet("colors.black.16")};

  pointer-events: auto;

  ${({ $variant  })=>$variant === "confirmation" ? css`
          margin: auto;
          max-width: 400px;
          width: calc(100% - 32px);
          border-radius: 30px;
        ` : css`
          width: 100%;

          ${media.up("small")} {
            margin: 56px auto 24px;
            width: 400px;
            border-radius: 30px;
            max-height: calc(100% - 80px);
            padding: 0 16px;
          }
        `}
`;
