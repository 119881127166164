import { useContext, createContext } from "react";
import { SearchScope } from "./types";
const SearchContext = createContext({
    searchScope: SearchScope["my-trees"],
    setSearchScope: ()=>undefined
});
export function useSearchContext() {
    return useContext(SearchContext);
}
export const SearchProvider = SearchContext.Provider;
export const SearchConsumer = SearchContext.Consumer;
