import { useState } from "react";
import { createContainer } from "unstated-next";
export var MoveItemsDialogView;
(function(MoveItemsDialogView) {
    MoveItemsDialogView[MoveItemsDialogView["CLEAN"] = 0] = "CLEAN";
    MoveItemsDialogView[MoveItemsDialogView["SCOPED"] = 1] = "SCOPED";
    MoveItemsDialogView[MoveItemsDialogView["ADD"] = 2] = "ADD";
})(MoveItemsDialogView || (MoveItemsDialogView = {}));
const MoveItemsDialogContext = createContainer(({ itemNodeIds , itemIds , initialCurrentTopicId  } = {
    itemIds: [],
    itemNodeIds: []
})=>{
    const [currentTopicId, setCurrentTopicId] = useState(initialCurrentTopicId);
    const [view, setView] = useState(MoveItemsDialogView.CLEAN);
    return {
        itemNodeIds,
        itemIds,
        initialCurrentTopicId,
        currentTopicId,
        setCurrentTopicId,
        view,
        setView
    };
});
export const useMoveItemsDialog = MoveItemsDialogContext.useContainer;
export const MoveItemsDialogProvider = MoveItemsDialogContext.Provider;
