import styled, { css } from "styled-components";
import { space, color, typography, system } from "styled-system";
import shouldForwardProp from "@styled-system/should-forward-prop";
const wordBreak = system({
    wordBreak: {
        property: "wordBreak"
    }
});
const align = system({
    align: {
        property: "textAlign"
    }
});
const textDecoration = system({
    textDecoration: {
        property: "textDecoration"
    }
});
const textTransform = system({
    textTransform: {
        property: "textTransform"
    }
});
const Text = styled("div").withConfig({
    shouldForwardProp: (prop)=>shouldForwardProp(prop)
})`
  ${space};
  ${color};
  ${typography}

  ${align};
  ${wordBreak}

  ${textDecoration};
  ${textTransform};

  ${({ truncate  })=>truncate && css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}

  /* Deprecated: DO NOT USE, added for backwards compatibility */
    ${({ caps  })=>caps && css`
      text-transform: uppercase;
    `}
`;
export default Text;
