import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { searchPageRoutes } from "@sprout/client-routes";
export default function useSearchPageParams() {
    const match = useRouteMatch({
        exact: true,
        path: searchPageRoutes
    });
    const { view , topicId  } = (match === null || match === void 0 ? void 0 : match.params) || {};
    return useMemo(()=>({
            view,
            topicId
        }), [
        view,
        topicId
    ]);
};
