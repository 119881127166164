function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { useCallback } from "react";
import { useSetItemsArchivedMutation, ItemType } from "sprout-graphql";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
export const plural = {
    [ItemType.Post]: "Leaves",
    [ItemType.Divider]: "Leaf dividers",
    [ItemType.Checklist]: "To-do lists",
    [ItemType.ChecklistItem]: "To-dos",
    [ItemType.Topic]: "Topics"
};
export const singular = {
    [ItemType.Post]: "Leaf",
    [ItemType.Divider]: "Leaf divider",
    [ItemType.Checklist]: "To-do list",
    [ItemType.ChecklistItem]: "To-do",
    [ItemType.Topic]: "Topic"
};
export default function useSetItemsArchived() {
    const [setItemsArchived] = useSetItemsArchivedMutation();
    const showSnackbar = useSnackbar();
    return useCallback(({ items , isExplicitlyArchived , snackbarOverwrite , callback , optimisticCallback  })=>{
        setItemsArchived({
            variables: {
                input: {
                    itemIds: items.map((item)=>item.id),
                    isArchived: isExplicitlyArchived
                }
            },
            optimisticResponse: {
                setItemsArchived: {
                    items: items.map((item)=>_objectSpread({}, item, {
                            isArchived: isExplicitlyArchived,
                            isExplicitlyArchived,
                            archivedAt: isExplicitlyArchived ? new Date(Date.now()).toISOString() : null
                        }))
                }
            }
        }).then(({ data  })=>{
            var ref;
            const dataItems = data === null || data === void 0 ? void 0 : (ref = data.setItemsArchived) === null || ref === void 0 ? void 0 : ref.items;
            if (!dataItems) throw new Error("No items returned");
            const [item] = dataItems;
            const str = (dataItems.length > 1 ? plural : singular)[item.type];
            const state = isExplicitlyArchived ? "archived" : "unarchived";
            showSnackbar({
                message: `${snackbarOverwrite || str} ${state}`
            });
            if (callback) callback(dataItems);
        }).catch(()=>{
            showSnackbar.error();
        });
        if (optimisticCallback) optimisticCallback(items);
    }, [
        setItemsArchived,
        showSnackbar
    ]);
};
