import styled, { css } from "styled-components";
import { flexbox, system } from "styled-system";
import { Box } from "../Box";
const Flex = styled(Box)`
  ${flexbox};

  /* Deprecated: DO NOT USE, added for backwards compatibility */
  ${system({
    align: {
        property: "alignItems"
    },
    justify: {
        property: "justifyContent"
    }
})}

  ${({ column  })=>column && css`
      flex-direction: column;
    `}
`;
Flex.defaultProps = {
    display: "flex"
};
export default Flex;
