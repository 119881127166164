function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import jumpToData from "@sprout/context/dist/jumpToData";
import { PersonOutlined } from "sprout-ui-icons";
import useCurrentUser from "@sprout/client-graphql/dist/useCurrentUser";
import useSpecialTopic from "@sprout/hooks/dist/useSpecialTopic";
const APP = "APP";
export default function useJumpToMoreGroups() {
    const currentUser = useCurrentUser();
    const { myTasksURL , readLaterURL  } = useSpecialTopic();
    return [
        {
            group: "YOUR",
            items: [
                _objectSpread({}, jumpToData["/my/assignments"], {
                    action: "GO_MY_ASSIGNMENTS",
                    url: "/my/assignments",
                    type: APP
                }),
                _objectSpread({}, jumpToData["/to-dos"], {
                    action: "GO_MY_TASKS",
                    url: myTasksURL,
                    type: APP
                }),
                _objectSpread({}, jumpToData["/read-later"], {
                    action: "GO_READ_LATER",
                    url: readLaterURL,
                    type: APP
                }),
                _objectSpread({}, jumpToData["/following"], {
                    action: "GO_FOLLOWING",
                    url: "/following",
                    type: APP
                }),
                _objectSpread({}, jumpToData["/activity"], {
                    action: "GO_ACTIVITY",
                    url: "/activity",
                    type: APP
                }),
                ...currentUser ? [
                    {
                        label: "Profile",
                        icon: PersonOutlined,
                        type: APP,
                        url: `/${currentUser.username}`,
                        action: "GO_PROFILE"
                    }, 
                ] : [],
                _objectSpread({}, jumpToData["/my/workspaces"], {
                    action: "GO_WORKSPACE",
                    url: "/my/workspaces",
                    type: APP
                }), 
            ]
        },
        {
            group: "OTHER STUFF",
            items: [
                _objectSpread({}, jumpToData["/drafts"], {
                    action: "GO_DRAFTS",
                    url: "/drafts",
                    type: APP
                }),
                _objectSpread({}, jumpToData["/bookmarks"], {
                    action: "GO_BOOKMARKS",
                    url: "/bookmarks",
                    type: APP
                }),
                _objectSpread({}, jumpToData["/recent"], {
                    action: "GO_RECENT",
                    url: "/recent",
                    type: APP
                }),
                _objectSpread({}, jumpToData["/my/settings"], {
                    action: "GO_SETTINGS",
                    url: "/my/settings",
                    type: APP
                }), 
            ]
        }, 
    ];
};
