function flattenTreeData(tree, depth = 0) {
    const treeItem = tree.items[tree.rootId];
    if (treeItem) return treeItem.children.reduce((acc, currentValue)=>{
        const currentItem = tree.items[currentValue];
        const currentPath = depth + 1;
        const children = flattenTreeData({
            rootId: currentItem.topic.id,
            items: tree.items
        }, currentPath);
        return [
            ...acc,
            {
                topic: currentItem.topic,
                depth: currentPath
            },
            ...children, 
        ];
    }, []);
    return [];
}
export default function getTopicTreeItems(topic) {
    const items = [
        topic,
        ...topic.itemsByRootTopicId.nodes
    ].reduce((acc, currentValue, _, source)=>{
        const { id  } = currentValue;
        acc[id] = {
            topic: currentValue,
            children: source.filter((section)=>section.parentTopicId === currentValue.id).sort((a, b)=>a.position - b.position).map((item)=>item.id)
        };
        return acc;
    }, {});
    const treeData = {
        rootId: topic.id,
        items
    };
    return [
        {
            topic,
            depth: 0
        },
        ...flattenTreeData(treeData)
    ];
};
