function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
export default function uploadImage(file) {
    return _uploadImage.apply(this, arguments);
};
function _uploadImage() {
    _uploadImage = _asyncToGenerator(function*(file) {
        if (process.env.STORYBOOK) return new Promise((resolve)=>{
            setTimeout(()=>{
                resolve(URL.createObjectURL(file));
            }, 1000);
        });
        if (!/image/i.test(file.type)) throw new Error("File is not an image");
        if (file.size > 5242880) throw new Error("File is too large, try a smaller file");
        const fileName = file.name;
        const signResult = yield fetch("/api/s3sign", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                fileName: fileName.substring(0, fileName.lastIndexOf(".")),
                fileType: file.type,
                fileSize: file.size,
                purpose: "AVATAR"
            })
        });
        if (signResult.status !== 200) throw new Error("Failed to get signed URL");
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { url: urlToUploadTo , fields , targetUrl  } = yield signResult.json();
        const formData = new FormData();
        Object.entries(fields).forEach(([k, v])=>{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formData.append(k, v);
        });
        formData.append("file", file);
        const uploadResult = yield fetch(urlToUploadTo, {
            method: "POST",
            body: formData,
            redirect: "manual"
        });
        if (uploadResult.status < 200 || uploadResult.status > 299) throw new Error("Failed to upload image");
        return targetUrl;
    });
    return _uploadImage.apply(this, arguments);
}
