import { Extension, getAttributes } from "@tiptap/core";
import { Link } from "@tiptap/extension-link";
import { Plugin } from "prosemirror-state";
import { checkIsSproutUrl } from "sprout-lib";
const InternalLink = Extension.create({
    addProseMirrorPlugins () {
        return [
            new Plugin({
                props: {
                    handleClick: (view, _, event)=>{
                        var ref;
                        const attrs = getAttributes(view.state, Link.name);
                        const link = (ref = event.target) === null || ref === void 0 ? void 0 : ref.closest("a");
                        if (link && attrs.href) {
                            const { isSproutUrl , pathname  } = checkIsSproutUrl(attrs.href);
                            if (isSproutUrl && pathname) {
                                this.options.history.push(pathname);
                                return true;
                            }
                        }
                        return false;
                    }
                }
            }), 
        ];
    }
});
export default InternalLink;
