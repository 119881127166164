import { useCallback, useMemo, useState } from "react";
import { usePopoverState } from "sprout-ui-dom/dist/Popover";
import { getIsLargePointerDevice } from "sprout-ui-dom/dist/utils";
export function useMenuState() {
    const { referenceElement , setReferenceElement , setTriggerElement , triggerElement ,  } = usePopoverState();
    const [open, setOpen] = useState(false);
    const onOpenChange = useCallback((nextOpen)=>{
        if (!nextOpen) {
            setOpen(false);
            return;
        }
        setOpen(getIsLargePointerDevice() ? "popover" : "bottom-sheet");
    }, []);
    const onOpenToggle = useCallback(()=>{
        onOpenChange(!open);
    }, [
        onOpenChange,
        open
    ]);
    return useMemo(()=>({
            open,
            onOpenChange,
            onOpenToggle,
            referenceElement,
            setReferenceElement,
            triggerElement,
            setTriggerElement
        }), [
        open,
        onOpenChange,
        onOpenToggle,
        referenceElement,
        setReferenceElement,
        setTriggerElement,
        triggerElement, 
    ]);
}
