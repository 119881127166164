import { useMemo } from "react";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
export default function useSearchPageQueryString() {
    const { search  } = useLocation();
    return useMemo(()=>{
        const parsed = parse(search, {
            parseBooleans: true
        });
        return {
            q: typeof parsed.q === "string" ? parsed.q : "",
            f: typeof parsed.f === "string" ? parsed.f.split(",").map((f)=>f.trim()) : []
        };
    }, [
        search
    ]);
};
