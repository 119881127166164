import { grey, black, white, blue, yellow } from "./colors";
import { breakpoints } from "./media";
const theme = {
    breakpoints: Object.values(breakpoints).map((breakpoint)=>`${breakpoint}px`),
    colors: {
        primary: grey[900],
        grey,
        black,
        white,
        blue,
        yellow
    },
    space: [
        4,
        8,
        12,
        16,
        20,
        24,
        28,
        32,
        36,
        40,
        44,
        48,
        64
    ],
    fontSizes: [
        12,
        14,
        16,
        20,
        24,
        32,
        48,
        63,
        72
    ],
    fontWeights: {
        thin: 100,
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
        black: 900
    }
};
export default theme;
