import { Box } from "sprout-ui-primitives";
import styled from "styled-components";
const BottomSheetBackdrop = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;

  opacity: 0;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);

  [class*="enter-"] & {
    opacity: 1;
    transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
  }

  [class*="exit-done"] & {
    display: none;
  }
`;
BottomSheetBackdrop.defaultProps = {
    bg: "black.10"
};
export default BottomSheetBackdrop;
