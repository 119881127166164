import { sortedUniq } from "lodash";
import { unescapeBrackets } from "../utils";
import format from "./format";
import processOutputMarkdown from "./processOutputMarkdown";
function nodeWithStyles(text, styles) {
    const node = {
        type: "text",
        text
    };
    const marks = styles.map((s)=>s.style === "BOLD" ? {
            type: "bold"
        } : s.style === "ITALIC" ? {
            type: "italic"
        } : s.style === "STRIKETHROUGH" ? {
            type: "strike"
        } : s.style === "CODE" ? {
            type: "code"
        } : null).filter((s)=>!!s);
    if (marks.length) node.marks = marks;
    return node;
}
// {type, content, marks, attrs} | {type: 'text', text: "..."}
export default function formatToTipTapJSON(text, mode) {
    const document = {
        type: "doc",
        content: []
    };
    let block;
    function addRaw(rawText) {
        if (rawText) // ProseMirror doesn't support empty text
        block.content.push({
            type: "text",
            text: rawText
        });
    }
    const addOutput = (outputText)=>{
        const textWithUnescapedBrackets = unescapeBrackets(outputText);
        // addRaw(textWithUnescapedBrackets);
        const { text: processedText , styles ,  } = processOutputMarkdown(0, textWithUnescapedBrackets);
        if (!processedText) return;
        const startPoints = styles.map((s)=>s.offset);
        const endPoints = styles.map((s)=>s.offset + s.length);
        const breakPoints = sortedUniq([
            0,
            ...startPoints,
            ...endPoints,
            processedText.length
        ].sort((a, z)=>a - z));
        let previousBreakpoint = breakPoints.shift();
        let activeStyles = styles.filter((s)=>s.offset === previousBreakpoint && s.length > 0);
        breakPoints.forEach((breakPoint)=>{
            // Add existing text
            const subtext = processedText.substring(previousBreakpoint, breakPoint);
            if (subtext) // ProseMirror doesn't support empty text
            block.content.push(nodeWithStyles(subtext, activeStyles));
            activeStyles = styles.filter((s)=>s.offset <= breakPoint && s.offset + s.length > breakPoint);
            previousBreakpoint = breakPoint;
        });
    };
    const addSpecial = (type, data, fallback)=>{
        if (type === "mention") {
            const { userId: id , displayText /* username: name */  } = data;
            if (displayText) block.content.push({
                type: "mention",
                attrs: {
                    label: displayText.substring(1),
                    id
                }
            });
            else addRaw(`[someone]`);
        } else if (type === "raw") addRaw(fallback);
        else if (type === "url") {
            const { textOrLink , url  } = data;
            if (textOrLink) block.content.push({
                type: "text",
                marks: [
                    {
                        type: "link",
                        attrs: {
                            href: url,
                            auto: true
                        }
                    }, 
                ],
                text: textOrLink
            });
        } else {
            console.warn(`Unsupported type '${type}'`);
            addOutput(fallback);
        }
    };
    function sealBlock() {
        if (block) {
            if (block.content && block.content.length === 0) delete block.content;
        }
    }
    const addBlock = (blockType, options)=>{
        sealBlock();
        switch(blockType){
            case "unstyled":
                block = {
                    type: "paragraph",
                    content: []
                };
                document.content.push(block);
                break;
            case "code-block":
                block = {
                    type: "codeBlock",
                    content: []
                };
                document.content.push(block);
                break;
            case "blockquote":
                {
                    block = {
                        type: "paragraph",
                        content: []
                    };
                    const lastBlock = document.content[document.content.length - 1];
                    if (lastBlock && lastBlock.type === "blockquote") lastBlock.content.push(block);
                    else {
                        const parentBlock = {
                            type: "blockquote",
                            content: [
                                block
                            ]
                        };
                        document.content.push(parentBlock);
                    }
                    break;
                }
            case "unordered-list":
            case "ordered-list":
                {
                    const listType = blockType === "unordered-list" ? "bulletList" : "orderedList";
                    block = {
                        type: "paragraph",
                        content: []
                    };
                    const listItem = {
                        type: "listItem",
                        content: [
                            block
                        ]
                    };
                    const lastBlock = document.content[document.content.length - 1];
                    if (lastBlock && lastBlock.type === listType) lastBlock.content.push(listItem);
                    else {
                        var ref;
                        var ref1;
                        const parentBlock = {
                            type: listType,
                            content: [
                                listItem
                            ],
                            attrs: {
                                start: (ref1 = options === null || options === void 0 ? void 0 : options.start) !== null && ref1 !== void 0 ? ref1 : undefined
                            }
                        };
                        if (document.content.length === 1 && document.content[0].type === "paragraph" && ((ref = document.content[0].content) === null || ref === void 0 ? void 0 : ref.length) === 0) // Replace empty paragraph
                        document.content[0] = parentBlock;
                        else document.content.push(parentBlock);
                    }
                    break;
                }
            default:
                // TODO: block quotes/etc
                throw new Error(`Unsupported block type '${blockType}'`);
        }
    };
    format(text, {
        addOutput,
        addSpecial,
        addBlock
    }, mode);
    sealBlock();
    // Failsafe: populate a block with the text we were given.
    if (document.content.length === 0) {
        // This SHOULD never be needed.
        console.error("ERROR: formatToTipTapJSON failsafe was invoked; bad copy follows (JSON.stringified):");
        console.error(JSON.stringify(text));
        addBlock();
        addRaw(text || "");
        sealBlock();
    }
    return {
        type: "tiptap",
        json: document
    };
};
