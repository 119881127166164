import features from "./features";
export { features };
export const searchPageRoutes = [
    "/search/:view(my-trees|sprout|people)",
    "/:username/:topicId(\\d+)-:topicTitle([^\\s\\/]+)/search",
    "/:view(read-later|daily)/search", 
];
export const postPageRoutes = [
    "/leaf/:postId(\\d+)",
    "/todolist/:todoListId(\\d+)",
    "/todo/:todoId(\\d+)", 
];
export const topicPageRoutes = [
    "/:username/:topicId(\\d+)-:topicTitle([^\\s\\/]+)/:view(archive|trash|activity|labels)?",
    "/:username/:topicId(\\d+)-:topicTitle([^\\s\\/]+)/label/:labelId", 
];
export const featureRoutes = features.map((feature)=>`/features/:feature(${feature.fragment})`);
const billingPages = "billing|payment-method|billing-history|billing-period";
const notificationPages = "notifications|email-notifications|browser-notifications";
const settingPages = [
    `/my/(edit-profile|${billingPages}|${notificationPages}|account)`,
    `/:workspace/(edit-profile|${billingPages}|account)`, 
];
const inviteUsersPages = [
    "/:username/invite",
    "/:username/:topicId(\\d+)-:topicTitle([^\\s\\/]+)/invite", 
];
export const noNavigationBottomRoutes = [
    ...settingPages,
    ...inviteUsersPages,
    "/leaf/:postId(\\d+)/draft",
    "/new-tree",
    "/new-workspace",
    "/my/start-page",
    "/payment",
    "/:username/:topicId(\\d+)-:topicTitle([^\\s\\/]+)/settings",
    "/:username/:topicId(\\d+)-:topicTitle([^\\s\\/]+)/members", 
];
export const noNavigationBottomNewPostButtonRoutes = [
    "/:username/:topicId(\\d+)-:topicTitle([^\\s\\/]+)",
    ...postPageRoutes, 
];
export const landingPageRoutes = [
    "/(terms|privacy|copyright|trademark|community)",
    "/amendment-to-sprout-terms-of-service-applicable-to-u-s-government-users",
    "/features",
    "/pricing",
    ...featureRoutes, 
];
export const welcomePageRoutes = [
    "/welcome",
    "/welcome/2"
];
export const rootPageRoutes = [
    // "/following",
    // "/activity",
    "/notifications",
    "/home",
    // "/trees",
    // "/bookmarks",
    "/agenda",
    ...welcomePageRoutes, 
];
export const assignmentsPageRoutes = [
    "/my/:assign(assignments|assignings)"
];
