const replacement = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;"
};
const invertReplacement = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">"
};
export function escapeBrackets(text) {
    return text.replace(/[<>&]/g, (m)=>replacement[m] || "");
}
export function unescapeBrackets(text) {
    return text.replace(/&(amp|lt|gt);/g, (m)=>invertReplacement[m] || "");
}
export function trimWww(href) {
    return href.replace(/^www\./, "");
}
export function trimProtocol(href) {
    return href.replace(/^((https?|ftp):\/\/|mailto:)/, "");
}
