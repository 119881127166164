/**
 * Get the week number of the given date within it's month.
 *
 * By default, we assume weeks start on a Monday, but if that's not
 * the case please pass the week start day:
 *
 * 0 - Sunday
 * 1 - Monday
 * ...
 * 6 - Saturday
 */ export default function getWeekOfMonth(date, weekStartDay = 1) {
    // TODO: should we make `weekStartDay` default to whatever
    // `@internationalized/calendar` would set it to? Or maybe it should be
    // required that we specify it?
    if (weekStartDay < 0 || weekStartDay > 6) throw new Error("Invalid weekStartDay");
    let firstWeekday = new Date(date.getFullYear(), date.getMonth(), 1).getDay() - weekStartDay;
    if (firstWeekday < 0) firstWeekday += 7;
    const offsetDate = date.getDate() + firstWeekday - 1;
    return Math.floor(offsetDate / 7);
};
