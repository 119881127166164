import { useRef, useEffect } from "react";
export default function useAfterMountEffect(effect, deps) {
    const mount = useRef(false);
    useEffect(()=>{
        if (!mount.current) mount.current = true;
        else {
            const cleanup = effect();
            if (typeof cleanup === "function") return cleanup;
        }
        return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
