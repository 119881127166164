/* eslint-disable @typescript-eslint/prefer-regexp-exec */ import { inspect } from "util";
import { trimWww, trimProtocol } from "../utils";
export default function format(rawText, { addBlock , addOutput , addSpecial  }, mode) {
    if (typeof rawText !== "string") {
        console.error(`Expected text, instead received ${inspect(rawText)}`);
        return;
    }
    const lines = rawText.split(/\r?\n/);
    let inCode = null;
    for(let i = 0; i < lines.length; i++){
        let text = lines[i];
        if (text === "```" && mode !== "BASIC") {
            if (inCode) {
                addBlock("code-block");
                addSpecial("raw", {}, inCode.join("\n"));
                inCode = null;
            } else inCode = [];
        } else if (inCode) inCode.push(text);
        else {
            let type = "unstyled";
            let start = null;
            if (text[0] === ">") {
                type = "blockquote";
                text = text.replace(/^> ?/, "");
            } else if (text.startsWith("- ") && mode !== "BASIC") {
                type = "unordered-list";
                const prefix = "  ";
                text = text.substring(prefix.length);
                const pad = prefix.replace(/./g, " ");
                while(lines[i + 1] != null && lines[i + 1].startsWith(pad)){
                    text += `\n${lines[i + 1]}`;
                    i++;
                }
            } else if (text.match(/^[0-9]+\. /) && mode !== "BASIC") {
                type = "ordered-list";
                const dotIndex = text.indexOf(".");
                start = parseInt(text.substring(0, dotIndex), 10);
                const prefix = text.substring(0, dotIndex + 2);
                text = text.substring(prefix.length);
                const pad = prefix.replace(/./g, " ");
                while(lines[i + 1] != null && lines[i + 1].startsWith(pad)){
                    text += `\n${lines[i + 1]}`;
                    i++;
                }
            }
            addBlock(type, {
                start
            });
            let matches;
            let index = 0;
            const re = /<([^<>]+)>/g;
            while(matches = re.exec(text)){
                const head = text.substring(index, matches.index);
                addOutput(head);
                const matches2 = matches[1].match(/^((?:https?|ftp:\/\/|mailto:)[^\s|]+)(?:\|(.*))?$/);
                const matches3 = matches[1].match(/^@([0-9]+)(?:\|(.*))?$/);
                if (matches2) {
                    const [, url, linkText] = matches2;
                    const textOrLink = linkText || trimWww(trimProtocol(url));
                    addSpecial("url", {
                        url,
                        text: linkText,
                        textOrLink
                    }, url);
                } else if (matches3) {
                    const [, rawUserId, displayText] = matches3;
                    const userId = parseInt(rawUserId, 10);
                    const username = displayText ? displayText.replace(/^@/, "") : null;
                    addSpecial("mention", {
                        userId,
                        username,
                        displayText
                    }, displayText || "[person]");
                } else addOutput(matches[0]);
                index = matches.index + matches[0].length;
            }
            addOutput(text.substring(index));
        }
    }
    if (inCode) {
        // We started a code block but never finished it; lets not loose the content
        addBlock();
        inCode.unshift("```");
        addOutput(inCode.join("\n"));
        inCode = null;
    }
};
