import { useContext, useMemo } from "react";
import NavigationListProvider from "./NavigationListProvider";
import useNavigationList, { NavigationListListContext } from "./useNavigationList";
import useNavigationListItem from "./useNavigationListItem";
import NavigationList from "./NavigationList";
import ActionRegistryGlobalHandler from "./ActionRegistryGlobalHandler";
import useActionRegistryGlobalHandlers from "./useActionRegistryGlobalHandlers";
import useActionRegistryGlobalHandler from "./useActionRegistryGlobalHandler";
import useActionRegistryNavigation from "./useActionRegistryNavigation";
import useActionRegistryMultiselectAction from "./useActionRegistryMultiselectAction";
import useNavigationActiveLock from "./useNavigationActiveLock";
import { ACTIONS, displayify } from "./actionRegistry";
import { NavigationListContext } from "./NavigationListContext";
export { default as NavigationButton } from "./NavigationButton";
export { NavigationListContext };
export { displayify, NavigationList, NavigationListProvider, useNavigationList, useNavigationListItem, ActionRegistryGlobalHandler, useActionRegistryGlobalHandlers, useActionRegistryGlobalHandler, useActionRegistryNavigation, useActionRegistryMultiselectAction, useNavigationActiveLock, ACTIONS };
export const useNavigationListContext = ()=>useContext(NavigationListContext);
export const useNavigationListListContext = ()=>useContext(NavigationListListContext);
export const useCheckActiveCommands = (...args)=>{
    const { currentlyActiveCommands  } = useNavigationListContext();
    return useMemo(()=>args.every((command)=>currentlyActiveCommands.includes(command)), [
        args,
        currentlyActiveCommands
    ]);
};
export * from "./interfaces";
export { scrollIntoView } from "./utils";
