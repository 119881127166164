import styled from "styled-components";
import { Button } from "../Button";
const LandingCTAButton = styled(Button)`
  padding: 0 24px;
`;
LandingCTAButton.defaultProps = {
    height: "56px",
    fontSize: "24px",
    variant: "bright-blue"
};
export default LandingCTAButton;
