function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { detectOverflow } from "@popperjs/core";
const maxHeight = {
    name: "maxHeight",
    enabled: true,
    phase: "main",
    requiresIfExists: [
        "offset",
        "preventOverflow",
        "flip"
    ],
    fn ({ state , name , options  }) {
        const overflow = detectOverflow(state, _objectSpread({}, options, {
            padding: 16
        }));
        const { y  } = state.modifiersData.preventOverflow || {
            x: 0,
            y: 0
        };
        const { height  } = state.rects.popper;
        const [basePlacement] = state.placement.split("-");
        const heightProp = basePlacement === "top" ? "top" : "bottom";
        // eslint-disable-next-line no-param-reassign
        state.modifiersData[name] = {
            height: height - overflow[heightProp] - y
        };
    }
};
export default maxHeight;
