import { countBy } from "lodash";
import naturalCompare from "natural-compare";
import { isPresent } from "sprout-lib";
export var colorMap;
(function(colorMap) {
    colorMap["blue"] = "#1976d2";
    colorMap["green"] = "#388e3c";
    colorMap["yellow"] = "#fbc02d";
    colorMap["orange"] = "#f57c00";
    colorMap["red"] = "#d32f2f";
    colorMap["pink"] = "#c2185b";
    colorMap["purple"] = "#7b1fa2";
    colorMap["brown"] = "#5d4037";
    colorMap["grey"] = "#455a64";
    colorMap["black"] = "#212121";
})(colorMap || (colorMap = {}));
export const colors = Object.keys(colorMap);
export function sortLabels(labels) {
    return [
        ...labels
    ].sort((a, b)=>{
        const result = colors.indexOf(a.style) - colors.indexOf(b.style);
        if (result === 0) return naturalCompare(a.text, b.text);
        return result;
    });
}
export function markLabels(labels, posts) {
    const totalPosts = posts.length;
    const appliedLabels = posts.flatMap((post)=>post.appliedLabels).filter(isPresent).map((label)=>label.id);
    const stats = countBy(appliedLabels);
    return labels.map((label)=>{
        const labelCount = stats[label.id];
        return {
            label,
            state: labelCount === undefined ? "incomplete" : labelCount === totalPosts ? "complete" : "indeterminate"
        };
    });
}
