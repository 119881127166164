function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
const applyMaxHeight = {
    name: "applyMaxHeight",
    enabled: true,
    phase: "beforeWrite",
    requires: [
        "maxHeight"
    ],
    fn ({ state  }) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { height  } = state.modifiersData.maxHeight;
        // eslint-disable-next-line no-param-reassign
        state.styles.popper = _objectSpread({}, state.styles.popper, {
            maxHeight: `${height}px`
        });
    }
};
export default applyMaxHeight;
