import styled from "styled-components";
import { media } from "sprout-ui-theme";
import AppBarBase from "./AppBar_Base";
const AppBarDesktop = styled(AppBarBase)`
  display: none;
  ${media.up("medium", "pointer")} {
    display: flex;
  }
`;
export default AppBarDesktop;
