import { addClass, hasClass, removeClass } from "dom-helpers";
import { useCallback, useEffect } from "react";
export default function useDialogOverlayHandlers() {
    const onEnter = useCallback(()=>{
        if (hasClass(document.body, "overlay")) return;
        addClass(document.body, "overlay");
    }, []);
    const onExited = useCallback(()=>{
        removeClass(document.body, "overlay");
    }, []);
    useEffect(()=>{
        return ()=>{
            if (hasClass(document.body, "overlay")) removeClass(document.body, "overlay");
        };
    }, []);
    return {
        onEnter,
        onExited
    };
};
