export function requestNotificationPermission() {
    return new Promise((resolve, reject)=>{
        if (!("Notification" in window)) {
            reject();
            return;
        }
        let called = false;
        const promise = Notification.requestPermission((permission)=>{
            if (!called) resolve(permission);
        });
        if (typeof (promise === null || promise === void 0 ? void 0 : promise.then) === "function") {
            called = true;
            promise.then(resolve, reject);
        }
    });
}
export function getNotificationPermissionState() {
    if (!("Notification" in window)) {
        console.error("This browser does not support Notifications");
        return "denied";
    }
    return Notification.permission;
}
