import { useCallback } from "react";
import { ItemType, useMove_TrackMoveHistoryMutation } from "sprout-graphql";
export default function useTrackMoveHistory() {
    const [trackMoveHistory] = useMove_TrackMoveHistoryMutation();
    return useCallback(({ lastItemId , currentItemId  })=>{
        if (lastItemId === currentItemId) return undefined;
        return trackMoveHistory({
            variables: {
                itemId: currentItemId,
                search: {
                    type: ItemType.Topic,
                    itemId: currentItemId
                }
            }
        });
    }, [
        trackMoveHistory
    ]);
};
