export function getWeekOfMonth(date) {
    let firstWeekday = new Date(date.getFullYear(), date.getMonth(), 1).getDay() - 1;
    if (firstWeekday < 0) firstWeekday = 6;
    const offsetDate = date.getDate() + firstWeekday - 1;
    return Math.floor(offsetDate / 7);
}
function getTimezoneOffsetInMilliseconds(date) {
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
    utcDate.setUTCFullYear(date.getFullYear());
    return date.getTime() - utcDate.getTime();
}
export function differenceInDays(left, right) {
    const millisecondsInDay = 86400000;
    const timestampLeft = left.getTime() - getTimezoneOffsetInMilliseconds(left);
    const timestampRight = right.getTime() - getTimezoneOffsetInMilliseconds(right);
    return Math.round((timestampLeft - timestampRight) / millisecondsInDay);
}
export function differenceInWeeks(left, right) {
    const diff = differenceInDays(left, right) / 7;
    return Math.trunc(diff);
}
