function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { MutationQueueLink } from "./MutationQueueLink";
const dataIdFromObject = (result)=>{
    if (typeof result.nodeId === "string") return result.nodeId;
    return undefined;
};
const config = {
    cacheOptions: {
        addTypename: true,
        dataIdFromObject
    }
};
export function createClient(baseLink, ssrMode = false) {
    /*
  networkInterface.use([
    {
      applyMiddleware(req, next) {
        req.options.headers = req.options.headers || {};
        const token = networkInterface.jwtToken;
        if (token) {
          req.options.headers.authorization = `Bearer ${token}`;
        }
        const app = networkInterface.sproutApp;
        if (app) {
          req.options.headers["x-sprout-app"] = app;
        }
        if (typeof window !== "undefined" && window.SPROUT_USER_CONFIG.CSRF) {
          req.options.headers["x-csrf-token"] = window.SPROUT_USER_CONFIG.CSRF;
        }
        next();
      },
    },
  ]);
  */ const middlewareLink = setContext(()=>{
        const l = baseLink;
        const token = l.jwtToken;
        const app = l.sproutApp;
        return {
            headers: _objectSpread({}, token ? {
                authorization: `Bearer ${token}`
            } : {}, app ? {
                "x-sprout-app": app
            } : {}, typeof window !== "undefined" && window.SPROUT_USER_CONFIG.CSRF ? {
                "x-csrf-token": window.SPROUT_USER_CONFIG.CSRF
            } : {})
        };
    });
    const initialState = typeof window !== "undefined" && window.SPROUT_USER_CONFIG.STATE && window.SPROUT_USER_CONFIG.STATE.apolloState;
    const cache = new InMemoryCache(config.cacheOptions);
    if (initialState) cache.restore(initialState);
    const client = new ApolloClient({
        ssrMode,
        ssrForceFetchDelay: 100,
        link: from([
            middlewareLink,
            new MutationQueueLink({
                debug: process.env.NODE_ENV === "development"
            }),
            baseLink, 
        ]),
        cache
    });
    return client;
}
