import { useCallback, useEffect, useRef, useState } from "react";
import { useSnackbar } from "../Snackbar";
export default function useClipboard() {
    const [supported, setSupported] = useState(false);
    const supportedRef = useRef(supported);
    useEffect(()=>{
        if (navigator.clipboard) {
            setSupported(true);
            supportedRef.current = true;
        }
    }, []);
    const showSnackbar = useSnackbar();
    const copy = useCallback((textToCopy)=>{
        if (supportedRef.current) navigator.clipboard.writeText(textToCopy).then(()=>{
            showSnackbar({
                message: "Copied to clipboard"
            });
        }).catch(()=>{
            showSnackbar.error();
        });
    }, [
        showSnackbar
    ]);
    return {
        supported,
        copy
    };
};
