/* eslint-disable @typescript-eslint/unbound-method, no-param-reassign */ import { useMemo } from "react";
import useActionRegistryGlobalHandlers from "./useActionRegistryGlobalHandlers";
const isDev = process.env.NODE_ENV === "development";
/**
 * More convenient syntax to useActionRegistryGlobalHandlers (if you use this,
 * you are guaranteeing that you are using the event; if you might not, either
 * use the condition to say when you do or don't, or use
 * useActionRegistryGlobalHandlers instead).
 */ export default function useActionRegistryGlobalHandler(rawAction, performAction, options = {}) {
    const { skip =false , preventDefault =false  } = options;
    /*
   * Since rawAction can be an array, it can differ every call. This would
   * cause cascading re-renders if we used this in dependencies arrays, so
   * instead we'll convert it to an array that will only change if the elements
   * in rawAction change.
   */ const actionsText = Array.isArray(rawAction) ? rawAction.join("|") : String(rawAction);
    const actions = useMemo(()=>actionsText.split("|"), [
        actionsText
    ]);
    useActionRegistryGlobalHandlers(useMemo(()=>{
        return skip ? {
            actions: [],
            performAction: ()=>false
        } : {
            actions,
            performAction (command, activeItems, event) {
                if (actions.includes(command)) {
                    if (preventDefault) /**
                     * Required in Chrome, prevent the letter from filling the
                     * editor.
                     */ event === null || event === void 0 ? void 0 : event.preventDefault();
                    const result = performAction(activeItems, event, command);
                    if (typeof result !== "boolean") Promise.resolve(result).catch((e)=>{
                        if (isDev) console.debug(e);
                    });
                    return result;
                }
                return false;
            }
        };
    }, [
        skip,
        actions,
        performAction,
        preventDefault
    ]));
};
