function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { useCallback } from "react";
import { useSetAgendaItemOrderMutation } from "sprout-graphql";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
import useCurrentUser from "../useCurrentUser";
export default function useSetAgendaItemOrder() {
    const currentUser = useCurrentUser();
    const [setAgendaItemOrder] = useSetAgendaItemOrderMutation();
    const showSnackbar = useSnackbar();
    return useCallback((order)=>{
        setAgendaItemOrder(_objectSpread({
            variables: {
                order
            }
        }, currentUser ? {
            optimisticResponse: {
                setAgendaItemOrder: {
                    user: _objectSpread({}, currentUser, {
                        agendaItemOrder: order
                    })
                }
            }
        } : null)).catch(()=>{
            showSnackbar.error();
        });
    }, [
        currentUser,
        setAgendaItemOrder,
        showSnackbar
    ]);
};
