import { useMemo } from "react";
import update from "immutability-helper";
import naturalCompare from "natural-compare";
import { ItemMemberLevel } from "sprout-graphql";
import getTopicTreeItems from "sprout-lib/dist/getTopicTreeItems";
import isNotNull from "sprout-lib/dist/isNotNull";
import isPresent from "sprout-lib/dist/isPresent";
export var GroupType;
(function(GroupType) {
    GroupType[GroupType["CURRENT"] = 0] = "CURRENT";
    GroupType[GroupType["RECENT"] = 1] = "RECENT";
    GroupType[GroupType["SPECIAL_TREE"] = 2] = "SPECIAL_TREE";
    GroupType[GroupType["PERSONAL"] = 3] = "PERSONAL";
    GroupType[GroupType["TEAM"] = 4] = "TEAM";
})(GroupType || (GroupType = {}));
export default function useProcessTopicsToGroups({ topicTree , moveHistories , teams: _teams , personalTopics: _personalTopics , topicsToFilter  }) {
    const topicTreeItems = useMemo(()=>topicTree ? getTopicTreeItems(topicTree) : [], [
        topicTree
    ]);
    const recentTopics = useMemo(()=>{
        return moveHistories.map(({ item  })=>item)// `null` trees are trees that the user is no-longer permitted to see/use
        .filter(isPresent).filter(({ archivedAt , deletedAt , rootTopicOrSelf  })=>{
            const { memberLevel  } = rootTopicOrSelf || {};
            return !(archivedAt !== null && deletedAt !== null && // remove trees that the user is no longer a part of
            memberLevel && [
                ItemMemberLevel.Invited,
                ItemMemberLevel.Pending
            ].includes(memberLevel));
        });
    }, [
        moveHistories
    ]);
    const teams = useMemo(()=>{
        return _teams.map(({ team  })=>{
            if (!team) return null;
            const teamTopics = team.topics.nodes.filter(({ rootTopicOrSelf , id  })=>{
                const { memberLevel  } = rootTopicOrSelf || {};
                return memberLevel && ![
                    ItemMemberLevel.Invited,
                    ItemMemberLevel.Pending
                ].includes(memberLevel) && !topicsToFilter.includes(id);
            }).sort((topicA, topicB)=>{
                const titleA = topicA.title || "";
                const titleB = topicB.title || "";
                return naturalCompare(titleA.toLowerCase(), titleB.toLowerCase());
            });
            // Do not show empty team, this is different from `TopicsPage`
            if (teamTopics.length !== 0) return {
                user: update(team, {
                    $unset: [
                        "topics"
                    ]
                }),
                topics: teamTopics
            };
            return null;
        }).filter(isNotNull).sort((a, b)=>{
            const strA = a.user.firstName || a.user.username;
            const strB = b.user.firstName || b.user.username;
            return naturalCompare(strA, strB);
        });
    }, [
        _teams,
        topicsToFilter
    ]);
    const teamsTopicIds = useMemo(()=>{
        return teams.reduce((acc, currentValue)=>{
            const { topics  } = currentValue;
            return [
                ...acc,
                ...topics.map((topic)=>topic.id)
            ];
        }, []);
    }, [
        teams
    ]);
    const personalTopics = useMemo(()=>{
        return _personalTopics.map(({ topic  })=>topic).filter(isPresent).filter((topic)=>![
                ...teamsTopicIds,
                ...topicsToFilter
            ].includes(topic.id)).sort((topicA, topicB)=>{
            const titleA = topicA.title || "";
            const titleB = topicB.title || "";
            return naturalCompare(titleA.toLowerCase(), titleB.toLowerCase());
        });
    }, [
        _personalTopics,
        teamsTopicIds,
        topicsToFilter
    ]);
    return useMemo(()=>[
            {
                heading: "Current Tree",
                type: GroupType.CURRENT,
                items: topicTreeItems
            },
            {
                heading: "Recent",
                type: GroupType.RECENT,
                items: recentTopics
            },
            {
                heading: "Special Trees",
                type: GroupType.SPECIAL_TREE,
                items: [
                    {
                        title: "To-dos",
                        special: "MY_TASKS"
                    },
                    {
                        title: "Profile",
                        special: "PROFILE"
                    },
                    {
                        title: "Read Later",
                        special: "INBOX"
                    }, 
                ]
            },
            {
                heading: "Personal Trees",
                type: GroupType.PERSONAL,
                items: personalTopics
            },
            ...teams.map((team)=>{
                const { user , topics  } = team;
                return {
                    heading: `${user.firstName || user.username}`,
                    type: GroupType.TEAM,
                    user,
                    items: topics
                };
            }), 
        ].filter((group)=>group.items.length !== 0), [
        topicTreeItems,
        recentTopics,
        personalTopics,
        teams
    ]);
};
