export const getTeamRole = (member)=>{
    if (typeof member === "string") return member;
    const { active , owner , admin , invitedAt  } = member;
    if (active) {
        if (owner) return "OWNER";
        else if (admin) return "ADMIN";
        else return "MEMBER";
    } else if (invitedAt) return "INVITED";
    else return "PENDING";
};
/**
 * backend `ADMIN` = frontend `EDITOR`
 * backend `CONTRIBUTOR` = frontend `MEMBER`
 *
 * backend `MEMBER` (view only role) is not supported
 * i.e. `!owner && !admin && !contributor`
 */ export const getTopicRole = (member)=>{
    if (typeof member === "string") {
        if (member === "ADMIN") return "EDITOR";
        if (member === "CONTRIBUTOR") return "MEMBER";
        return member;
    }
    const { active , owner , admin , contributor , invitedAt  } = member;
    if (active) {
        if (owner) return "OWNER";
        else if (admin) return "EDITOR";
        else if (contributor) return "MEMBER";
        else return "NOT_SUPPORTED";
    } else if (invitedAt) return "INVITED";
    else return "PENDING";
};
