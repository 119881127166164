import { useCallback } from "react";
import { useActionRegistryGlobalHandler } from "@sprout/list-navigation";
import { useIsMyTasksPageOnboard } from "@sprout/hooks/dist/onboard";
import { useApp_CreatePostMutation, WellKnownTopic } from "sprout-graphql";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
import { useHistory } from "react-router-dom";
import { getItemUrl } from "sprout-lib";
const GlobalCreatePost = ()=>{
    const history = useHistory();
    const showSnackbar = useSnackbar();
    const [createPost] = useApp_CreatePostMutation();
    const [, onboardMyTasksPage] = useIsMyTasksPageOnboard();
    useActionRegistryGlobalHandler([
        "CREATE_MY_TASKS_POST",
        "CREATE_DEFAULT_TOPIC_POST"
    ], useCallback((_activeItems, _events, command)=>{
        createPost({
            variables: {
                inside: command === "CREATE_MY_TASKS_POST" ? WellKnownTopic.MyTasks : WellKnownTopic.DefaultTopic
            }
        }).then(({ data  })=>{
            var ref;
            const item = data === null || data === void 0 ? void 0 : (ref = data.createItem) === null || ref === void 0 ? void 0 : ref.item;
            if (item) {
                if (command === "CREATE_MY_TASKS_POST") onboardMyTasksPage();
                history.push(getItemUrl(item), {
                    focusDescriptionEditor: true
                });
            }
        }).catch(()=>{
            showSnackbar.error();
        });
        return true;
    }, [
        createPost,
        history,
        onboardMyTasksPage,
        showSnackbar
    ]));
    return null;
};
export default GlobalCreatePost;
