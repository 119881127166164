import { useRef } from "react";
import useAfterMountEffect from "./useAfterMountEffect";
function usePrevious(value) {
    const ref = useRef(value);
    useAfterMountEffect(()=>{
        ref.current = value;
    }, [
        value
    ]);
    return ref.current;
}
export default usePrevious;
