import format from "./format";
export default function formatToText(text, abbreviate = false) {
    const output = [];
    let wasCode = false;
    function closeBlock() {
        if (wasCode) {
            output.push("\n```");
            wasCode = false;
        }
    }
    format(text, {
        addOutput (outputText) {
            output.push(outputText);
        },
        addSpecial (type, data, fallback) {
            if (abbreviate && type === "url") output.push(data.textOrLink);
            else output.push(fallback);
        },
        addBlock (type) {
            closeBlock();
            if (output.length) output.push("\n");
            if (type === "blockquote") output.push("> ");
            else if (type === "code-block") {
                wasCode = true;
                output.push("```\n");
            }
        }
    });
    closeBlock();
    return output.join("");
};
