// https://github.com/mui-org/material-ui/blob/8a4b8952077b64e90ac9d23c116a45aed672db9c/packages/material-ui-utils/src/useForkRef.ts
import { useMemo } from "react";
function setRef(ref, value) {
    if (typeof ref === "function") ref(value);
    else if (ref) // eslint-disable-next-line no-param-reassign
    ref.current = value;
}
export default function useMergeRefs(refA, refB) {
    return useMemo(()=>{
        if (refA == null && refB == null) return null;
        return (refValue)=>{
            setRef(refA, refValue);
            setRef(refB, refValue);
        };
    }, [
        refA,
        refB
    ]);
};
