function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import canUseDOM from "dom-helpers/canUseDOM";
export const PERSONAL_PRODUCT_ID = canUseDOM ? window.SPROUT_CONFIG.PERSONAL_PRODUCT_ID : process.env.SPROUT_PERSONAL_PRODUCT_ID;
export const BUSINESS_PRODUCT_ID = canUseDOM ? window.SPROUT_CONFIG.BUSINESS_PRODUCT_ID : process.env.SPROUT_BUSINESS_PRODUCT_ID;
const getAnnualAndMonthPlanFromProduct = (stripeProduct, currency = "usd")=>{
    const annualPlan = ((stripeProduct === null || stripeProduct === void 0 ? void 0 : stripeProduct.plans) || []).find((p)=>p.currency === currency && p.interval === "year");
    const monthPlan = ((stripeProduct === null || stripeProduct === void 0 ? void 0 : stripeProduct.plans) || []).find((p)=>p.currency === currency && p.interval === "month");
    return {
        annualPlan,
        monthPlan
    };
};
const formatAmount = (amount, annualToMonth = false)=>{
    if (typeof amount !== "number") return null;
    const monthlyAmount = amount / 100 / (annualToMonth ? 12 : 1);
    if (Math.abs(monthlyAmount - Math.round(monthlyAmount)) < 0.01) return monthlyAmount.toFixed(0);
    else return monthlyAmount.toFixed(2);
};
export const formatProduct = (stripeProduct, key = "")=>{
    if (!stripeProduct) throw new Error(`Missing stripe product!`);
    const { annualPlan , monthPlan  } = getAnnualAndMonthPlanFromProduct(stripeProduct);
    if (!(annualPlan && monthPlan)) throw new Error(`Missing stripe product plans!`);
    const { amount: annualAmount  } = annualPlan;
    const { amount: monthAmount  } = monthPlan;
    return {
        [`annual${key}Plan`]: _objectSpread({}, annualPlan, {
            amountPerYear: annualPlan.tiers ? null : formatAmount(annualAmount),
            amountPerMonth: annualPlan.tiers ? null : formatAmount(annualAmount, true),
            tiers: (annualPlan.tiers || []).map((tier)=>{
                const { flatAmount , unitAmount  } = tier;
                return _objectSpread({}, tier, {
                    flatAmountPerYear: formatAmount(flatAmount),
                    unitAmountPerYear: formatAmount(unitAmount),
                    flatAmountPerMonth: formatAmount(flatAmount, true),
                    unitAmountPerMonth: formatAmount(unitAmount, true)
                });
            })
        }),
        [`month${key}Plan`]: _objectSpread({}, monthPlan, {
            amount: monthAmount,
            amountPerYear: monthPlan.tiers ? null : formatAmount(monthAmount ? monthAmount * 12 : undefined),
            amountPerMonth: monthPlan.tiers ? null : formatAmount(monthAmount),
            tiers: (monthPlan.tiers || []).map((tier)=>{
                const { flatAmount , unitAmount  } = tier;
                return _objectSpread({}, tier, {
                    flatAmountPerYear: formatAmount(flatAmount ? flatAmount * 12 : undefined),
                    unitAmountPerYear: formatAmount(unitAmount ? unitAmount * 12 : undefined),
                    flatAmountPerMonth: formatAmount(flatAmount),
                    unitAmountPerMonth: formatAmount(unitAmount)
                });
            })
        })
    };
};
