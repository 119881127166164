import styled from "styled-components";
import { space, color, layout } from "styled-system";
import { themeGet } from "sprout-ui-theme";
const Divider = styled.hr`
  height: 1px;
  margin: 0;
  border: 0;
  flex-shrink: 0;
  background: ${themeGet("colors.black.12")};

  ${space};
  ${color};
  ${layout};
`;
export default Divider;
