import { Text } from "sprout-ui-primitives";
import styled from "styled-components";
const LandingH1P = styled(Text).attrs({
    as: "p"
})``;
LandingH1P.defaultProps = {
    fontWeight: "medium",
    fontSize: 4,
    lineHeight: "32px",
    textAlign: "center"
};
export default LandingH1P;
