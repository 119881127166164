import { ItemMemberLevel } from "sprout-graphql";
import naturalCompare from "natural-compare";
const topicMemberLevelSpecificity = [
    ItemMemberLevel.Owner,
    ItemMemberLevel.Admin,
    ItemMemberLevel.Contributor,
    ItemMemberLevel.Invited,
    ItemMemberLevel.Pending, 
];
/**
 * backend `ADMIN` = frontend `EDITOR`
 * backend `CONTRIBUTOR` = frontend `MEMBER`
 *
 * backend `MEMBER` (view only role) is not supported
 * i.e. `!owner && !admin && !contributor`
 */ export function convertTopicMemberToItemMemberLevel(member) {
    const { active , owner , admin , contributor , invitedAt  } = member;
    if (active) {
        if (owner) return ItemMemberLevel.Owner;
        else if (admin) return ItemMemberLevel.Admin;
        else if (contributor) return ItemMemberLevel.Contributor;
        else return undefined;
    } else if (invitedAt) return ItemMemberLevel.Invited;
    else return ItemMemberLevel.Pending;
}
export function sortTopicMembers(members) {
    return [
        ...members
    ].sort((a, b)=>{
        const itemMemberLevelA = convertTopicMemberToItemMemberLevel(a);
        const aIndex = itemMemberLevelA ? topicMemberLevelSpecificity.indexOf(itemMemberLevelA) : -1;
        const itemMemberLevelB = convertTopicMemberToItemMemberLevel(b);
        const bIndex = itemMemberLevelB ? topicMemberLevelSpecificity.indexOf(itemMemberLevelB) : -1;
        if (aIndex !== bIndex) return aIndex - bIndex;
        if (!a.user) return 1;
        if (!b.user) return 0;
        return naturalCompare(a.user.username.toLowerCase(), b.user.username.toLowerCase());
    });
}
