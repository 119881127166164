import { anchorStyles } from "sprout-ui-dom/dist/Landing";
import { themeGet } from "sprout-ui-theme";
import styled from "styled-components";
const AppBarNavList = styled.ul`
  display: inline-flex;
  align-items: center;

  li {
    display: inline-block;

    + li {
      margin-left: 16px;
    }

    a {
      font-size: ${themeGet("fontSizes.3")}px;
      font-weight: ${themeGet("fontWeights.bold")};
    }

    :not(:last-child) a {
      ${anchorStyles};
    }
  }
`;
export default AppBarNavList;
