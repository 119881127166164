import { useRef, useEffect } from "react";
const useEventListener = (eventName, handler, disable = false, element = global)=>{
    const savedHandler = useRef();
    useEffect(()=>{
        savedHandler.current = handler;
    }, [
        handler
    ]);
    useEffect(()=>{
        const isSupported = element && element.addEventListener;
        if (!isSupported || disable) return undefined;
        const eventListener = (event)=>savedHandler.current(event);
        element.addEventListener(eventName, eventListener);
        return ()=>{
            element.removeEventListener(eventName, eventListener);
        };
    }, [
        eventName,
        element,
        disable
    ]);
};
export default useEventListener;
