import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import useCurrentUser from "@sprout/client-graphql/dist/useCurrentUser";
import { useGoBack } from "@sprout/providers/dist/goBack";
import useSpecialTopic from "@sprout/hooks/dist/useSpecialTopic";
import { useActionRegistryGlobalHandler, useActionRegistryNavigation } from "@sprout/list-navigation";
const GlobalKeyboardShortcuts = ()=>{
    var ref;
    const history = useHistory();
    const { goBack  } = useGoBack();
    const currentUser = useCurrentUser();
    const startPage = currentUser === null || currentUser === void 0 ? void 0 : (ref = currentUser.userSetting) === null || ref === void 0 ? void 0 : ref.startPage;
    const { readLaterURL , myTasksURL  } = useSpecialTopic();
    useActionRegistryNavigation("NEW_TOPIC", "/new-tree");
    useActionRegistryGlobalHandler("GO_BACK", goBack);
    useActionRegistryGlobalHandler("GO_FORWARD", useCallback(()=>{
        history.goForward();
        return true;
    }, [
        history
    ]));
    const skip = !currentUser;
    useActionRegistryNavigation("GO_TOPICS", "/trees", {
        skip
    }); //TODO: Change route to "/all-trees"
    useActionRegistryNavigation("GO_TREES", "/home", {
        skip
    }); //TODO: Change route "/trees"
    useActionRegistryNavigation("GO_WORKSPACE", "/my/workspaces", {
        skip
    });
    useActionRegistryNavigation("GO_FOLLOWING", "/following", {
        skip
    });
    useActionRegistryNavigation("GO_NOTIFICATIONS", "/notifications", {
        skip
    });
    useActionRegistryNavigation("GO_SET_ASIDE", "/bookmarks", {
        skip
    });
    useActionRegistryNavigation("GO_ACTIVITY", "/activity", {
        skip
    });
    useActionRegistryNavigation("GO_AGENDA", "/agenda", {
        skip
    });
    useActionRegistryNavigation("GO_BOOKMARKS", "/bookmarks", {
        skip
    });
    useActionRegistryNavigation("GO_HOME", startPage || "/", {
        skip
    });
    useActionRegistryNavigation("GO_DRAFTS", "/drafts", {
        skip
    });
    useActionRegistryNavigation("GO_SETTINGS", "/my/settings", {
        skip
    });
    useActionRegistryNavigation("GO_PRIORITIES", "/priorities", {
        skip
    });
    useActionRegistryNavigation("GO_SHORTCUTS", "/shortcuts", {
        skip
    });
    useActionRegistryNavigation("GO_MY_TASKS", myTasksURL, {
        skip
    });
    useActionRegistryNavigation("GO_READ_LATER", readLaterURL, {
        skip
    });
    useActionRegistryNavigation("GO_PROFILE", `/${(currentUser === null || currentUser === void 0 ? void 0 : currentUser.username) || ""}`, {
        skip
    });
    useActionRegistryNavigation("GO_RECENT", "/recent", {
        skip
    });
    useActionRegistryNavigation("GO_MY_ASSIGNMENTS", `/my/assignments`, {
        skip
    });
    useActionRegistryNavigation("GO_MY_ASSIGNINGS", `/my/assignings`, {
        skip
    });
    useActionRegistryNavigation("GO_TRASH", "/trash", {
        skip
    });
    useActionRegistryNavigation("GO_ARCHIVED", "/archived", {
        skip
    });
    return null;
};
export default GlobalKeyboardShortcuts;
