let FriendlyPoller = class FriendlyPoller {
    destroy() {
        if (this.backgroundInterval) {
            clearInterval(this.backgroundInterval);
            this.backgroundInterval = null;
        }
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }
    constructor(callback, periodWhenVisible, periodWhenHidden = periodWhenVisible * 20){
        this.callback = callback;
        this.periodWhenVisible = periodWhenVisible;
        this.periodWhenHidden = periodWhenHidden;
        this.handleVisibilityChange = ()=>{
            if (document.hidden) {
                if (this.interval != null) {
                    clearInterval(this.interval);
                    this.interval = null;
                }
                if (this.backgroundInterval == null) this.backgroundInterval = window.setInterval(this.callback, this.periodWhenHidden);
            } else {
                if (this.backgroundInterval != null) {
                    clearInterval(this.backgroundInterval);
                    this.backgroundInterval = null;
                }
                if (this.interval == null) this.interval = window.setInterval(this.callback, this.periodWhenVisible);
                if (!this.wasVisible) // Just focussed tab - refresh!
                this.callback();
            }
            this.wasVisible = !document.hidden;
        };
        this.backgroundInterval = null;
        this.interval = null;
        this.wasVisible = true;
        document.addEventListener("visibilitychange", this.handleVisibilityChange);
        this.handleVisibilityChange();
    }
};
/**
 * This'll call `callback` every `periodWhenVisible` milliseconds when the tab
 * is visible, or every `periodWhenHidden` when it's not.
 *
 * You **MUST** remember to call `poller.destroy()` when you're done with it,
 * otherwise you'll cause a memory leak.
 *
 * Window visibility is detected via `document.hidden` - if the browser does
 * not support that then it's falsy and thus it's seen as always being visible.
 */ export { FriendlyPoller as default };
