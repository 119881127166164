const flagDefaults = {
    BILLING: false,
    SUBSCRIPTIONS: false
};
const knownFlags = Object.keys(flagDefaults);
// To disable a default flag, set it in SPROUT_FEATURE_FLAGS preceded by a -;
// e.g. SPROUT_FEATURE_FLAGS="-MY_FEATURE"
const defaultFlags = knownFlags.filter((f)=>f in flagDefaults && flagDefaults[f] === true);
const rawFlagsString = typeof window !== "undefined" ? window.SPROUT_CONFIG.FEATURE_FLAGS : process.env.SPROUT_FEATURE_FLAGS;
// In case no flags are set:
const flagsString = rawFlagsString || "";
const overrideFlags = flagsString.split(",");
const flags = [
    ...defaultFlags.filter((flag)=>overrideFlags.includes(`-${flag}`)),
    ...overrideFlags.filter((flag)=>!flag.startsWith("-")), 
];
export default function flagEnabled(flagName) {
    if (!knownFlags.includes(flagName)) throw new Error(`Unknown flag '${flagName}', known flags: '${knownFlags.join(",")}`);
    return flags.includes(flagName);
};
