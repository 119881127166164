import activity from "@sprout/assets/images/features/activity-thumbnail.png";
import agenda from "@sprout/assets/images/features/agenda-thumbnail.png";
import assignments from "@sprout/assets/images/features/assignments-thumbnail.png";
import browser from "@sprout/assets/images/features/browser-extension-thumbnail.png";
import discussions from "@sprout/assets/images/features/discussions-thumbnail.png";
import followingFeed from "@sprout/assets/images/features/following-feed-thumbnail.png";
import home from "@sprout/assets/images/features/home-thumbnail.png";
import keyboard from "@sprout/assets/images/features/keyboard-shortcuts-thumbnail.png";
import labels from "@sprout/assets/images/features/labels-thumbnail.png";
import leaves from "@sprout/assets/images/features/leaves-thumbnail.png";
import links from "@sprout/assets/images/features/links-thumbnail.png";
import notes from "@sprout/assets/images/features/notes-thumbnail.png";
import notifications from "@sprout/assets/images/features/notifications-thumbnail.png";
import priorities from "@sprout/assets/images/features/priorities-thumbnail.png";
import privacy from "@sprout/assets/images/features/privacy-thumbnail.png";
import profile from "@sprout/assets/images/features/profile-thumbnail.png";
import todos from "@sprout/assets/images/features/todos-thumbnail.png";
import trees from "@sprout/assets/images/features/trees-thumbnail.png";
import workspace from "@sprout/assets/images/features/workspace-thumbnail.png";
import activity1 from "@sprout/assets/images/feature/activity-feature.png";
import agenda1 from "@sprout/assets/images/feature/agenda-feature.png";
import assignments1 from "@sprout/assets/images/feature/assignments-feature.png";
import browser1 from "@sprout/assets/images/feature/browser-extension-feature.png";
import discussions1 from "@sprout/assets/images/feature/discussions-feature.png";
import followingFeed1 from "@sprout/assets/images/feature/following-feed-feature.png";
import home1 from "@sprout/assets/images/feature/home-feature.png";
import keyboard1 from "@sprout/assets/images/feature/keyboard-shortcuts-feature.png";
import labels1 from "@sprout/assets/images/feature/labels-feature.png";
import leaves1 from "@sprout/assets/images/feature/leaves-feature.png";
import links1 from "@sprout/assets/images/feature/links-feature.png";
import notes1 from "@sprout/assets/images/feature/notes-feature.png";
import notifications1 from "@sprout/assets/images/feature/notifications-feature.png";
import priorities1 from "@sprout/assets/images/feature/priorities-feature.png";
import privacy1 from "@sprout/assets/images/feature/privacy-feature.png";
import profile1 from "@sprout/assets/images/feature/profile-feature.png";
import todos1 from "@sprout/assets/images/feature/todos-feature.png";
import trees1 from "@sprout/assets/images/feature/trees-feature.png";
import workspace1 from "@sprout/assets/images/feature/workspace-feature.png";
const features = [
    {
        title: "Home surfaces only what you need",
        description: "A dashboard of your trees, deadlines, priorities, and reading list.",
        fragment: "home",
        thumbnail: home,
        feature: home1,
        fpb: 86.86192468619247
    },
    {
        title: "Organize using tree-like groupings",
        description: "Unlike to-do apps, Sprout trees are nest-able allowing you to save diverse information.",
        fragment: "trees",
        thumbnail: trees,
        feature: trees1,
        fpb: 93.67866549604916
    },
    {
        title: "Save diverse kinds of content together",
        description: "Collect bookmarks, notes, to-dos, discussions and more in leaves without reaching for another app.",
        fragment: "leaves",
        thumbnail: leaves,
        feature: leaves1,
        fpb: 113.46153846153845
    },
    {
        title: "Put it on the agenda",
        description: "Schedule when to work on something, set deadlines sparingly, and add optional reminders.",
        fragment: "agenda",
        thumbnail: agenda,
        feature: agenda1,
        fpb: 80.12313104661389
    },
    {
        title: "Make it a priority",
        description: "No need to over schedule your day. Track it in your ordered priority list.",
        fragment: "priorities",
        thumbnail: priorities,
        feature: priorities1,
        fpb: 66.46017699115045
    },
    {
        title: "Save it from the web",
        description: "Save links with a click or keystroke. Add it it to Read Later, To-dos, Public Profile, or categorize it.",
        fragment: "browser-extension",
        thumbnail: browser,
        feature: browser1,
        fpb: 83.77192982456141
    },
    {
        title: "Use links in your workflow",
        description: "Save links with context – like a social post. Clip the important bits quickly.",
        fragment: "links",
        thumbnail: links,
        feature: links1,
        fpb: 63.964757709251096
    },
    {
        title: "No need for a notes app",
        description: "Your notes will always be close to what they relate to.",
        fragment: "notes",
        thumbnail: notes,
        feature: notes1,
        fpb: 84.58110516934046
    },
    {
        title: "To-dos",
        description: "Add to-do lists to anything you save to track and check off follow-up tasks.",
        fragment: "todos",
        thumbnail: todos,
        feature: todos1,
        fpb: 83.72911169744943
    },
    {
        title: "Discuss with collaborators",
        description: "Start a discussion with a group, a team or a public community. Notify with mentions.",
        fragment: "discussions",
        thumbnail: discussions,
        feature: discussions1,
        fpb: 70.54735013032146
    },
    {
        title: "Categorize using labels",
        description: "Create a unique label cluster relevant to each tree.",
        fragment: "labels",
        thumbnail: labels,
        feature: labels1,
        fpb: 77.48226950354609
    },
    {
        title: "Assign important work",
        description: "Track your assignments and what you’ve assigned.",
        fragment: "assignments",
        thumbnail: assignments,
        feature: assignments1,
        fpb: 39.733333333333334
    },
    {
        title: "Track latest activity",
        description: "View everything that’s changed at a glance.",
        fragment: "activity",
        thumbnail: activity,
        feature: activity1,
        fpb: 100.70609002647836
    },
    {
        title: "Share collections on your profile",
        description: "Links are saved and organized into trees for posterity – like a syllabus.",
        fragment: "profile",
        thumbnail: profile,
        feature: profile1,
        fpb: 111.01474414570684
    },
    {
        title: "Trees + people = workspaces",
        description: "Create workspaces for your business, organizations, communities and clubs.",
        fragment: "workspace",
        thumbnail: workspace,
        feature: workspace1,
        fpb: 75.53832902670112
    },
    {
        title: "Control privacy of your trees",
        description: "You control each tree and whether it is private, public or visible only to team members.",
        fragment: "privacy",
        thumbnail: privacy,
        feature: privacy1,
        fpb: 70.29126213592232
    },
    {
        title: "Follow people with similar interests",
        description: "When you receive an update in your newsfeed, the leaf links back to a collection of related leaves.",
        fragment: "following-feed",
        thumbnail: followingFeed,
        feature: followingFeed1,
        fpb: 91.09947643979058
    },
    {
        title: "Notifications in one place",
        description: "Sprout helps you stay up to date with configurable app, email, and browser notifications.",
        fragment: "notifications",
        thumbnail: notifications,
        feature: notifications1,
        fpb: 66.51904340124003
    },
    {
        title: "Keyboard first design",
        description: "Work faster with easy to learn keyboard shortcuts.",
        fragment: "keyboard-shortcuts",
        thumbnail: keyboard,
        feature: keyboard1,
        fpb: 110.94700260642921
    }, 
];
export default features;
