import { useEffect, useRef } from "react";
export default function useFnRef(fn) {
    const ref = useRef(fn);
    useEffect(()=>{
        ref.current = fn;
    }, [
        fn
    ]);
    return ref;
};
