import NProgress from "nprogress";
let loadingCounter = 0;
function debug(text) {
    if (typeof console.debug === "function") console.debug(text);
}
/**
 * Starts an NProgress bar for the named task if there's no current NProgress
 * bar; otherwise increments the bar. Returns a function that you *MUST CALL*
 * when your task is done, whether that be due to error or success.
 */ export function startTask(task) {
    let done = false;
    debug(`Performing task '${task}'`);
    loadingCounter++;
    if (loadingCounter === 1) NProgress.start();
    else NProgress.inc();
    return (preserve = false)=>{
        if (done) throw new Error(`Cannot complete task '${task}' twice!`);
        done = true;
        debug(`Task '${task}' completed`);
        loadingCounter--;
        if (loadingCounter === 0 && !preserve) NProgress.done();
        else if (loadingCounter < 0) // Something went wrong
        loadingCounter = 0;
        else NProgress.inc();
    };
}
export function abortAllTasks(msg) {
    debug(msg || `Something catastrophic went wrong; aborting all in progress loading bars`);
    loadingCounter = 0;
    if (NProgress.isStarted()) NProgress.done();
}
