import styled from "styled-components";
import { space, color, layout, border, flex, typography, position } from "styled-system";
import shouldForwardProp from "@styled-system/should-forward-prop";
const Box = styled.div.withConfig({
    shouldForwardProp: (prop)=>shouldForwardProp(prop)
})`
  ${space};
  ${color};
  ${layout};
  ${border};
  ${flex};
  ${typography};
  ${position};
`;
export default Box;
