/* eslint-disable @typescript-eslint/unbound-method, no-param-reassign */ import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useActionRegistryGlobalHandler from "./useActionRegistryGlobalHandler";
/**
 * Even more convenient syntax when your action should redirect to a new URL.
 */ export default function useActionRegistryNavigation(action, targetUrl, options = {}) {
    const { skip  } = options;
    const { push  } = useHistory();
    const { pathname  } = useLocation();
    useActionRegistryGlobalHandler(action, useCallback(()=>{
        push(targetUrl);
        return true;
    }, [
        push,
        targetUrl
    ]), useMemo(()=>({
            skip: skip || pathname === targetUrl,
            preventDefault: true
        }), [
        skip,
        targetUrl,
        pathname
    ]));
};
