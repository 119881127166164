import { Box } from "sprout-ui-primitives";
import styled from "styled-components";
import LandingSection from "./LandingSection";
export const LandingMaxWidth = styled(Box)``;
LandingMaxWidth.defaultProps = {
    maxWidth: "840px",
    mx: "auto"
};
export const LandingSectionMaxWidth = styled(LandingMaxWidth).attrs({
    as: LandingSection
})``;
