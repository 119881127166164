import styled from "styled-components";
export default styled.div`
  position: relative;
  pointer-events: none;

  a,
  button {
    pointer-events: auto;
  }
`;
