function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { useCallback } from "react";
import { useAssignmentDialog_AssignManyMutation } from "sprout-graphql";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
import update from "immutability-helper";
import { generateOptimisticId, generateRandomKey } from "@sprout/client-lib";
export default function useAssignMany(users) {
    const [assignMany] = useAssignmentDialog_AssignManyMutation();
    const showSnackbar = useSnackbar();
    return useCallback((variables)=>{
        const { assigneesToAdd , assigneesToRemove , posts  } = variables;
        const itemIds = posts.map((post)=>post.id);
        const userToAdd = users.find((user)=>assigneesToAdd.includes(user.id));
        const [userIdToRemove] = assigneesToRemove;
        assignMany({
            variables: _objectSpread({
                itemIds
            }, variables),
            optimisticResponse: {
                __typename: "Mutation",
                assignMany: {
                    __typename: "AssignManyPayload",
                    items: posts.map((post)=>{
                        return update(post, {
                            assignments: {
                                $apply: (assignments)=>{
                                    return userToAdd ? [
                                        {
                                            id: generateOptimisticId(),
                                            nodeId: generateRandomKey(),
                                            assignee: userToAdd
                                        },
                                        ...assignments.filter(({ assignee  })=>{
                                            return (assignee === null || assignee === void 0 ? void 0 : assignee.id) !== userToAdd.id;
                                        }), 
                                    ] : assignments.filter(({ assignee  })=>{
                                        return (assignee === null || assignee === void 0 ? void 0 : assignee.id) !== userIdToRemove;
                                    });
                                }
                            }
                        });
                    })
                }
            }
        }).catch(()=>{
            showSnackbar.error();
        });
    }, [
        assignMany,
        showSnackbar,
        users
    ]);
};
