import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
function nodeEqualsType({ types , type  }) {
    return Array.isArray(types) && types.includes(type);
}
const TrailingNode = Extension.create({
    name: "trailingNode",
    addOptions () {
        return {
            node: "paragraph",
            notAfter: [
                "paragraph"
            ]
        };
    },
    addProseMirrorPlugins () {
        const plugin = new PluginKey(this.name);
        const disabledNodes = Object.entries(this.editor.schema.nodes).map(([, value])=>value).filter((node)=>this.options.notAfter.includes(node.name));
        return [
            new Plugin({
                key: plugin,
                appendTransaction: (_, __, state)=>{
                    const { doc , tr  } = state;
                    const schema = state.schema;
                    const shouldInsertNodeAtEnd = plugin.getState(state);
                    const endPosition = doc.content.size;
                    const type = schema.nodes[this.options.node];
                    if (!shouldInsertNodeAtEnd) return undefined;
                    return tr.insert(endPosition, type.create());
                },
                state: {
                    init: (_, state)=>{
                        const lastNode = state.tr.doc.lastChild;
                        if (!lastNode) return false;
                        return !nodeEqualsType({
                            type: lastNode.type,
                            types: disabledNodes
                        });
                    },
                    apply: (tr, value)=>{
                        if (!tr.docChanged) return value;
                        const lastNode = tr.doc.lastChild;
                        if (!lastNode) return false;
                        return !nodeEqualsType({
                            type: lastNode.type,
                            types: disabledNodes
                        });
                    }
                }
            }), 
        ];
    }
});
export default TrailingNode;
