import { useEffect, useCallback, useRef, useMemo } from "react";
import useActionRegistryGlobalHandler from "./useActionRegistryGlobalHandler";
const $$MULTISELECT = Symbol("multiselect");
export default function useActionRegistryMultiselectAction({ escape , complete  }) {
    const resolveReject = useRef(null);
    // Auto-complete on unrender.
    useEffect(()=>{
        return ()=>{
            if (resolveReject.current) {
                resolveReject.current.resolve();
                resolveReject.current = null;
            }
        };
    }, []);
    // When complete, complete.
    useEffect(()=>{
        if (resolveReject.current && complete) {
            resolveReject.current.resolve();
            resolveReject.current = null;
        }
    }, [
        complete
    ]);
    const stopMultiselectAction = useMemo(()=>(error)=>{
            if (resolveReject.current) {
                if (error) {
                    resolveReject.current.reject(new Error("Escape pressed"));
                    resolveReject.current = null;
                    return true;
                } else {
                    resolveReject.current.resolve();
                    resolveReject.current = null;
                    return true;
                }
            }
            return false;
        }, []);
    useActionRegistryGlobalHandler("ESCAPE", useCallback(()=>{
        if (escape) {
            stopMultiselectAction(new Error("Escape pressed"));
            escape();
            return true;
        }
        return false;
    }, [
        stopMultiselectAction,
        escape
    ]), {
        skip: !escape
    });
    const startMultiselectAction = useCallback((callback)=>{
        return Object.assign(new Promise((resolve, reject)=>{
            if (resolveReject.current) resolveReject.current.reject(new Error("Replaced"));
            resolveReject.current = {
                resolve,
                reject
            };
            callback();
        }), {
            [$$MULTISELECT]: true
        });
    }, []);
    return useMemo(()=>({
            startMultiselectAction,
            stopMultiselectAction
        }), [
        startMultiselectAction,
        stopMultiselectAction
    ]);
};
