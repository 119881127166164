import { themeGet } from "sprout-ui-theme";
import styled from "styled-components";
const Input = styled("input")`
  height: 40px;
  padding: 0 8px;

  width: 100%;

  appearance: none;
  outline: 0;
  border: 0;
  border-radius: 30px;

  color: ${themeGet("colors.black.87")};
  ::placeholder {
    color: ${themeGet("colors.black.60")};
  }

  background: ${themeGet("colors.white.100")};
  border: 1px solid ${themeGet("colors.black.12")};

  :disabled {
    border: 1px solid ${themeGet("colors.black.6")};
    cursor: not-allowed;

    color: ${themeGet("colors.black.16")};

    ::placeholder {
      color: ${themeGet("colors.black.16")};
    }
  }
`;
export default Input;
