import { DateFormatter, getLocalTimeZone, startOfWeek, today } from "@internationalized/date";
import { useMemo } from "react";
import { useLocale } from "react-aria";
export default function useCustomWeekDays(weekday = "short") {
    const { locale  } = useLocale();
    const weekDays = useMemo(()=>{
        const timezone = getLocalTimeZone();
        const weekStart = startOfWeek(today(timezone), locale);
        return [
            ...new Array(7).keys()
        ].map((index)=>{
            const date = weekStart.add({
                days: index
            });
            const dateDay = date.toDate(timezone);
            return new DateFormatter(locale, {
                weekday
            }).format(dateDay);
        });
    }, [
        locale,
        weekday
    ]);
    return weekDays;
};
