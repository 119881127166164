import { themeGet } from "sprout-ui-theme";
import { Text as BaseText } from "sprout-ui-primitives";
import styled from "styled-components";
const LandingH2P = styled(BaseText).attrs({
    as: "p"
})`
  em {
    font-style: normal;
    font-weight: ${themeGet("fontWeights.bold")};
  }
`;
LandingH2P.defaultProps = {
    fontSize: 3,
    lineHeight: "30px",
    textAlign: "center"
};
export default LandingH2P;
