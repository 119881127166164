import { Onboard } from "@sprout/client-lib";
import useAppAddUserFlag from "@sprout/client-graphql/dist/mutations/useAppAddUserFlag";
import { useCallback } from "react";
import useCurrentUser from "@sprout/client-graphql/dist/useCurrentUser";
import useCheckUserFlag from "./useCheckUserFlag";
function useOnboardFlag(flag) {
    const addUserFlag = useAppAddUserFlag();
    const currentUser = useCurrentUser();
    const doesFlagExist = useCheckUserFlag(flag);
    return [
        /**
     * Fake onboard if `currentUser` does not exists, so that the
     * `OnboardMessage` does not appear
     */ currentUser ? doesFlagExist : true,
        useCallback(()=>{
            if (!doesFlagExist) addUserFlag(flag);
        }, [
            addUserFlag,
            flag,
            doesFlagExist
        ]), 
    ];
}
export function useIsWelcomePageOnboard() {
    return useOnboardFlag(Onboard.ONBOARD_WELCOME);
}
export function useIsWelcomePageStepOneOnboard() {
    return useOnboardFlag(Onboard.ONBOARD_WELCOME_1);
}
export function useIsReadLaterPageOnboard() {
    return useOnboardFlag(Onboard.ONBOARD_READ_LATER);
}
export function useIsMyTasksPageOnboard() {
    return useOnboardFlag(Onboard.ONBOARD_MY_TASKS);
}
export function useIsHomePageOnboard() {
    return useOnboardFlag(Onboard.ONBOARD_HOME);
}
export function useIsNewTopicPageOnboard() {
    return useOnboardFlag(Onboard.ONBOARD_NEW_TOPIC);
}
export function useIsTopicPageOnboard() {
    return useOnboardFlag(Onboard.ONBOARD_TOPIC);
}
