/**
 * NOTE:
 * `starredTopicIds` can include archived, deleted and permanently deleted
 * trees, i.e inactive trees.
 *
 * This messes up the sort order because the index of the list no longer reflects
 * the `starredTopicIds` array correctly.
 *
 * The corrected array accounts for this, and it sorts all inactive trees after
 * active trees.
 *
 * Technically this approach will mess up the original order of the inactive trees,
 * but 🤷‍♂️, don't think it matters here.
 *
 */ export function correctStarredTopicIds(starredTopicIds, currentlyStarredTopics) {
    return starredTopicIds.reduce((acc, currentValue)=>{
        if (typeof currentValue !== "number") return acc;
        const valid = currentlyStarredTopics.some((id)=>id === currentValue);
        const [match, reject] = acc;
        if (valid) return [
            [
                ...match,
                currentValue
            ],
            reject
        ];
        return [
            match,
            [
                ...reject,
                currentValue
            ]
        ];
    }, [
        [],
        []
    ]);
}
export default function sortStarredTopics(starredTopics, starredTopicIds) {
    const [matched] = correctStarredTopicIds(starredTopicIds, starredTopics.map(({ id  })=>id));
    return [
        ...starredTopics
    ].sort((a, b)=>{
        const indexA = matched.findIndex((id)=>id === a.id);
        const indexB = matched.findIndex((id)=>id === b.id);
        return indexA - indexB;
    });
};
