import { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { createContainer } from "unstated-next";
import useCurrentUser from "@sprout/client-graphql/dist/useCurrentUser";
import { useRouteHistories } from "./routeHistories";
const GoBackContainer = createContainer(()=>{
    var ref;
    const currentUser = useCurrentUser();
    const history = useHistory();
    const startPage = (currentUser === null || currentUser === void 0 ? void 0 : (ref = currentUser.userSetting) === null || ref === void 0 ? void 0 : ref.startPage) || "/";
    const routeHistories = useRouteHistories();
    const isLastRoute = routeHistories.length === 1;
    const goBackRef = useRef(undefined);
    const goBack = useCallback(()=>{
        if (!isLastRoute) history.goBack();
        else history.replace(goBackRef.current || startPage);
        return true;
    }, [
        history,
        isLastRoute,
        startPage
    ]);
    return {
        goBack,
        goBackRef
    };
});
export const useGoBack = GoBackContainer.useContainer;
export const GoBackProvider = GoBackContainer.Provider;
export function useSetGoBackURL(goBackURL) {
    const { goBackRef  } = useGoBack();
    useEffect(()=>{
        goBackRef.current = goBackURL;
        return ()=>{
            goBackRef.current = undefined;
        };
    }, [
        goBackRef,
        goBackURL
    ]);
}
export function GoBack(props) {
    const { goBackURL  } = props;
    useSetGoBackURL(goBackURL);
    return null;
}
