export const breakpoints = {
    small: 600,
    medium: 768,
    large: 1024,
    extraLarge: 1440
};
const targets = {
    pointer: "and (hover: hover) and (pointer: fine)",
    touch: "and (hover: none) and (pointer: coarse)"
};
const getTarget = (mode)=>mode ? ` ${targets[mode]}` : "";
const getBreakpoint = (name, direction)=>{
    if (name !== "small" && name !== "medium" && name !== "large" && name !== "extraLarge") return name;
    return `${breakpoints[name] - (direction === "up" ? 0 : 0.02)}px`;
};
export const up = (name, targetName)=>{
    return `@media only screen and (min-width: ${getBreakpoint(name, "up")})${getTarget(targetName)}`;
};
export const down = (name, mode)=>{
    return `@media only screen and (max-width: ${getBreakpoint(name, "down")})${getTarget(mode)}`;
};
export const target = (targetName)=>{
    return `@media only screen ${targets[targetName]}`;
};
export const media = {
    up,
    down,
    target
};
