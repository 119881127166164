import parseText from "./parse/text";
import parseDraftJSRaw from "./parse/draftjs";
import parseTipTapRaw from "./parse/tiptap";
export * from "./format";
export * from "./interfaces";
/**
 * For internal use only.
 */ export function isDraftJSEditorState(state) {
    return typeof state === "object" && state && "getCurrentContent" in state || false;
}
export function assertDraftJSEditorState(state) {
    if (!isDraftJSEditorState(state)) throw new Error("Expected a DraftJS state");
}
/**
 * For internal use only.
 */ export function isDraftJSEditorRawState(state) {
    const o = state;
    return !!(typeof o === "object" && o && "blocks" in o && "entityMap" in o && Array.isArray(o.blocks) && typeof o.entityMap === "object" && o.entityMap);
}
export function assertDraftJSEditorRawState(state) {
    if (!isDraftJSEditorRawState(state)) throw new Error("Expected a raw DraftJS state");
}
export function isSproutTipTapEditorRawState(state) {
    const o = state;
    return typeof o === "object" && o && "type" in o && "json" in o && o.type === "tiptap" && o.json;
}
export function parse(input, returnTextIfEmpty = false) {
    if (isDraftJSEditorRawState(input)) // It must be a draft.js raw input
    return parseDraftJSRaw(input, returnTextIfEmpty);
    else if (isSproutTipTapEditorRawState(input)) return parseTipTapRaw(input, returnTextIfEmpty);
    else if (typeof input === "string") return parseText(input, returnTextIfEmpty);
    else throw new Error("Input to parse() not recognised");
}
