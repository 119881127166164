import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";
export default function renderSuggestion(componentToRender) {
    return function render() {
        let component;
        let popup;
        return {
            onStart: (props)=>{
                component = new ReactRenderer(componentToRender, {
                    props,
                    editor: props.editor
                });
                // @ts-ignore
                popup = tippy("body", {
                    getReferenceClientRect: props.clientRect,
                    appendTo: ()=>document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: "manual",
                    placement: "bottom-start"
                });
            },
            onUpdate (props) {
                component.updateProps(props);
                popup[0].setProps({
                    // @ts-ignore
                    getReferenceClientRect: props.clientRect
                });
            },
            onKeyDown (props) {
                var ref;
                if (props.event.key === "Escape") {
                    popup[0].hide();
                    return true;
                }
                const onKeyDown = (ref = component.ref) === null || ref === void 0 ? void 0 : ref.onKeyDown;
                return onKeyDown ? onKeyDown(props) : false;
            },
            onExit () {
                if (popup) popup[0].destroy();
                if (component) component.destroy();
            }
        };
    };
};
