import { Text } from "sprout-ui-primitives";
import styled from "styled-components";
const MenuTitle = styled(Text)`
  text-transform: uppercase;
`;
MenuTitle.defaultProps = {
    fontWeight: "bold",
    fontSize: 1,
    mx: 3,
    mb: 1,
    color: "#FFD953"
};
export default MenuTitle;
