import styled from "styled-components";
import { themeGet } from "sprout-ui-theme";
const EditorWrapper = styled("div")`
  font-size: 16px;
  line-height: 1.5;

  .ProseMirror {
    outline: none;

    /* Empty paragraph (to render new line) */
    p:empty::after {
      content: "\\00a0";
    }

    /* Placeholder */
    .placeholder {
      &:before {
        color: ${themeGet("colors.black.38")};
        content: attr(data-placeholder);
        float: left;
        height: 0;
        opacity: 0;
        pointer-events: none;
      }

      &:nth-child(1):before {
        opacity: 1;
      }
    }

    &.ProseMirror-focused {
      .placeholder:before {
        opacity: 1;
      }
    }

    .ProseMirror-selectednode {
      outline: 2px solid rgb(14, 165, 233);
    }

    .node-image {
      position: relative;
      background: ${themeGet("colors.white.100")};
      margin-bottom: 16px;

      figure {
        display: flex;
        align-items: center;
        flex-direction: column;

        margin: 0;
      }

      figcaption {
        margin-top: 8px;
        text-align: center;
        font-size: 0.875em;
        font-style: italic;
      }

      &.placeholder img {
        opacity: 50%;
      }

      img {
        max-width: 100%;
      }
    }

    /* Headings */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 24px;
      margin-bottom: 16px;
      font-weight: 600;
      line-height: 1.25;
    }

    h2 {
      font-size: 1.5em;
    }

    h3 {
      font-size: 1.25em;
    }

    h4 {
      font-size: 1em;
    }

    h5 {
      font-size: 0.875em;
    }

    h6 {
      font-size: 0.85em;
      color: #57606a;
    }

    /* Nested paragraphs */
    p {
      margin-top: 0;
      margin-bottom: 10px;
    }

    /* Blockquote */
    blockquote {
      margin: 0;
      padding: 0 1em;
      color: #57606a;
      border-left: 0.25em solid #d0d7de;

      > :first-child {
        margin-top: 0;
      }

      > :last-child {
        margin-bottom: 0;
      }
    }

    /* List */
    ul,
    ol {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 2em;
    }

    ul {
      list-style-type: disc;
    }

    /* 2 deep unordered lists use a circle */
    ul ul {
      list-style-type: circle;
    }

    /* 3 deep (or more) unordered lists use a square */
    ul ul ul {
      list-style-type: square;
    }

    ol {
      list-style-type: decimal;
    }

    ol ol,
    ul ol {
      list-style-type: lower-roman;
    }

    ul ul ol,
    ul ol ol,
    ol ul ol,
    ol ol ol {
      list-style-type: lower-alpha;
    }

    ul ul,
    ul ol,
    ol ol,
    ol ul {
      margin-top: 0;
      margin-bottom: 0;
    }

    li > p {
      margin-top: 16px;
    }

    li + li {
      margin-top: 0.25em;
    }

    /* Horizontal Rule */
    hr {
      box-sizing: content-box;
      overflow: hidden;
      background: transparent;
      border-bottom: 1px solid hsla(210, 18%, 87%, 1);
      height: 0.25em;
      padding: 0;
      margin: 24px 0;
      background-color: #d0d7de;
      border: 0;
    }

    hr::before {
      display: table;
      content: "";
    }

    hr::after {
      display: table;
      clear: both;
      content: "";
    }

    /* Link */
    a {
      color: #4991cc;
    }

    /* Code */
    code {
      font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
        Liberation Mono, monospace;
      font-size: 75%;
    }

    code {
      background: #f7f7f9;
      border-radius: 6px;
      padding: 2px 4px;
    }

    p > code {
      color: #d72b3f;
      border: 1px solid #e1e1e8;
    }

    pre {
      margin-top: 0;
      margin-bottom: 0;

      padding: 16px;
      overflow: auto;

      background: #f6f8fa;
      border-radius: 6px;

      > code {
        word-break: normal;
        white-space: pre;

        background: transparent;
        border-radius: 0;
        padding: 0;
      }
    }

    /* Margins (higher specificity) */
    > *:first-child {
      margin-top: 0 !important;
    }

    > *:last-child {
      margin-bottom: 0 !important;
    }

    p,
    blockquote,
    ul,
    ol,
    pre {
      margin-top: 0;
      margin-bottom: 16px;
    }

    /* Mention */
    span[data-type="mention"] {
      color: #0576b9;
      background: #eaf5fc;

      padding: 2px 4px;
      border-radius: 4px;
    }
  }
`;
export default EditorWrapper;
