import { ItemType } from "sprout-graphql";
import slugify from "./slugify";
function getItemUrl(item, owner, returnNullOnFail = false) {
    if (!item) // console.error("Unexpected arguments in `getItemUrl`");
    return "/";
    const { type , id , title  } = item;
    if (type === ItemType.Topic) {
        if (!owner || !title) // console.error("Unexpected arguments in `getItemUrl`");
        return "/";
        return `/${owner.username}/${id}-${slugify(title)}`;
    } else if (type === ItemType.Post) {
        if (item.isDraft) return `/leaf/${id}/draft`;
        else return `/leaf/${id}`;
    } else if (type === ItemType.Checklist) return `/todolist/${id}`;
    else if (type === ItemType.ChecklistItem) return `/todo/${id}`;
    else if (returnNullOnFail) return null;
    else throw new Error("Unexpected arguments in `getItemUrl`");
}
export default getItemUrl;
