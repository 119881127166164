function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { getLocalTimeZone, isToday, parseAbsolute, toCalendarDate, today } from "@internationalized/date";
import { useCallback } from "react";
import { useSetWhenForItemsMutation } from "sprout-graphql";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
import { attachNowTimeToDateValue } from "sprout-ui-dom/dist/Scheduler";
export default function useSetWhenForItems() {
    const showSnackbar = useSnackbar();
    const [setWhenForItems] = useSetWhenForItemsMutation();
    return useCallback(({ items , when: whenArg , whenReminder , today: todayArg , deadline  })=>{
        let when = whenArg;
        if (todayArg) {
            const [post] = items;
            const whenPost = post.when;
            const timezone = getLocalTimeZone();
            const whenPostCalendarDate = whenPost ? toCalendarDate(parseAbsolute(whenPost, timezone)) : null;
            const whenIsToday = whenPostCalendarDate ? isToday(whenPostCalendarDate, timezone) : false;
            when = whenIsToday ? null : attachNowTimeToDateValue(today(timezone));
        }
        setWhenForItems({
            variables: {
                itemIds: items.map((item)=>item.id),
                when,
                whenReminder,
                deadline
            },
            optimisticResponse: {
                setWhenForItems: {
                    items: items.map((item)=>_objectSpread({}, item, {
                            when: whenArg || item.when,
                            whenReminder: whenReminder || item.whenReminder,
                            deadline: deadline || item.deadline
                        })),
                    __typename: "SetWhenForItemsPayload"
                }
            }
        }).catch(()=>{
            showSnackbar.error();
        });
    }, [
        setWhenForItems,
        showSnackbar
    ]);
};
