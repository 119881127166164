import getFullName from "@sprout/client-lib/dist/getFullName";
import { useCallback, useEffect, useRef } from "react";
import { useEditor_TopicMembersQuery } from "sprout-graphql";
import { isNotNull } from "sprout-lib";
import Fuse from "fuse.js";
export default function useMentionAutocomplete({ itemId  }) {
    const { data  } = useEditor_TopicMembersQuery({
        skip: !itemId,
        variables: {
            topicId: Number(itemId)
        }
    });
    const dataRef = useRef(data);
    useEffect(()=>{
        dataRef.current = data;
    }, [
        data
    ]);
    return useCallback(({ query  })=>{
        var ref, ref1, ref2;
        const topicMembers = (ref = dataRef.current) === null || ref === void 0 ? void 0 : (ref1 = ref.item) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.rootTopicOrSelf) === null || ref2 === void 0 ? void 0 : ref2.topicMembers.nodes;
        const users = (topicMembers || []).map((topicMember)=>topicMember.user).filter(isNotNull).map((user)=>{
            return {
                username: user.username,
                fullName: getFullName(user),
                label: user.username,
                id: user.id
            };
        });
        if (query.length === 0) return users;
        const searcher = new Fuse(users, {
            keys: [
                "username",
                "fullName"
            ]
        });
        const results = searcher.search(query);
        return results.length >= 1 ? results.map((result)=>result.item) : [];
    }, []);
};
