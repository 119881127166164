import { escapeBrackets } from "../utils";
import parseText from "./text";
export default function parseDraftJSRaw({ blocks , entityMap  }, returnTextIfEmpty = false) {
    let link = null;
    if (blocks.length === 0) return {
        text: "",
        link: null
    };
    const parts = [];
    let hasNonLinkText = false;
    for(let i = 0; i < blocks.length; i++){
        const block = blocks[i];
        const { text , entityRanges , inlineStyleRanges , type: blockType  } = block;
        if (i > 0) parts.push("\n");
        entityRanges.sort((a, b)=>a.offset - b.offset);
        if (blockType === "code-block") {
            parts.push("```\n");
            parts.push(text);
            parts.push("\n```");
            hasNonLinkText = true;
        } else {
            let prefix = "";
            const addPrefix = (string)=>string.replace(/\n/g, `\n${prefix}`);
            if (blockType === "blockquote") {
                prefix = "> ";
                parts.push(prefix);
            } else if (blockType !== "unstyled") console.warn(`Unsupported block type '${blockType}'; treating as if it were 'unstyled'`);
            let pos = 0;
            let entityNumber = 0;
            while(entityNumber < entityRanges.length){
                const { offset , length , key  } = entityRanges[entityNumber++];
                if (offset > pos) {
                    const fragment = text.substring(pos, offset);
                    const { text: fragText , link: fragLink , hasNonLinkText: fragHasNonLinkText ,  } = parseText(fragment, true, pos, inlineStyleRanges);
                    link = link || fragLink;
                    hasNonLinkText = !!(hasNonLinkText || fragHasNonLinkText);
                    parts.push(addPrefix(fragText));
                }
                const { type , data  } = entityMap[key];
                if (type === "mention" && data && data.mention) {
                    const mention = data.mention.toJS ? data.mention.toJS() : data.mention; // ImmutableJS
                    const { id , name  } = mention;
                    // TODO: replace name with text.substr(offset, length)?
                    parts.push(`<@${id}|@${escapeBrackets(name)}>`);
                    hasNonLinkText = true;
                } else if (type === "LINK") {
                    const { text: finalText , link: finalLink , hasNonLinkText: finalHasNonLinkText ,  } = parseText(text.substr(offset, length), true, pos, inlineStyleRanges);
                    link = link || finalLink;
                    hasNonLinkText = !!(hasNonLinkText || finalHasNonLinkText);
                    parts.push(addPrefix(finalText));
                } else {
                    if (type !== "emoji") console.warn(`Could not process entity type '${type}'`);
                    else hasNonLinkText = true;
                    // Fallback to just passing the text through
                    parts.push(addPrefix(escapeBrackets(text.substr(offset, length))));
                }
                pos = offset + length;
            }
            const remainingText = text.substr(pos);
            if (remainingText) {
                const { text: finalText , link: finalLink , hasNonLinkText: finalHasNonLinkText ,  } = parseText(remainingText, true, pos, inlineStyleRanges);
                link = link || finalLink;
                hasNonLinkText = !!(hasNonLinkText || finalHasNonLinkText);
                parts.push(addPrefix(finalText));
            }
        }
    }
    return {
        text: returnTextIfEmpty || hasNonLinkText ? parts.join("") : "",
        link
    };
};
