import { Box } from "sprout-ui-primitives";
import styled from "styled-components";
const Badge = styled(Box).attrs({
    as: "span"
})`
  font-variant: tabular-nums;
  pointer-events: none;
`;
Badge.defaultProps = {
    px: 0,
    height: "20px",
    minWidth: "20px",
    color: "white.100",
    fontSize: 1,
    lineHeight: "20px",
    fontWeight: "bold",
    textAlign: "center",
    borderRadius: "20px",
    bg: "#f44336"
};
export default Badge;
