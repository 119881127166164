export var Onboard;
(function(Onboard) {
    Onboard[// Legacy
    "ONBOARD_JUMPTO"] = "ONBOARD_JUMPTO";
    Onboard["ONBOARD_TODAY"] = "ONBOARD_TODAY";
    Onboard["ONBOARD_INBOX"] = "ONBOARD_INBOX";
    Onboard[//
    "ONBOARD_WELCOME"] = "ONBOARD_WELCOME";
    Onboard["ONBOARD_WELCOME_1"] = "ONBOARD_WELCOME_1";
    Onboard[//
    "ONBOARD_HOME"] = "ONBOARD_HOME";
    Onboard["ONBOARD_MY_TASKS"] = "ONBOARD_MY_TASKS";
    Onboard["ONBOARD_READ_LATER"] = "ONBOARD_READ_LATER";
    Onboard[//
    "ONBOARD_TOPIC"] = "ONBOARD_TOPIC";
    Onboard["ONBOARD_TOPICS"] = "ONBOARD_TOPICS";
    Onboard["ONBOARD_NEW_TOPIC"] = "ONBOARD_NEW_TOPIC";
    Onboard["ONBOARD_POST_DRAFT"] = "ONBOARD_POST_DRAFT";
    Onboard["ONBOARD_POST_DRAFT_POST"] = "ONBOARD_POST_DRAFT_POST";
})(Onboard || (Onboard = {}));
