function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};
    var target = _objectWithoutPropertiesLoose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { createHttpLink, split } from "@apollo/client";
import { parse } from "graphql";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "apollo-utilities";
import flagEnabled from "../flags";
import { getQueryDocumentKey } from "./getQueryDocumentKey";
const httpLink = createHttpLink({
    uri: `${process.env.ROOT_URL}/graphql`,
    fetchOptions: {
        credentials: "include"
    },
    fetch: (url, options = {})=>{
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (link.persistedQueries) {
            const { body: jsonString  } = options;
            const json = JSON.parse(String(jsonString));
            const { query , operationName  } = json, rest = _objectWithoutProperties(json, [
                "query",
                "operationName"
            ]);
            const key = getQueryDocumentKey(parse(query), operationName);
            if (!key) throw new Error(`Could not find operation '${key}' in persisted query ID map`);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            const id = link.persistedQueries[key];
            if (!id) throw new Error(`Could not find persisted query for GraphQL query: '${query}'`);
            const newJson = _objectSpread({}, rest, {
                id
            });
            const newOptions = _objectSpread({}, options, {
                body: JSON.stringify(newJson)
            });
            return fetch(url, newOptions);
        } else return fetch(url, options);
    }
});
if (!process.env.ROOT_URL) throw new Error("ROOT_URL is required");
const wsLink = flagEnabled("SUBSCRIPTIONS") && process === false ? new WebSocketLink({
    uri: `${process.env.ROOT_URL.replace(/^http/, "ws")}/graphql`,
    options: {
        reconnect: true,
        reconnectionAttempts: Infinity,
        lazy: true,
        timeout: 30000,
        inactivityTimeout: 30000
    }
}) : null;
export const link = typeof window === "undefined" || !wsLink ? httpLink : split(({ query  })=>{
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
}, wsLink, httpLink);
