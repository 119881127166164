import { useState, useCallback, useEffect, useRef } from "react";
function useToggle(initialValue = false) {
    const [value, setValue] = useState(initialValue);
    const componentIsMountedRef = useRef(false);
    useEffect(()=>{
        if (componentIsMountedRef.current === false) componentIsMountedRef.current = true;
        return ()=>{
            componentIsMountedRef.current = false;
        };
    }, []);
    const safeSetValue = useCallback((nextValue)=>{
        if (componentIsMountedRef.current) setValue(nextValue);
    }, []);
    const open = useCallback(()=>{
        if (value !== true) {
            safeSetValue(true);
            return true;
        }
        return false;
    }, [
        safeSetValue,
        value
    ]);
    const close = useCallback(()=>{
        if (value !== false) {
            safeSetValue(false);
            return true;
        }
        return false;
    }, [
        safeSetValue,
        value
    ]);
    return [
        value,
        open,
        close,
        setValue
    ];
}
export default useToggle;
