import useCurrentUser from "@sprout/client-graphql/dist/useCurrentUser";
import { useMemo } from "react";
import getItemUrl from "sprout-lib/dist/getItemUrl";
export default function useSpecialTopic() {
    const currentUser = useCurrentUser();
    const { myTasksTopic , readLaterTopic , profileTopic  } = currentUser || {};
    const readLaterURL = getItemUrl(readLaterTopic, currentUser);
    const myTasksURL = getItemUrl(myTasksTopic, currentUser);
    const profileURL = getItemUrl(profileTopic, currentUser);
    return useMemo(()=>({
            myTasksTopic: myTasksTopic !== null && myTasksTopic !== void 0 ? myTasksTopic : undefined,
            readLaterTopic: readLaterTopic !== null && readLaterTopic !== void 0 ? readLaterTopic : undefined,
            profileTopic: profileTopic !== null && profileTopic !== void 0 ? profileTopic : undefined,
            readLaterURL,
            myTasksURL,
            profileURL
        }), [
        myTasksTopic,
        myTasksURL,
        profileTopic,
        profileURL,
        readLaterTopic,
        readLaterURL, 
    ]);
};
