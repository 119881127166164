/* eslint-disable import/prefer-default-export */ import update from "immutability-helper";
export function makeUpdateQueryForCollectionWithNodes(propertyName) {
    return (prevResult, { fetchMoreResult  })=>{
        var ref;
        const connection = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult[propertyName];
        if (!connection) return prevResult;
        var ref1;
        const known = new Set((ref1 = (ref = prevResult[propertyName]) === null || ref === void 0 ? void 0 : ref.nodes.map((n)=>n.nodeId)) !== null && ref1 !== void 0 ? ref1 : []);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return update(prevResult, {
            [propertyName]: {
                nodes: {
                    $push: connection.nodes.filter((n)=>!known.has(n.nodeId))
                },
                pageInfo: {
                    $set: connection.pageInfo
                }
            }
        });
    };
}
