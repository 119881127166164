function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import getFullName from "@sprout/client-lib/dist/getFullName";
import { countBy } from "lodash";
import { isPresent } from "sprout-lib";
export default function processAndMarkWorkspaceMembers(workspaceMembers, posts) {
    const totalPosts = posts.length;
    const users = posts.flatMap((post)=>post.assignments).map(({ assignee  })=>assignee).filter(isPresent);
    const userIds = users.map((user)=>user.id);
    const stats = countBy(userIds);
    return workspaceMembers.map((user)=>{
        const count = stats[user.id];
        return {
            user: _objectSpread({}, user, {
                fullName: getFullName(user)
            }),
            state: count === undefined ? "incomplete" : count === totalPosts ? "complete" : "indeterminate"
        };
    });
};
