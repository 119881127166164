// This is almost an exact copy of the remirror parser; the only difference (other than types) is that we use 'mention' instead of 'mentionAtom'.
import * as assert from "assert";
import { escapeBrackets } from "../utils";
import parseText, { formatURL } from "./text";
function leadingMainTrailing(text) {
    const lTrimmed = text.trimLeft();
    const l = text.substring(0, text.length - lTrimmed.length);
    const lrTrimmed = lTrimmed.trimRight();
    const r = lTrimmed.substring(lrTrimmed.length);
    return [
        l,
        lrTrimmed,
        r
    ];
}
export function sanitizeHref(href) {
    if (href.startsWith("//")) return `http:${href}`;
    return href;
}
function inlineContentsToText(elements, withStyles = true, listType = null, startCountAt = 1) {
    let link = null;
    let listItemCounter = startCountAt;
    const results = elements.map((el)=>{
        switch(el.type){
            case "text":
                if (withStyles) {
                    const [leadingWhitespace, innerText, trailingWhitespace] = leadingMainTrailing(el.text || "");
                    let mainText = innerText;
                    let prefix = "";
                    let suffix = "";
                    // This may be undone below
                    let hasNonLinkText = mainText.length > 0;
                    // Only process styles if mainText is not empty
                    if (el.marks && mainText.length) el.marks.forEach((mark)=>{
                        if (typeof mark === "object") switch(mark.type){
                            case "bold":
                                prefix += "*";
                                suffix = `*${suffix}`;
                                break;
                            case "italic":
                                prefix += "_";
                                suffix = `_${suffix}`;
                                break;
                            case "strike":
                                prefix += "~";
                                suffix = `~${suffix}`;
                                break;
                            case "link":
                            case "autoLink":
                                {
                                    const { href  } = mark.attrs;
                                    if (!href) throw new Error(`Invalid ${mark.type} - no href`);
                                    const sanitizedHref = sanitizeHref(String(href));
                                    prefix += `<${sanitizedHref}|`;
                                    suffix = `>${suffix}`;
                                    hasNonLinkText = false;
                                    link = link || sanitizedHref;
                                    mainText = formatURL(sanitizedHref);
                                    break;
                                }
                            case "code":
                                prefix += "`";
                                suffix = `\`${suffix}`;
                                break;
                            default:
                                console.warn(`Unsupported mark type '${mark.type}'`);
                        }
                    });
                    // Scan text for link
                    let text;
                    if (!link) ({ text , link , hasNonLinkText  } = parseText(mainText, true, 0));
                    else text = escapeBrackets(mainText);
                    hasNonLinkText = hasNonLinkText || !!leadingWhitespace || !!trailingWhitespace;
                    return {
                        hasNonLinkText,
                        link,
                        text: leadingWhitespace + prefix + text + suffix + trailingWhitespace
                    };
                } else return {
                    text: el.text || "",
                    link,
                    hasNonLinkText: false
                };
            case "image":
                throw new Error("Images not supported");
            case "mention":
                if (!el.attrs) throw new Error(`Invalid ${el.type} element - no attrs`);
                return {
                    text: `<@${el.attrs.id}|@${el.attrs.label}>`,
                    hasNonLinkText: true
                };
            case "emoji":
                if (!el.attrs) throw new Error(`Invalid ${el.type} element - no attrs`);
                return {
                    text: el.attrs.native,
                    hasNonLinkText: true
                };
            case "hardBreak":
                return {
                    text: "\n",
                    hasNonLinkText: true
                };
            case "listItem":
                {
                    const counter = listItemCounter++;
                    const listItemPrefix = listType === "ordered-list" ? `${counter}. ` : listType === "unordered-list" ? "- " : "";
                    if (!el.content) return {
                        text: `${listItemPrefix}\n`,
                        hasNonLinkText: true
                    };
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    const inside = parseBlocks(el.content, "");
                    const pad = listItemPrefix.replace(/./g, " ");
                    return {
                        text: `${listItemPrefix}${inside.text.replace(/\n/g, `\n${pad}`)}\n`,
                        hasNonLinkText: inside.hasNonLinkText,
                        link: inside.link
                    };
                }
            default:
                console.dir(el);
                throw new Error(`Unsupported inline node type '${el.type}'`);
        }
    });
    let text1 = results.map((r)=>r.text).join("");
    if (listType != null) text1 = text1.trimEnd();
    const hasNonLinkText1 = results.some((r)=>r.hasNonLinkText);
    return {
        text: text1,
        hasNonLinkText: hasNonLinkText1,
        link
    };
}
function parseBlock(block) {
    switch(block.type){
        case "codeBlock":
            {
                let text = "";
                text += "```\n";
                if (block.content) {
                    const converted = inlineContentsToText(block.content, false);
                    text += converted.text;
                }
                text += "\n```";
                return {
                    text,
                    hasNonLinkText: true,
                    link: null
                };
            }
        case "blockquote":
            {
                const prefix = "> ";
                if (block.content) // eslint-disable-next-line @typescript-eslint/no-use-before-define
                return parseBlocks(block.content, prefix);
                else return {
                    text: ""
                };
            }
        case "bulletList":
            if (block.content) {
                const converted = inlineContentsToText(block.content, true, "unordered-list");
                return converted;
            } else return {
                text: ""
            };
        case "orderedList":
            if (block.content) {
                var ref;
                const converted = inlineContentsToText(block.content, true, "ordered-list", (ref = block.attrs) === null || ref === void 0 ? void 0 : ref.start);
                return converted;
            } else return {
                text: ""
            };
        default:
            if (block.type !== "paragraph") console.warn(`Unsupported Sprout markup block type: '${block.type}'`);
            if (block.content) return inlineContentsToText(block.content);
            else return {
                text: ""
            };
    }
}
function parseBlocks(blocks, prefix = "") {
    if (!blocks) return {
        text: "",
        hasNonLinkText: false,
        link: null
    };
    const parsedBlocks = blocks.map(parseBlock);
    let text = parsedBlocks.map((b)=>b.text).join("\n");
    if (prefix) text = prefix + text.replace(/\n/g, `\n${prefix}`);
    const hasNonLinkText = parsedBlocks.some((b)=>b.hasNonLinkText);
    const linkBlock = parsedBlocks.find((b)=>b.link);
    const link = linkBlock ? linkBlock.link || null : null;
    return {
        text,
        hasNonLinkText,
        link
    };
}
export default function parseTipTapRaw(state, returnTextIfEmpty = false) {
    const { json  } = state;
    assert.equal(json.type, "doc", "Expected a TipTap document");
    if (!json.content || json.content.length === 0) return {
        text: "",
        link: null
    };
    const { text , hasNonLinkText , link  } = parseBlocks(json.content);
    return {
        text: returnTextIfEmpty || hasNonLinkText ? text : "",
        link
    };
};
