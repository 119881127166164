import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
export const imagePlaceholderPlugin = new Plugin({
    props: {
        decorations (state) {
            // @ts-ignore
            return this.getState(state);
        }
    },
    state: {
        init () {
            return DecorationSet.empty;
        },
        apply (tr, set) {
            // Adjust decoration positions to changes made by the transaction
            // eslint-disable-next-line no-param-reassign
            set = set.map(tr.mapping, tr.doc);
            // See if the transaction adds or removes any placeholders
            // @ts-ignore
            const action = tr.getMeta(this);
            if (action && "add" in action) {
                const element = document.createElement("div");
                element.className = "node-image placeholder";
                const figure = document.createElement("figure");
                const img = document.createElement("img");
                img.src = URL.createObjectURL(action.add.file);
                element.appendChild(figure);
                figure.appendChild(img);
                const deco = Decoration.widget(action.add.pos, element, {
                    id: action.add.id
                });
                // eslint-disable-next-line no-param-reassign
                set = set.add(tr.doc, [
                    deco
                ]);
            }
            if (action && "remove" in action) // eslint-disable-next-line no-param-reassign
            set = set.remove(set.find(undefined, undefined, (spec)=>spec.id === action.remove.id));
            return set;
        }
    }
});
export function findImagePlaceholder(state, id) {
    const set = imagePlaceholderPlugin.getState(state);
    const found = set.find(undefined, undefined, (spec)=>spec.id === id);
    return found.length ? [
        found[0].from,
        found[0].to
    ] : null;
}
