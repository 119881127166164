export var SearchScope;
(function(SearchScope) {
    SearchScope["my-trees"] = "my-trees";
    SearchScope["sprout"] = "sprout";
    SearchScope["people"] = "people";
})(SearchScope || (SearchScope = {}));
export var SearchVariant;
(function(SearchVariant) {
    SearchVariant[SearchVariant["DESKTOP"] = 0] = "DESKTOP";
    SearchVariant[SearchVariant["MOBILE"] = 1] = "MOBILE";
})(SearchVariant || (SearchVariant = {}));
