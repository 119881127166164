import naturalCompare from "natural-compare";
import { getTeamRole, getTopicRole } from "./getRoles";
export const teamRolesSpecificity = [
    "OWNER",
    "ADMIN",
    "MEMBER",
    "INVITED",
    "PENDING", 
];
export const topicRolesSpecificity = [
    "OWNER",
    "EDITOR",
    "MEMBER",
    "INVITED",
    "PENDING", 
];
export const sortTeamMembers = (members)=>{
    return [
        ...members
    ].sort(({ node: a  }, { node: b  })=>{
        var ref, ref1;
        const aIndex = teamRolesSpecificity.indexOf(getTeamRole(a));
        const bIndex = teamRolesSpecificity.indexOf(getTeamRole(b));
        if (aIndex === bIndex) return naturalCompare(((ref = a.user) === null || ref === void 0 ? void 0 : ref.username.toLowerCase()) || "", ((ref1 = b.user) === null || ref1 === void 0 ? void 0 : ref1.username.toLowerCase()) || "");
        return aIndex - bIndex;
    });
};
export const sortTopicMembers = (members)=>{
    return [
        ...members
    ].sort(({ node: a  }, { node: b  })=>{
        var ref, ref2;
        const aIndex = topicRolesSpecificity.indexOf(getTopicRole(a));
        const bIndex = topicRolesSpecificity.indexOf(getTopicRole(b));
        if (aIndex === bIndex) return naturalCompare(((ref = a.user) === null || ref === void 0 ? void 0 : ref.username.toLowerCase()) || "", ((ref2 = b.user) === null || ref2 === void 0 ? void 0 : ref2.username.toLowerCase()) || "");
        return aIndex - bIndex;
    });
};
export const sortTeams = (teams)=>{
    return [
        ...teams
    ].sort(({ node: a  }, { node: b  })=>{
        var ref, ref3, ref4, ref5;
        const aIndex = teamRolesSpecificity.indexOf(getTeamRole(a));
        const bIndex = teamRolesSpecificity.indexOf(getTeamRole(b));
        if (aIndex === bIndex) return naturalCompare((((ref = a.userByTeamId) === null || ref === void 0 ? void 0 : ref.firstName) || ((ref3 = a.userByTeamId) === null || ref3 === void 0 ? void 0 : ref3.username) || "").toLowerCase(), (((ref4 = b.userByTeamId) === null || ref4 === void 0 ? void 0 : ref4.firstName) || ((ref5 = b.userByTeamId) === null || ref5 === void 0 ? void 0 : ref5.username) || "").toLowerCase());
        return aIndex - bIndex;
    });
};
