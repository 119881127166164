import { Plugin } from "prosemirror-state";
import { isNotNull } from "sprout-lib";
import insertImage from "./insertImage";
const imageUploadPlugin = ({ showSnackbar  })=>new Plugin({
        props: {
            // @ts-ignore
            handleDOMEvents: {
                paste (view, event) {
                    const { props: { editable  } , state ,  } = view;
                    if (editable && !editable(state)) return false;
                    if (!event.clipboardData) return false;
                    const files = Array.from(event.clipboardData.items).map((dt)=>dt.getAsFile()).filter(isNotNull).filter((file)=>/image/i.test(file.type));
                    if (files.length === 0) return false;
                    const { tr  } = state;
                    if (!tr.selection.empty) tr.deleteSelection();
                    const pos = tr.selection.from;
                    insertImage({
                        showSnackbar,
                        view,
                        event,
                        pos,
                        files
                    });
                    return true;
                },
                drop (view, event) {
                    const { props: { editable  } , state ,  } = view;
                    if (editable && !editable(state)) return false;
                    if (!event.dataTransfer) return false;
                    const files = Array.from(event.dataTransfer.files).filter((file)=>/image/i.test(file.type));
                    if (files.length === 0) return false;
                    // grab the position in the document for the cursor
                    const result = view.posAtCoords({
                        left: event.clientX,
                        top: event.clientY
                    });
                    if (!result) return false;
                    insertImage({
                        showSnackbar,
                        view,
                        event,
                        pos: result.pos,
                        files
                    });
                    return true;
                }
            }
        }
    });
export default imageUploadPlugin;
