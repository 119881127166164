export default function checkIsSproutUrl(url) {
    try {
        const { host , pathname  } = new URL(url);
        if (process.env.NODE_ENV === "production") return {
            isSproutUrl: host === "sprout.io" && pathname !== "/i",
            pathname
        };
        const rootURL = String(process.env.ROOT_URL);
        if (url.startsWith(rootURL)) return {
            isSproutUrl: !url.includes("/i"),
            pathname
        };
        return {
            isSproutUrl: false
        };
    } catch (e) {
        return {
            isSproutUrl: false
        };
    }
};
