import { useApolloClient } from "@apollo/client";
import { useEffect } from "react";
const WindowFocusQuery = (props)=>{
    const { query  } = props;
    const client = useApolloClient();
    useEffect(()=>{
        const handler = ()=>{
            if (document.visibilityState !== "hidden") {
                if (query) client.query({
                    query,
                    fetchPolicy: "network-only"
                }).catch((error)=>{
                    console.error(error);
                });
                else client.reFetchObservableQueries().catch((error)=>{
                    console.error(error);
                });
            }
        };
        document.addEventListener("visibilitychange", handler);
        return ()=>{
            document.removeEventListener("visibilitychange", handler);
        };
    }, [
        client,
        query
    ]);
    return null;
};
export default WindowFocusQuery;
