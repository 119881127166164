import { Editor } from "@tiptap/react";
import { // useRef,
useEffect, // useReducer,
useState } from "react";
const useTipTapEditor = (options = {})=>{
    const [editor] = useState(()=>typeof window !== "undefined" ? new Editor(options) : null);
    useEffect(()=>{
        if (editor && !editor.isDestroyed) editor.setOptions(options);
    }, [
        editor,
        options
    ]);
    useEffect(()=>{
        return ()=>{
            if (editor) editor.destroy();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return editor;
};
export default useTipTapEditor;
