import { getLocalTimeZone, parseAbsoluteToLocal, Time, now, isToday, ZonedDateTime, toCalendarDate, today, toZoned } from "@internationalized/date";
function getTime(knownValues) {
    const { hour , minute  } = knownValues;
    if (hour !== undefined) return new Time(hour, minute);
    return undefined;
}
export function getTodayZonedDateTime() {
    const timezone = getLocalTimeZone();
    return now(timezone);
}
export function appendTimeToDate(date, time) {
    const { millisecond , second , hour , minute  } = time;
    return toZoned(date, getLocalTimeZone()).set({
        millisecond,
        second,
        hour,
        minute
    });
}
export function suggestDate(parsedResult, defaultTime) {
    const { start , end  } = parsedResult;
    let result = start.date();
    let knownValues = start.knownValues;
    let time = getTime(knownValues) || defaultTime;
    if (end) {
        result = end.date();
        knownValues = end.knownValues;
        time = getTime(knownValues);
    }
    let parsedDate = toCalendarDate(parseAbsoluteToLocal(result.toISOString()));
    const todayDate = today(getLocalTimeZone());
    if (time) parsedDate = appendTimeToDate(parsedDate, time);
    // If the parsed date is EXPLICITLY < Today, i.e. Yesterday
    // The level of explicitness can be known if the `year` exists
    if (parsedDate.compare(todayDate) < 0 && knownValues.year) return [];
    // Offset is needed so that we do not show dates < Today
    const offset = parsedDate.compare(todayDate) < 0 ? 1 : 0;
    // If the keyword is `Tue`, `Tuesday`
    const keys = Object.keys(knownValues);
    if (keys.length === 1 && keys[0] === "weekday") return Array.from(new Array(3), (_, index)=>{
        return parsedDate.add({
            days: (index + offset) * 7
        });
    });
    if (keys.length === 2 && keys.includes("day") && keys.includes("month")) return Array.from(new Array(3), (_, index)=>{
        return parsedDate.add({
            years: index + offset
        });
    });
    return [
        parsedDate
    ];
}
export function checkReminder(date) {
    const todayZoned = getTodayZonedDateTime();
    const isZonedDateTime = date instanceof ZonedDateTime;
    const isDateToday = isToday(date, getLocalTimeZone());
    if (isZonedDateTime && isDateToday) {
        const isGreaterThan5Minutes = date.compare(todayZoned.add({
            minutes: 5
        })) > 0;
        return !isGreaterThan5Minutes;
    }
    return false;
}
export function attachNowTimeToDateValue(value) {
    const today1 = getTodayZonedDateTime();
    return today1.set({
        era: value.era,
        day: value.day,
        month: value.month,
        year: value.year
    }).toDate().toISOString();
}
