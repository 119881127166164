function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
import { useCallback, useMemo } from "react";
import { useLabelsDialog_AlterLabelsForItemsMutation } from "sprout-graphql";
export default function useAlterLabelsForItems(labels, posts) {
    const [alterLabelsForItems] = useLabelsDialog_AlterLabelsForItemsMutation();
    const showSnackbar = useSnackbar();
    const itemIds = useMemo(()=>posts.map((post)=>post.id), [
        posts
    ]);
    const items = useMemo(()=>posts.map((post)=>({
                id: post.id,
                nodeId: post.nodeId,
                type: post.type,
                appliedLabels: post.appliedLabels,
                __typename: "Item"
            })), [
        posts
    ]);
    return useCallback(({ variables  })=>{
        const { addLabelIds , removeLabelIds  } = variables;
        const labelToAdd = labels.find((label)=>addLabelIds.includes(label.id));
        const [labelIdToRemove] = removeLabelIds;
        alterLabelsForItems({
            variables: _objectSpread({
                itemIds
            }, variables),
            optimisticResponse: {
                alterLabelsForItems: {
                    items: items.map((item)=>{
                        const { appliedLabels  } = item;
                        return _objectSpread({}, item, {
                            appliedLabels: labelToAdd ? [
                                ...(appliedLabels || []).filter((label)=>label && label.id !== labelToAdd.id),
                                labelToAdd, 
                            ] : (appliedLabels || []).filter((label)=>label && label.id !== labelIdToRemove)
                        });
                    }),
                    __typename: "AlterLabelsForItemsPayload"
                }
            }
        }).catch(()=>{
            showSnackbar.error();
        });
    }, [
        alterLabelsForItems,
        itemIds,
        items,
        labels,
        showSnackbar
    ]);
};
