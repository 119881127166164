import { createContext, useContext, useMemo } from "react";
import { ItemType } from "sprout-graphql";
import { jumpToPages } from "./jumpToPages";
export const JumpHistoriesContext = createContext({
    jumpHistories: []
});
export const JumpHistoriesProvider = JumpHistoriesContext.Provider;
export const JumpHistoriesConsumer = JumpHistoriesContext.Consumer;
export function useJumpHistories() {
    const { jumpHistories , updateQuery  } = useContext(JumpHistoriesContext);
    return useMemo(()=>{
        return {
            jumpHistories: jumpHistories.filter((jumpHistory)=>{
                const { item  } = jumpHistory;
                const search = jumpHistory.search;
                if (search.type === "APP") {
                    var ref;
                    const pathname = (ref = search.data) === null || ref === void 0 ? void 0 : ref.pathname;
                    return pathname ? jumpToPages.includes(pathname) : false;
                }
                /**
         * remove private items, i.e. `TOPIC`/`POST` that user no longer has permission
         * to view
         */ if (search.type === ItemType.Post || search.type === ItemType.Topic) {
                    if (item) return !(item.archivedAt || item.deletedAt);
                    return false;
                }
                return false;
            }),
            updateQuery
        };
    }, [
        jumpHistories,
        updateQuery
    ]);
}
