import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
function useScrollManager() {
    const { pathname  } = useLocation();
    const prevLocation = useRef(null);
    useEffect(()=>{
        if (prevLocation.current === null) {
            prevLocation.current = pathname;
            return;
        }
        if (prevLocation.current !== pathname) window.scrollTo(0, 0);
        prevLocation.current = pathname;
    }, [
        pathname
    ]);
}
export default useScrollManager;
