import { Text } from "sprout-ui-primitives";
import styled from "styled-components";
const LandingH1 = styled(Text).attrs({
    as: "h1"
})``;
LandingH1.defaultProps = {
    lineHeight: 1,
    textAlign: "center",
    fontSize: [
        5,
        6,
        7
    ],
    fontWeight: "bold"
};
export default LandingH1;
