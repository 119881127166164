import styled, { css } from "styled-components";
import { media, themeGet } from "sprout-ui-theme";
export const focusStyles = css`
  .js-focus-visible & {
    :focus:not(.focus-visible) {
      outline: 0;
    }
    &.focus-visible {
      outline: 0;
      box-shadow: ${themeGet("colors.white.100")} 0 0 0 2px,
        ${themeGet("colors.blue.500")} 0 0 0 4px;
    }
  }
`;
const Card = styled("div")`
  position: relative;
  padding: 12px 0;

  ${media.up("medium")} {
    padding: 12px 16px;
  }

  ${focusStyles};

  ${({ isSelected  })=>isSelected && css`
      background: rgba(25, 118, 210, 0.08);
    `};

  ${({ isActive , isSelected  })=>isActive && (isSelected ? css`
          ${media.up("medium", "pointer")} {
            background: rgba(25, 118, 210, 0.12);
          }
        ` : css`
          ${media.up("medium", "pointer")} {
            background: rgba(25, 118, 210, 0.04);
          }
        `)};

  ${({ isGroupedOver  })=>isGroupedOver && css`
      background: #f0f6fb;
    `}

  ${({ isDragging  })=>isDragging ? css`
          background: ${themeGet("colors.grey.300")};

          &:focus {
            background: ${themeGet("colors.grey.300")};
          }

          box-shadow: 0 1px 4px ${themeGet("colors.black.4")};
        ` : css`
          ::before,
          ::after {
            position: absolute;
            content: " ";
            left: 0;
            right: 0;
            height: 1px;
            background: ${themeGet("colors.black.6")};
          }

          ::before {
            top: 0;
          }

          ::after {
            bottom: -1px;
          }
        `}
`;
export default Card;
