function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { useCallback } from "react";
import { useSetItemsDeletedMutation } from "sprout-graphql";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
import { plural, singular } from "./useSetItemsArchived";
export default function useSetItemsDeleted() {
    const [setItemsDeleted] = useSetItemsDeletedMutation();
    const showSnackbar = useSnackbar();
    return useCallback(({ items , isExplicitlyDeleted , snackbarOverwrite , callback , optimisticCallback  })=>{
        setItemsDeleted({
            variables: {
                input: {
                    itemIds: items.map((item)=>item.id),
                    isDeleted: isExplicitlyDeleted
                }
            },
            optimisticResponse: {
                setItemsDeleted: {
                    items: items.map((item)=>_objectSpread({}, item, {
                            isExplicitlyDeleted: isExplicitlyDeleted,
                            deletedAt: isExplicitlyDeleted ? new Date(Date.now()).toISOString() : null
                        }))
                }
            }
        }).then(({ data  })=>{
            var ref;
            const dataItems = data === null || data === void 0 ? void 0 : (ref = data.setItemsDeleted) === null || ref === void 0 ? void 0 : ref.items;
            if (!dataItems) throw new Error("No items returned");
            const [item] = dataItems;
            const str = (dataItems.length > 1 ? plural : singular)[item.type];
            const state = isExplicitlyDeleted ? "moved to Trash" : "restored";
            showSnackbar({
                message: `${snackbarOverwrite || str} ${state}`
            });
            if (callback) callback(dataItems);
        }).catch(()=>{
            showSnackbar.error();
        });
        if (optimisticCallback) optimisticCallback(items);
    }, [
        setItemsDeleted,
        showSnackbar
    ]);
};
