import { PeopleOutlined, NotificationsNoneOutlined, Timeline, RestoreOutlined, SettingsOutlined, ArchiveOutlined, DeleteOutlined, BookmarkBorder, DraftsOutlined, HomeOutlined, PriorityHighOutlined, AssignmentOutlined, ListAltOutlined, NodeTree, TaskAltOutlined } from "sprout-ui-icons";
const jumpToData = {
    "/home": {
        label: "Home",
        icon: HomeOutlined
    },
    "/following": {
        label: "Following Feed",
        icon: PeopleOutlined
    },
    "/recent": {
        label: "Recents",
        icon: RestoreOutlined
    },
    "/notifications": {
        label: "Notifications",
        icon: NotificationsNoneOutlined
    },
    "/activity": {
        label: "Latest Activity",
        icon: Timeline
    },
    "/bookmarks": {
        label: "Bookmarks",
        icon: BookmarkBorder
    },
    "/my/workspaces": {
        label: "Workspaces",
        icon: PeopleOutlined
    },
    "/my/settings": {
        label: "Settings",
        icon: SettingsOutlined
    },
    "/my/assignments": {
        label: "Assignments",
        icon: AssignmentOutlined
    },
    "/trees": {
        label: "Trees",
        icon: NodeTree
    },
    "/archived": {
        label: "Archived trees",
        icon: ArchiveOutlined
    },
    "/trash": {
        label: "Deleted trees",
        icon: DeleteOutlined
    },
    "/to-dos": {
        label: "To-dos",
        icon: TaskAltOutlined
    },
    "/read-later": {
        label: "Reading List",
        icon: ListAltOutlined
    },
    "/drafts": {
        label: "Drafts",
        icon: DraftsOutlined
    },
    "/priorities": {
        label: "Priorities",
        icon: PriorityHighOutlined
    }
};
export default jumpToData;
