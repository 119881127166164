/**
 * https://github.com/microsoft/fluentui/pull/14313
 * Omit from `lodash` is extremely slow.
 */ export default function omit(obj, targets) {
    const result = {};
    // eslint-disable-next-line no-restricted-syntax
    for(const key in obj)if (targets.indexOf(key) === -1 && Object.prototype.hasOwnProperty.call(obj, key)) result[key] = obj[key];
    // const keys = Object.keys(obj);
    // for (let index = 0; index < keys.length; index++) {
    //   const key = keys[index];
    //   if (!targets.includes(key)) {
    //     result[key] = obj[key];
    //   }
    // }
    return result;
};
