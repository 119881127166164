import { Text } from "sprout-ui-primitives";
import styled from "styled-components";
const LandingH2 = styled(Text).attrs({
    as: "h2"
})``;
LandingH2.defaultProps = {
    lineHeight: 1,
    textAlign: "center",
    fontSize: [
        5,
        null,
        6
    ],
    fontWeight: "bold"
};
export default LandingH2;
