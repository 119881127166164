import { Flex } from "sprout-ui-primitives";
import { media, themeGet } from "sprout-ui-theme";
import styled from "styled-components";
const SelectableWrapper = styled(Flex)`
  align-items: center;

  user-select: none;
  color: ${themeGet("colors.white.100")};

  cursor: pointer;

  &[aria-selected="true"] {
    background: ${themeGet("colors.black.16")};
  }

  ${media.target("pointer")} {
    :hover {
      background: ${themeGet("colors.black.16")};
    }
  }
`;
SelectableWrapper.defaultProps = {
    height: "40px",
    pl: 1,
    pr: 1,
    borderRadius: "8px"
};
export default SelectableWrapper;
