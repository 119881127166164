function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};
    var target = _objectWithoutPropertiesLoose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
function _toPrimitive(input, hint) {
    if (_typeof(input) !== "object" || input === null) return input;
    var prim = input[Symbol.toPrimitive];
    if (prim !== undefined) {
        var res = prim.call(input, hint || "default");
        if (_typeof(res) !== "object") return res;
        throw new TypeError("@@toPrimitive must return a primitive value.");
    }
    return (hint === "string" ? String : Number)(input);
}
function _toPropertyKey(arg) {
    var key = _toPrimitive(arg, "string");
    return _typeof(key) === "symbol" ? key : String(key);
}
var _typeof = function(obj) {
    "@swc/helpers - typeof";
    return obj && typeof Symbol !== "undefined" && obj.constructor === Symbol ? "symbol" : typeof obj;
};
import { atom, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useEffect, useMemo } from "react";
const cacheAtom = atom({});
const registerAtom = atom(null, (get, set, [id, handlers])=>{
    const cache = get(cacheAtom);
    set(cacheAtom, _objectSpread({}, cache, {
        [id]: handlers
    }));
});
const unregisterAtom = atom(null, (get, set, id)=>{
    const cache = get(cacheAtom);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [id]: _deleted  } = cache, rest = _objectWithoutProperties(cache, [
        id
    ].map(_toPropertyKey));
    set(cacheAtom, rest);
});
export function usePopoverRegistryHandler(id, { onOpen , onClose  }) {
    const register = useSetAtom(registerAtom);
    const unregister = useSetAtom(unregisterAtom);
    const handlers = useMemo(()=>({
            onOpen,
            onClose
        }), [
        onClose,
        onOpen
    ]);
    useEffect(()=>{
        register([
            id,
            handlers
        ]);
        return ()=>{
            unregister(id);
        };
    }, [
        handlers,
        id,
        register,
        unregister
    ]);
}
export function usePopoverRegistry() {
    return useAtomCallback(useCallback((get)=>get(cacheAtom), []));
}
