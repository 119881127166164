/* eslint-disable no-param-reassign, @typescript-eslint/unbound-method */ import scrollIntoViewIfNeeded from "scroll-into-view-if-needed";
/**
 * Does nothing; NO OPeration -> NOOP
 */ export function noop() {
/* noop */ }
/**
 * Does nothing; NO OPeration -> NOOP
 */ export function noopFalse() {
    /* Noop */ return false;
}
/**
 * Like list.forEach, except it has a try/catch block so loop continues on
 * error.
 */ export function forEachSafe(list, callback) {
    list.forEach((item)=>{
        try {
            callback(item);
        } catch (e) {
            console.error(e);
        }
    });
}
const scrollImmediate = (actions)=>{
    actions.forEach(({ el , top , left  })=>{
        el.scrollTop = top;
        el.scrollLeft = left;
    });
};
export function scrollIntoView(el) {
    el.style.margin = "-128px 0 -48px";
    el.style.padding = "128px 0 48px";
    scrollIntoViewIfNeeded(el, {
        scrollMode: "if-needed",
        block: "nearest",
        behavior: scrollImmediate || "smooth"
    });
    el.style.removeProperty("margin");
    el.style.removeProperty("padding");
}
export function isActionValidForMultiselect(action, multiselectObjects) {
    var ref;
    // See if it matches the types
    const isActionType = (o)=>{
        var ref1;
        return action.types === null || ((ref = (ref1 = action.types) === null || ref1 === void 0 ? void 0 : ref1.includes(o.__typename)) !== null && ref !== void 0 ? ref : true);
    };
    // Is it suitable for multiselect/active/standalone mode?
    const objects = multiselectObjects.filter(isActionType);
    if (objects.length !== multiselectObjects.length) // Doesn't support all items
    return false;
    // Action must support multiselect _OR_ be standalone
    return typeof action.multiselect === "function" ? action.multiselect(objects) : action.multiselect;
}
export function isActionValidForActiveObject(action, activeObject) {
    var ref;
    // See if it matches the types
    const isActionType = (o)=>{
        var ref2;
        return action.types === null || ((ref = (ref2 = action.types) === null || ref2 === void 0 ? void 0 : ref2.includes(o.__typename)) !== null && ref !== void 0 ? ref : true);
    };
    const object = isActionType(activeObject) ? activeObject : null;
    if (!object) // Incompatible object
    return false;
    return typeof action.single === "function" ? action.single(object) : action.single;
}
export function handledByList(list, command, activeObject, event) {
    const { actions , performSingularAction  } = list.options;
    if ((actions === null || actions === void 0 ? void 0 : actions.includes(command)) && performSingularAction) return performSingularAction(command, activeObject, event);
    return false;
}
export function sortItems(l1, l2) {
    const el1 = l1.item.element;
    const el2 = l2.item.element;
    const comparison = el1.compareDocumentPosition(el2);
    // bitmask; 1 = disconnected, 2 = preceding, 4 = following, 8 = contains, 16 = containedBy, 32 (ignore)
    const e1IsContainedByE2 = comparison & 16;
    const e1ContainsE2 = comparison & 16;
    const e1FollowsE2 = comparison & 4;
    const e1PrecedesE2 = comparison & 2;
    if (e1IsContainedByE2) return -1;
    else if (e1ContainsE2) return 1;
    else if (e1FollowsE2) return -1;
    else if (e1PrecedesE2) return 1;
    else {
        console.error("Cannot determine order of elements");
        console.error({
            el1,
            el2
        });
        return 0;
    }
}
export function li2str(li) {
    return [
        li.list.listId,
        li.item.listItemId
    ].join(",");
}
