//
import Blockquote from "@tiptap/extension-blockquote";
import BulletList from "@tiptap/extension-bullet-list";
import CodeBlock from "@tiptap/extension-code-block";
import Document from "@tiptap/extension-document";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Link from "@tiptap/extension-link";
//
import Bold from "@tiptap/extension-bold";
import Code from "@tiptap/extension-code";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Underline from "@tiptap/extension-underline";
//
import Dropcursor from "@tiptap/extension-dropcursor";
import Gapcursor from "@tiptap/extension-gapcursor";
import History from "@tiptap/extension-history";
import BaseMention from "@tiptap/extension-mention";
import BubbleMenu from "@tiptap/extension-bubble-menu";
import InternalLink from "./extensions/internal-link";
import { Image } from "./extensions/image";
import Placeholder from "./extensions/placeholder";
import HorizontalRule from "./extensions/horizontal-rule";
import KeydownHandler from "./extensions/keydown-handler";
import SlashCommands from "./extensions/slash-command";
import TrailingNode from "./extensions/trailing-node";
//
import renderSuggestion from "./utils/renderSuggestion";
import MentionsMenu from "./components/MentionsMenu";
import SlashCommandsMenu from "./components/SlashCommandsMenu";
/**
 * Higher priority than `KeydownHandler` so that `Enter` does not get swallow.
 */ const Mention = BaseMention.extend({
    priority: 1001
});
export default function createExtensions({ showSnackbar , mode , onEnter , onEscape , onSave , placeholder , onMentionAutocomplete , history  }) {
    const extensions = [];
    extensions.push(TrailingNode, KeydownHandler.configure({
        onEnter,
        onEscape,
        onSave
    }), BubbleMenu, History, Placeholder.configure({
        placeholder
    }), Document, Paragraph, Text, Bold, Italic, Strike, Link.configure({
        openOnClick: false
    }), InternalLink.configure({
        history
    }), Code, HardBreak, Underline, Blockquote);
    if (onMentionAutocomplete) extensions.push(Mention.configure({
        suggestion: {
            items: onMentionAutocomplete,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            render: renderSuggestion(MentionsMenu)
        }
    }));
    if (mode === "SIMPLE" || mode === "FULL") extensions.push(BulletList, OrderedList, CodeBlock, ListItem);
    if (mode === "FULL") extensions.push(Image.configure({
        showSnackbar
    }), HorizontalRule, Heading, Dropcursor.configure({
        width: 3,
        color: "#3147ef"
    }), Gapcursor, SlashCommands.configure({
        suggestion: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            render: renderSuggestion(SlashCommandsMenu)
        }
    }));
    return extensions;
};
