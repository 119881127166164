import styled from "styled-components";
import { Search } from "sprout-ui-icons";
import { themeGet } from "sprout-ui-theme";
const SearchIcon = styled(Search)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  left: 12px;

  color: ${themeGet("colors.black.60")};
`;
export default SearchIcon;
