function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};
    var target = _objectWithoutPropertiesLoose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { formatToText } from "../parser";
import imageCache from "../image-cache";
const unset = ()=>undefined;
const blank = ()=>null;
const getUserName = (user)=>{
    if (user) return [
        user.firstName,
        user.lastName
    ].filter((_)=>_).join(" ");
    return "Someone";
};
const getUserAvatar = (user)=>{
    if (!user) return null;
    const size = 256;
    return {
        avatar: user.avatar ? imageCache(user.avatar, {
            w: size
        }) : `https://www.gravatar.com/avatar/${user.gravatar}?s=${size}&d=mm`,
        username: user.username
    };
};
const getTeamName = (user)=>user && user.firstName || "a team";
const getTopicName = (topic, fallback = "a topic")=>{
    var _title;
    if (topic) return (_title = topic.title) !== null && _title !== void 0 ? _title : null;
    return fallback;
};
const getPostDescription = (post, fallback = "Go to leaf")=>formatToText(post && post.description || fallback);
function mkTemplate(details, skipEmail = false) {
    const { emailFrom , emailSubject , emailTitle , emailTitleContext , emailDescription , emailComment , emailButtonText , pathname , priority , appMessageTemplate , appComment , appContext , appMessageVariables  } = details, rest = _objectWithoutProperties(details, [
        "emailFrom",
        "emailSubject",
        "emailTitle",
        "emailTitleContext",
        "emailDescription",
        "emailComment",
        "emailButtonText",
        "pathname",
        "priority",
        "appMessageTemplate",
        "appComment",
        "appContext",
        "appMessageVariables"
    ]);
    if (Object.keys(rest).length) throw new Error(`Unexpected keys sent to mkTemplate: ${Object.keys(rest).join(", ")}`);
    if (!skipEmail) {
        if (!emailSubject && !emailTitle) throw new Error("Invalid template!");
    }
    if (appMessageVariables && !appMessageTemplate) throw new Error("Variables specified without template");
    const actorFallback = (notification)=>{
        var ref;
        return {
            actor: (ref = notification.actor) === null || ref === void 0 ? void 0 : ref.username
        };
    };
    const fallbackFallback = (notification)=>({
            fallback: (emailTitle || emailSubject)(notification)
        });
    return {
        pathname,
        emailFrom: emailFrom || unset,
        emailSubject: emailSubject || emailTitle,
        emailTitle: emailTitle || emailSubject,
        emailTitleContext: emailTitleContext || blank,
        emailDescription: emailDescription || blank,
        emailComment: emailComment || blank,
        emailButtonText: emailButtonText || (()=>`See More`),
        priority: priority || 0,
        appMessageTemplate: appMessageTemplate || "{fallback}",
        appComment: appComment || (()=>null),
        appContext: appContext || (()=>null),
        appMessageVariables: appMessageVariables || (appMessageTemplate ? actorFallback : fallbackFallback)
    };
}
function mkTemplateSansEmail(options) {
    return mkTemplate(options, true);
}
export { formatToText, getUserName, getUserAvatar, getTeamName, getTopicName, getPostDescription, mkTemplate, mkTemplateSansEmail };
