import { DateFormatter, getLocalTimeZone, parseAbsoluteToLocal, toCalendarDate, today } from "@internationalized/date";
import { useMemo } from "react";
import { useLocale } from "react-aria";
import { differenceInDays } from "../Calendar";
export default function useDeadlineDetails(value) {
    const { locale  } = useLocale();
    return useMemo(()=>{
        if (!value) return null;
        const timeZone = getLocalTimeZone();
        const todaysDate = today(timeZone);
        const dateValue = toCalendarDate(parseAbsoluteToLocal(value));
        // use `CalendarDate` so it doesn't take time into account
        const isToday = todaysDate.compare(dateValue) === 0;
        const isTomorrow = todaysDate.add({
            days: 1
        }).compare(dateValue) === 0;
        const expired = dateValue.compare(todaysDate) < 0;
        const diffDays = differenceInDays(todaysDate.toDate(timeZone), dateValue.toDate(timeZone));
        const within7Days = dateValue.compare(todaysDate) >= 0 && dateValue.compare(todaysDate.add({
            days: 7
        })) < 0;
        const notThisYear = dateValue.year !== todaysDate.year;
        const moreThan7Days = dateValue.compare(todaysDate.add({
            days: 7
        })) > 0;
        let label = new DateFormatter(locale, {
            day: notThisYear ? undefined : "numeric",
            month: "short",
            year: notThisYear ? "numeric" : undefined
        }).format(dateValue.toDate(timeZone));
        if (isToday) label = "Today";
        else if (isTomorrow) label = "Tomorrow";
        else if (expired && diffDays < 60) label = `${diffDays} days ago`;
        else if (within7Days) label = `${Math.abs(diffDays)} days left`;
        return {
            isToday,
            isTomorrow,
            expired,
            label,
            moreThan7Days
        };
    }, [
        locale,
        value
    ]);
};
