import styled from "styled-components";
import { Box } from "sprout-ui-primitives";
const ScrollContainer = styled(Box)`
  /* https://github.com/atlassian/react-beautiful-dnd#scroll-containers */
  overflow-y: auto;

  -webkit-overflow-scrolling: touch;

  /* https://bitbucket.org/atlassian/atlaskit-mk-2/src/29ef840e95c08fb00e5461ee15e506b6671e04eb/packages/editor/editor-core/src/ui/styles.ts?at=master */
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: inherit;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgba(170, 170, 170, 0.6);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(170, 170, 170, 0.6);
  }
`;
export default ScrollContainer;
