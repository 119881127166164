import { useContext, useEffect } from "react";
import { NavigationListContext } from "./NavigationListContext";
export default function useNavigationActiveLock(lock) {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { lockNavigation  } = useContext(NavigationListContext);
    useEffect(()=>lockNavigation(lock), [
        lock,
        lockNavigation
    ]);
};
