import TextareaAutosize from "react-textarea-autosize";
import { themeGet } from "sprout-ui-theme";
import styled from "styled-components";
const Textarea = styled(TextareaAutosize)`
  padding: 9px 8px;
  line-height: 20px;
  resize: none;

  width: 100%;

  outline: 0;
  border: 0;
  border-radius: 30px;

  color: ${themeGet("colors.black.87")};
  ::placeholder {
    color: ${themeGet("colors.black.60")};
  }

  background: ${themeGet("colors.white.100")};
  border: 1px solid ${themeGet("colors.black.12")};

  :disabled {
    border: 1px solid ${themeGet("colors.black.6")};
    cursor: not-allowed;

    color: ${themeGet("colors.black.16")};

    ::placeholder {
      color: ${themeGet("colors.black.16")};
    }
  }
`;
export default Textarea;
