import styled from "styled-components";
const SearchInput = styled("input")`
  appearance: none;
  border: 0;
  outline: 0;
  background: transparent;

  padding-right: 4px;

  /* 12 + 24 + 8 */
  padding-left: 44px;

  flex: 1;
  /* needed */
  min-width: 0;
`;
export default SearchInput;
