import styled from "styled-components";
import { Button } from "../Button";
const Tab = styled(Button).attrs((prop)=>{
    if (prop.selected) return {
        variant: "purple"
    };
    return {
        variant: "flat"
    };
})``;
export default Tab;
