/* eslint-disable @typescript-eslint/unbound-method, no-param-reassign */ import { useRef, useMemo, useCallback, useContext, useEffect } from "react";
import { NavigationListContext } from "./NavigationListContext";
import { NavigationListListContext } from "./useNavigationList";
export default function useNavigationListItem(listItemId, options) {
    const ref1 = useRef(null);
    const { listId , registerItem , listOptions , setActiveItemId  } = useContext(NavigationListListContext);
    const { activeCoord , multiselectCoords , setActiveCoord , setMultiselectCoords , mouseInputModeRef ,  } = useContext(NavigationListContext);
    useEffect(()=>{
        if (!ref1.current) throw new Error("useNavigationListItem's returned ref must be used on a DOM element");
        return registerItem(listItemId, ref1.current, options);
    }, [
        options,
        listId,
        listItemId,
        registerItem
    ]);
    const isActive = activeCoord && activeCoord.listId === listId && activeCoord.listItemId === listItemId || false;
    const isSelectable = !!listOptions.multiselect && true; /* TODO */ 
    const isSelected = multiselectCoords && multiselectCoords.some((selected)=>selected.listId === listId && selected.listItemId === listItemId) || false;
    const toggleMultiselect = useCallback(()=>{
        if (isSelectable) setMultiselectCoords((oldMultiselectCoords)=>{
            var ref;
            const i = (ref = oldMultiselectCoords === null || oldMultiselectCoords === void 0 ? void 0 : oldMultiselectCoords.findIndex((c)=>c.listId === listId && c.listItemId === listItemId)) !== null && ref !== void 0 ? ref : -1;
            if (i >= 0) {
                if (!oldMultiselectCoords) return null;
                // Remove from multiselect
                return [
                    ...oldMultiselectCoords.slice(0, i),
                    ...oldMultiselectCoords.slice(i + 1), 
                ];
            } else return [
                ...oldMultiselectCoords !== null && oldMultiselectCoords !== void 0 ? oldMultiselectCoords : [],
                {
                    listId,
                    listItemId
                }
            ];
        });
    }, [
        isSelectable,
        listId,
        listItemId,
        setMultiselectCoords
    ]);
    const isMultiselectActive = !!multiselectCoords;
    const helpers = useMemo(()=>({
            isActive,
            isSelectable,
            isSelected,
            isMultiselectActive,
            toggleMultiselect,
            setActiveItemId
        }), [
        isActive,
        isSelectable,
        isSelected,
        isMultiselectActive,
        toggleMultiselect,
        setActiveItemId, 
    ]);
    const iAmActive = !!activeCoord && activeCoord.listId === listId && activeCoord.listItemId === listItemId;
    const onMouseEnter = useCallback(()=>{
        if (mouseInputModeRef.current && !iAmActive) setActiveCoord((coord)=>coord && coord.listItemId === listItemId && coord.listId === listId ? coord : {
                listId,
                listItemId
            });
    }, [
        setActiveCoord,
        listId,
        listItemId,
        mouseInputModeRef,
        iAmActive
    ]);
    const onMouseMove = useCallback(()=>{
        // Always set active on mouse move, independent of mouseInputModeRef
        if (!iAmActive) setActiveCoord((coord)=>coord && coord.listItemId === listItemId && coord.listId === listId ? coord : {
                listId,
                listItemId
            });
    }, [
        setActiveCoord,
        listId,
        listItemId,
        iAmActive
    ]);
    const onMouseLeave = useCallback(()=>{
        if (mouseInputModeRef.current && !iAmActive) setActiveCoord((coord)=>!coord || coord.listItemId === listItemId && coord.listId === listId ? null : coord);
    }, [
        setActiveCoord,
        listId,
        listItemId,
        mouseInputModeRef,
        iAmActive
    ]);
    return {
        helpers,
        navProps: {
            ref: ref1,
            onMouseLeave,
            onMouseEnter,
            onMouseMove
        }
    };
};
