function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { useCallback } from "react";
import { useApp_AddUserFlagMutation } from "sprout-graphql";
import { Onboard } from "@sprout/client-lib";
import useCurrentUser from "../useCurrentUser";
export default function useAppAddUserFlag() {
    const currentUser = useCurrentUser();
    const [addUserFlag] = useApp_AddUserFlagMutation();
    return useCallback((flag)=>{
        if (!currentUser) return;
        const { flags  } = currentUser;
        addUserFlag({
            variables: {
                flag
            },
            optimisticResponse: {
                addUserFlag: {
                    user: _objectSpread({}, currentUser, {
                        flags: [
                            ...new Set([
                                ...flags || [],
                                flag
                            ])
                        ].filter((nextFlag)=>{
                            // To purge legacy onboard flags
                            return ![
                                Onboard.ONBOARD_JUMPTO,
                                Onboard.ONBOARD_TODAY,
                                Onboard.ONBOARD_INBOX,
                                Onboard.ONBOARD_TOPICS,
                                Onboard.ONBOARD_POST_DRAFT,
                                Onboard.ONBOARD_POST_DRAFT_POST, 
                            ].includes(nextFlag);
                        }),
                        __typename: "User"
                    }),
                    __typename: "AddUserFlagPayload"
                }
            }
        }).catch((error)=>{
            console.error(error);
        });
    }, [
        addUserFlag,
        currentUser
    ]);
};
