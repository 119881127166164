import { InputRule, mergeAttributes, Node } from "@tiptap/core";
import { TextSelection } from "prosemirror-state";
const HorizontalRule = Node.create({
    name: "horizontalRule",
    group: "block",
    parseHTML () {
        return [
            {
                tag: "hr"
            }
        ];
    },
    renderHTML ({ HTMLAttributes  }) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return [
            "hr",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)
        ];
    },
    addCommands () {
        return {
            setHorizontalRule: ()=>({ tr , editor  })=>{
                    const { paragraph  } = editor.schema.nodes;
                    const node = paragraph.create();
                    const pos = tr.selection.$from.pos + 1;
                    tr.insert(pos, node);
                    tr.replaceSelectionWith(this.type.create()).scrollIntoView();
                    return true;
                }
        };
    },
    addKeyboardShortcuts () {
        return {
            "Mod-_": ()=>this.editor.commands.setHorizontalRule()
        };
    },
    addInputRules () {
        return [
            new InputRule({
                find: /^(?:---|___\s|\*\*\*\s)$/,
                handler: ({ state , match , range  })=>{
                    const { tr  } = state;
                    const start = range.from;
                    const end = range.to;
                    if (match[0]) {
                        const { paragraph  } = this.editor.schema.nodes;
                        const node = paragraph.create();
                        const pos = tr.selection.$from.pos + 1;
                        tr.insert(pos, node);
                        tr.setSelection(TextSelection.create(tr.doc, pos + 1));
                        tr.replaceWith(start - 1, end, this.type.create());
                    }
                }
            }), 
        ];
    }
});
export default HorizontalRule;
