import { memo } from "react";
import shallowEqual from "shallowequal";
import omit from "sprout-lib/dist/omit";
export default function memoDownshiftProps(component) {
    return memo(component, (prevProps, nextProps)=>{
        return shallowEqual(omit(nextProps, [
            "onMouseDown",
            "onClick",
            "onMouseMove"
        ]), omit(prevProps, [
            "onMouseDown",
            "onClick",
            "onMouseMove"
        ]));
    });
};
