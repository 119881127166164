import { Extension } from "@tiptap/core";
import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
const Placeholder = Extension.create({
    name: "placeholder",
    addProseMirrorPlugins () {
        return [
            new Plugin({
                props: {
                    decorations: (state)=>{
                        const { doc  } = state;
                        const decorations = [];
                        const completelyEmpty = doc.textContent === "" && doc.childCount <= 1 && doc.content.size <= 2;
                        doc.descendants((node, pos)=>{
                            if (!completelyEmpty) return;
                            if (pos !== 0 || node.type.name !== "paragraph") return;
                            const decoration = Decoration.node(pos, pos + node.nodeSize, {
                                class: "placeholder",
                                "data-placeholder": this.options.placeholder || "Write something..."
                            });
                            decorations.push(decoration);
                        });
                        return DecorationSet.create(doc, decorations);
                    }
                }
            }), 
        ];
    }
});
export default Placeholder;
