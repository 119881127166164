import { ApolloLink, Observable } from "@apollo/client/core";
function isMutation(operation) {
    return operation.query.definitions.some((definition)=>definition.kind === "OperationDefinition" && definition.operation === "mutation");
}
function toRequestKey(operation) {
    return operation.operationName;
}
export class MutationQueueLink extends ApolloLink {
    log(message, ...rest) {
        if (this.debug) console.log(message, ...rest);
    }
    processOperation(entry) {
        const { operation , forward , observer  } = entry;
        this.processing = true;
        this.log("[PROCESSING] -", toRequestKey(operation));
        forward(operation).subscribe({
            next: (result)=>{
                this.processing = false;
                if (observer.next) observer.next(result);
                this.log("[NEXT] -", toRequestKey(operation));
                // If there are more operations, process them.
                const next = this.queue.shift();
                if (next) this.processOperation(next);
            },
            error: (error)=>{
                this.processing = false;
                if (observer.error) observer.error(error);
                this.log("[ERROR] -", toRequestKey(operation), error);
                const next = this.queue.shift();
                if (next) this.processOperation(next);
            },
            complete: observer.complete ? observer.complete.bind(observer) : undefined
        });
    }
    enqueueOperation(entry) {
        this.log("[ENQUEUE] -", toRequestKey(entry.operation));
        this.queue.push(entry);
    }
    cancelOperation(entry) {
        this.queue = this.queue.filter((e)=>e !== entry);
    }
    request(operation, forward) {
        const context = operation.getContext();
        if (!(isMutation(operation) && context.queue)) return forward(operation);
        return new Observable((observer)=>{
            const entry = {
                operation,
                forward,
                observer
            };
            if (!this.processing) this.processOperation(entry);
            else this.enqueueOperation(entry);
            return ()=>this.cancelOperation(entry);
        });
    }
    constructor({ debug =false  } = {}){
        super();
        this.queue = [];
        this.processing = false;
        this.debug = false;
        this.debug = debug;
    }
}
