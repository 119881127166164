import { useApolloClient } from "@apollo/client";
import update from "immutability-helper";
import { useCallback } from "react";
import { useMove_MoveItemsMutation, MoveItem_ItemFragmentDoc } from "sprout-graphql";
import { isNotNull } from "sprout-lib";
export default function useMoveItems() {
    const client = useApolloClient();
    const [moveItems] = useMove_MoveItemsMutation();
    return useCallback(({ itemIds , itemNodeIds , topicId  })=>{
        return moveItems({
            variables: {
                itemIds,
                topicId
            },
            optimisticResponse: {
                __typename: "Mutation",
                moveItemsByIdIntoTopic: {
                    __typename: "MoveItemsByIdIntoTopicPayload",
                    items: itemNodeIds.map((nodeId)=>{
                        const options = {
                            id: nodeId,
                            fragment: MoveItem_ItemFragmentDoc,
                            fragmentName: "MoveItem_Item"
                        };
                        const item = client.readFragment(options);
                        if (!item) return null;
                        return update(item, {
                            parentTopicId: {
                                $set: topicId
                            }
                        });
                    }).filter(isNotNull)
                }
            }
        });
    }, [
        client,
        moveItems
    ]);
};
