import styled from "styled-components";
import { media, themeGet } from "sprout-ui-theme";
import AppBarBase from "./AppBar_Base";
const AppBarMobile = styled(AppBarBase)`
  body.is-scrolled & {
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 12px ${themeGet("colors.black.5")};
  }

  ${media.up("medium", "pointer")} {
    display: none;
  }
`;
export default AppBarMobile;
