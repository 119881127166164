function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { useCallback } from "react";
import { useSetItemsCompletedMutation } from "sprout-graphql";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
export default function useSetItemsCompleted() {
    const showSnackbar = useSnackbar();
    const [setItemsCompleted] = useSetItemsCompletedMutation();
    return useCallback(({ items , completed  })=>{
        setItemsCompleted({
            variables: {
                itemIds: items.map((item)=>item.id),
                completed
            },
            optimisticResponse: {
                setItemsCompleted: {
                    items: items.map((item)=>_objectSpread({}, item, {
                            completed,
                            completedAt: completed ? new Date(Date.now()).toISOString() : null
                        }))
                }
            }
        }).catch(()=>{
            showSnackbar.error();
        });
    }, [
        setItemsCompleted,
        showSnackbar
    ]);
};
