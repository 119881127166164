import { mergeAttributes, Node, InputRule } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import ImageComponent from "../../components/Image";
import { imagePlaceholderPlugin } from "./imagePlaceholderPlugin";
import imageUploadPlugin from "./imageUploadPlugin";
const inputRegex = /(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;
const Image = Node.create({
    name: "image",
    group: "block",
    marks: "",
    selectable: true,
    draggable: true,
    addAttributes () {
        return {
            src: {
                default: null
            },
            alt: {
                default: null
            },
            title: {
                default: null
            }
        };
    },
    renderHTML ({ HTMLAttributes  }) {
        return [
            "div",
            mergeAttributes({
                class: "react-renderer node-image",
                contenteditable: "false",
                draggable: "true"
            }),
            [
                "figure",
                [
                    "img",
                    mergeAttributes(HTMLAttributes)
                ]
            ], 
        ];
    },
    addNodeView () {
        return ReactNodeViewRenderer(ImageComponent);
    },
    addInputRules () {
        return [
            new InputRule({
                find: inputRegex,
                handler: ({ state , match , range  })=>{
                    const { tr  } = state;
                    const start = range.from;
                    const end = range.to;
                    const [, , alt, src, title] = match;
                    if (match[0]) tr.replaceWith(start - 1, end, this.type.create({
                        src,
                        alt,
                        title
                    }));
                }
            }), 
        ];
    },
    addProseMirrorPlugins () {
        return [
            imagePlaceholderPlugin,
            imageUploadPlugin({
                showSnackbar: this.options.showSnackbar
            }), 
        ];
    }
});
export default Image;
