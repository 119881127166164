import { createGlobalStyle as css } from "styled-components";
import { themeGet, media } from "sprout-ui-theme";
import getScrollbarSize from "dom-helpers/scrollbarSize";
const scrollbarSize = getScrollbarSize() || 0;
export default css`
  body {
    display: flex;
    flex-direction: column;

    background: #fdf6f0;
    overflow-y: scroll;

    &.overlay {
      overflow: hidden;
      padding-right: ${`${scrollbarSize}px`};

      .offset {
        padding-right: ${`${scrollbarSize}px`};
      }

      ${media.down("medium")} {
        .offset-m {
          padding-right: ${`${scrollbarSize}px`};
        }
      }
    }
  }

  #sprout {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  /* NProgress */
  #nprogress {
    position: relative;
    z-index: 99;
    pointer-events: none;
  }

  #nprogress .bar,
  #nprogress::after {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
  }

  #nprogress .bar {
    background: ${themeGet("colors.grey.900")};
  }

  #nprogress:after {
    content: "";
    z-index: -1;
    background: ${themeGet("colors.grey.200")};
  }

  #nprogress .peg {
    box-shadow: 0 0 10px ${themeGet("colors.grey.900")},
      0 0 5px ${themeGet("colors.grey.900")};
  }

  /* Intercom */
  ${media.down("1279px")} {
    [class*="intercom"],
    [id*="intercom"] {
      display: none;
    }
  }

  body.overlay {
    [class*="intercom"],
    [id*="intercom"] {
      display: none;
    }
  }
`;
