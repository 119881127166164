import { useCallback, useImperativeHandle, useState } from "react";
export default function useSuggestionsMenu({ itemsCount , onEnter , ref  }) {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const selectPrevious = useCallback(()=>{
        setSelectedIndex((selectedIndex + itemsCount - 1) % itemsCount);
    }, [
        itemsCount,
        selectedIndex
    ]);
    const selectNext = useCallback(()=>{
        setSelectedIndex((selectedIndex + 1) % itemsCount);
    }, [
        itemsCount,
        selectedIndex
    ]);
    useImperativeHandle(ref, ()=>({
            onKeyDown: ({ event  })=>{
                if (event.key === "ArrowUp") {
                    selectPrevious();
                    return true;
                }
                if (event.key === "ArrowDown") {
                    selectNext();
                    return true;
                }
                if (event.key === "Enter") {
                    onEnter(selectedIndex);
                    return true;
                }
                return false;
            }
        }), [
        onEnter,
        selectNext,
        selectPrevious,
        selectedIndex
    ]);
    return {
        selectedIndex
    };
};
