import styled from "styled-components";
import { NavLink } from "react-router-dom";
const CardLink = styled(NavLink)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
CardLink.defaultProps = {
    tabIndex: -1
};
export default CardLink;
