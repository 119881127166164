/* eslint-disable no-loop-func, @typescript-eslint/no-non-null-assertion, @typescript-eslint/prefer-regexp-exec, prefer-destructuring */ const STYLE_REGEXPS = [
    /(^|\s)(\*)([^*]+?)(\*)/,
    /(^|\s)(_)([^_]+?)(_)/,
    /(^|\s)(`)([^`]+?)(`)/,
    /(^|\s)(~)([^~]+?)(~)/, 
];
const styleFromOpenMarkdown = {
    "*": "BOLD",
    _: "ITALIC",
    "~": "STRIKETHROUGH",
    "`": "CODE"
};
function process(initialOffset, textToProcess, callbacks, regexps) {
    let offset = initialOffset;
    const { open =()=>"" , close =()=>"" , raw =(str)=>str  } = callbacks;
    const processedTextComponents = [];
    const styles = [];
    let remainingText = textToProcess;
    let currentMatch;
    let charactersProcessed = 0;
    do {
        currentMatch = regexps.map((re)=>[
                re,
                remainingText.match(re), 
            ]).filter((arg)=>!!arg[1]).sort(([, aMatches], [, bMatches])=>aMatches.index - bMatches.index)[0];
        if (currentMatch) {
            const [styleRegexp, matches] = currentMatch;
            const [entireMatch, whitespace, openSymbol, rawStyledText, closeSymbol] = matches;
            const remainingRegexps = regexps.filter((re)=>re !== styleRegexp);
            const innerOffset = offset + matches.index + whitespace.length;
            const { text: innerText , styles: innerStyles , charactersProcessed: moreCharactersProcessed ,  } = process(innerOffset, rawStyledText, callbacks, remainingRegexps);
            charactersProcessed += openSymbol.length + closeSymbol.length + moreCharactersProcessed;
            processedTextComponents.push(remainingText.substr(0, matches.index));
            const style = styleFromOpenMarkdown[openSymbol];
            processedTextComponents.push(raw(whitespace) + open(style) + raw(innerText) + close(style));
            styles.push({
                offset: innerOffset,
                length: innerText.length,
                style
            });
            styles.push(...innerStyles);
            // Keep going...
            offset = innerOffset + innerText.length;
            remainingText = remainingText.substr(matches.index + entireMatch.length);
        }
    }while (currentMatch)
    return {
        text: processedTextComponents.join("") + remainingText,
        styles,
        charactersProcessed
    };
}
export default function processOutputMarkdown(offset, text, callbacks = {}) {
    return process(offset, text, callbacks, STYLE_REGEXPS);
};
