import { media, themeGet } from "sprout-ui-theme";
import { css } from "styled-components";
// eslint-disable-next-line import/prefer-default-export
export const anchorStyles = css`
  transition: color 0.15s linear, text-decoration-color 0.15s linear;
  text-decoration: underline;
  text-decoration-thickness: 2px;

  ${media.target("pointer")} {
    :hover {
      color: ${themeGet("colors.blue.500")};
      text-decoration-color: ${themeGet("colors.blue.40")};
    }
  }
`;
