const sameWidth = {
    name: "sameWidth",
    enabled: true,
    phase: "beforeWrite",
    requires: [
        "computeStyles"
    ],
    fn: ({ state  })=>{
        const { styles , rects  } = state;
        const { popper  } = styles;
        const { reference  } = rects;
        popper.width = `${reference.width}px`;
    },
    effect: ({ state  })=>{
        const { elements: { popper , reference  } ,  } = state;
        if (reference instanceof HTMLElement) popper.style.width = `${reference.offsetWidth}px`;
    }
};
export default sameWidth;
