import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { createContainer } from "unstated-next";
const RouteHistories = createContainer(()=>{
    const history = useHistory();
    const { location  } = history;
    const { pathname  } = location;
    const [routeHistories, setRouteHistories] = useState([
        pathname
    ]);
    useEffect(()=>{
        history.listen((nextLocation, action)=>{
            const nextPathname = nextLocation.pathname;
            switch(action){
                case "PUSH":
                    setRouteHistories((v)=>[
                            ...v,
                            nextPathname
                        ]);
                    break;
                case "REPLACE":
                    setRouteHistories((v)=>{
                        return [
                            ...v.slice(0, -1),
                            nextPathname
                        ];
                    });
                    break;
                case "POP":
                    setRouteHistories((v)=>{
                        return v.slice(0, -1);
                    });
                    break;
                default:
                    break;
            }
        });
    }, [
        history
    ]);
    return routeHistories;
});
export const useRouteHistories = RouteHistories.useContainer;
export const RouteHistoriesProvider = RouteHistories.Provider;
