import { useCallback, useMemo, useState } from "react";
export function usePopoverState() {
    const [open, setOpen] = useState(false);
    const onOpenChange = useCallback((nextOpen)=>{
        setOpen(nextOpen);
    }, []);
    const [referenceElement, setReferenceElement] = useState(null);
    const [triggerElement, setTriggerElement] = useState(null);
    const onOpenToggle = useCallback(()=>{
        setOpen((v)=>!v);
    }, []);
    const onOpen = useCallback(()=>{
        setOpen(true);
    }, []);
    const onClose = useCallback(()=>{
        setOpen(false);
    }, []);
    return useMemo(()=>({
            open,
            onOpenChange,
            onOpenToggle,
            referenceElement,
            setReferenceElement,
            triggerElement,
            setTriggerElement,
            onOpen,
            onClose
        }), [
        onClose,
        onOpen,
        onOpenChange,
        onOpenToggle,
        open,
        referenceElement,
        triggerElement, 
    ]);
}
