import { Extension } from "@tiptap/core";
import { getEditorStateHelpers } from "../utils";
const KeydownHandler = Extension.create({
    name: "keydownHandler",
    priority: 1000,
    addKeyboardShortcuts () {
        return {
            "Mod-Enter": ({ editor  })=>{
                if (this.options.onSave) return this.options.onSave(getEditorStateHelpers(editor));
                return false;
            },
            Enter: ({ editor  })=>{
                if (this.options.onEnter) return this.options.onEnter(getEditorStateHelpers(editor));
                return false;
            },
            Escape: ({ editor  })=>{
                if (this.options.onEscape) return this.options.onEscape(getEditorStateHelpers(editor));
                return false;
            }
        };
    }
});
export default KeydownHandler;
