import { uniq } from "lodash";
import { ItemFocusWhen, ItemType } from "sprout-graphql";
import { ArchiveOutlined, ArrowRightCircleOutlined, BookmarkBorder, BookmarkRemoveOutlined, DeleteOutlined, DoneOutlineOutlined, EditOutlined, LocalOfferOutlined, RestoreFromTrashOutlined, UnarchiveOutlined, PersonAddOutlined, Link, Timeline, NodeTree, ScheduleOutlined, TodayOutlined, PriorityHighOutlined } from "sprout-ui-icons";
import { modKey } from "sprout-lib/dist/modKey";
import getItemUrl from "sprout-lib/dist/getItemUrl";
import getHistory from "sprout-lib/dist/history";
import { TEMPLATES as NOTIFICATION_TEMPLATES } from "sprout-lib/dist/notifications";
import { getTemplates } from "sprout-lib/dist/activities";
const inflect = (actionString)=>{
    return actionString[0] + actionString.substring(1).toLowerCase().replace(/_[a-z]/g, (m)=>m[1].toUpperCase());
};
function displayKey(key) {
    switch(key){
        case "mod":
            return modKey;
        case "alt":
            return "⌥";
        case "enter":
            return "Enter";
        case "backspace":
            return "Backspace";
        case "escape":
            return "Escape";
        case "right":
            return "→";
        case "left":
            return "←";
        case "up":
            return "↑";
        case "down":
            return "↓";
        case "shift":
            return "⇧";
        default:
            {
                let str = "";
                if (key === key.toUpperCase() && key !== key.toLowerCase()) str += "⇧";
                str += key.toUpperCase();
                return str;
            }
    }
}
export function displayify(fragment) {
    const split = fragment.includes("+") ? "+" : " ";
    const parts = fragment.split(split);
    /**
   * g s = g + s
   * mod+[ = mode + [
   * S = S
   */ return parts.map(displayKey).join(" + ");
}
const knownActions = new Set();
function makeAction(command, rawKeys, types, options = {}) {
    if (knownActions.has(command)) throw new Error(`Command '${command}' has already been registered`);
    knownActions.add(command);
    const { multiselect =false , single =true , standalone =types === null , label =inflect(command) , icon , handle =null ,  } = options;
    const keys = Array.isArray(rawKeys) ? rawKeys : [
        rawKeys
    ];
    const firstKey = keys[0];
    const keybindingForDisplay = displayify(firstKey);
    return {
        command,
        keys,
        keybindingForDisplay,
        types,
        multiselect,
        single,
        standalone,
        label,
        icon,
        handle
    };
}
function multiselectFn({ type , condition , action  }) {
    let typeToMatch = [
        ItemType.Post,
        ItemType.ChecklistItem
    ];
    if (type === ItemType.Post) typeToMatch = [
        ItemType.Post
    ];
    if (type === ItemType.ChecklistItem) typeToMatch = [
        ItemType.ChecklistItem
    ];
    return (selection)=>{
        return selection[action]((i)=>{
            const { deletedAt , archivedAt  } = i;
            return deletedAt === null && archivedAt === null && (condition ? condition(i) : false) && typeToMatch.includes(i.type);
        });
    };
}
function postSetDoneCondition(item) {
    const { userPreferences , type  } = item;
    const { focusWhen  } = userPreferences || {};
    return type === ItemType.Post && (focusWhen === ItemFocusWhen.Later || focusWhen === ItemFocusWhen.Now);
}
function postSetAsideCondition(item) {
    const { userPreferences , type  } = item;
    const { focusWhen  } = userPreferences || {};
    return type === ItemType.Post && focusWhen !== ItemFocusWhen.Later;
}
export const ACTIONS = [
    makeAction("ESCAPE", "escape", null, {
        label: "Exit"
    }),
    makeAction("EXPAND", [
        "right"
    ], [
        "Item"
    ], {
        single: (item)=>item.type === ItemType.Topic
    }),
    makeAction("COLLAPSE", [
        "left"
    ], [
        "Item"
    ], {
        single: (item)=>item.type === ItemType.Topic
    }),
    makeAction("OPEN", "enter", [
        "Notification",
        "TopicActivity",
        "Item",
        "Label",
        "User",
        "TopicMember",
        "TeamMember",
        "__Placeholder", 
    ], {
        standalone: true,
        single: (entity)=>{
            if (entity.__typename === "Item" && entity.type === ItemType.Divider) // Specifically ban dividers from being opened.
            return false;
            return true;
        },
        handle: (_multiselectObjects, targetObject)=>{
            if (!targetObject) return false;
            switch(targetObject.__typename){
                case "Item":
                    {
                        const history = getHistory();
                        const itemUrl = getItemUrl(targetObject, targetObject.owner);
                        if (itemUrl === null || itemUrl === "/") {
                            console.warn("Attempted to open item, but itemUrl came back invalid - perhaps you forgot to request the owner/title for a topic?", {
                                item: targetObject,
                                itemUrl
                            });
                            return false;
                        } else {
                            history.push(itemUrl);
                            return true;
                        }
                    }
                case "TeamMember":
                    if (targetObject.userByTeamId) {
                        const history = getHistory();
                        history.push(`/${targetObject.userByTeamId.username}`);
                        return true;
                    }
                    return false;
                case "TopicMember":
                    if (targetObject.user) {
                        const history = getHistory();
                        history.push(`/${targetObject.user.username}`);
                        return true;
                    }
                    return false;
                case "User":
                    {
                        const history = getHistory();
                        history.push(`/${targetObject.username}`);
                        return true;
                    }
                case "Notification":
                    {
                        const template = NOTIFICATION_TEMPLATES[targetObject.notificationType];
                        if (template) {
                            const pathname = template.pathname(targetObject);
                            if (pathname) {
                                const history = getHistory();
                                history.push(pathname);
                                return true;
                            }
                        }
                        return false;
                    }
                case "TopicActivity":
                    {
                        const activity = targetObject;
                        const ACTIVITY_TEMPLATES = getTemplates();
                        const templateFn = ACTIVITY_TEMPLATES[activity.notificationType];
                        if (templateFn) {
                            const template = templateFn(activity, {
                            });
                            if (template.url) {
                                const history = getHistory();
                                history.push(template.url);
                                return true;
                            }
                        }
                        return false;
                    }
                default:
                    return false;
            }
        }
    }),
    makeAction("OPEN_EXTERNAL", "v", [
        "Item"
    ], {
        handle (_multiselectObjects, targetObject) {
            if (targetObject && "url" in targetObject && targetObject.url) {
                window.open(targetObject.url);
                return true;
            }
            return false;
        }
    }),
    makeAction("SET_PRIORITY", "p", [
        "Item"
    ], {
        label: "Priority",
        icon: PriorityHighOutlined,
        single: ({ type , currentUserHasStarred  })=>[
                ItemType.ChecklistItem,
                ItemType.Post
            ].includes(type) && !currentUserHasStarred,
        multiselect: multiselectFn({
            action: "some",
            type: "BOTH",
            condition: (i)=>!i.currentUserHasStarred
        })
    }),
    makeAction("REMOVE_PRIORITY", "p", [
        "Item"
    ], {
        label: "Remove Priority",
        icon: PriorityHighOutlined,
        single: ({ type , currentUserHasStarred  })=>[
                ItemType.ChecklistItem,
                ItemType.Post
            ].includes(type) && !!currentUserHasStarred,
        multiselect: multiselectFn({
            action: "every",
            type: "BOTH",
            condition: (i)=>!!i.currentUserHasStarred
        })
    }),
    makeAction("PAGE_OPEN_EXTERNAL", "V", null),
    makeAction("FOCUS_DIVIDER", "enter", [
        "Item"
    ], {
        single: (item)=>item.type === "DIVIDER"
    }),
    makeAction("FOCUS_DESCRIPTION", "e", [
        "Item"
    ], {
        standalone: true
    }),
    makeAction("PAGE_FOCUS_DESCRIPTION", "E", null),
    makeAction("FOCUS_NOTE", "n", [
        "Item"
    ], {
        standalone: true
    }),
    makeAction("COMMENT", "r", [
        "Item"
    ], {
        standalone: true
    }),
    makeAction("ARCHIVE", "c", [
        "Item"
    ], {
        multiselect: (selection)=>selection.every((i)=>i.archivedAt === null && i.type === ItemType.Post),
        icon: ArchiveOutlined
    }),
    makeAction("UNARCHIVE", "c", [
        "Item"
    ], {
        multiselect: (selection)=>selection.every((i)=>i.archivedAt !== null && i.type === ItemType.Post),
        icon: UnarchiveOutlined
    }),
    makeAction("PAGE_TOGGLE_ARCHIVE", "C", null),
    makeAction("DELETE", "d", [
        "Item"
    ], {
        multiselect: (selection)=>selection.every((i)=>i.deletedAt === null && i.type === ItemType.Post),
        icon: DeleteOutlined
    }),
    // TODO: Check if we can dedupe/clean this up
    makeAction("PAGE_DELETE", "D", null, {
        label: "Delete",
        icon: DeleteOutlined
    }),
    makeAction("UNDELETE", "D", [
        "Item"
    ], {
        multiselect: (selection)=>selection.every((i)=>i.deletedAt !== null && i.type === ItemType.Post),
        icon: RestoreFromTrashOutlined
    }),
    makeAction("PAGE_PERMANENT_DELETE", "$", null, {
        icon: DeleteOutlined,
        label: "Delete forever"
    }),
    makeAction("PERMANENT_DELETE", "$", [
        "Item"
    ], {
        multiselect: (selection)=>selection.every((i)=>!!i.deletedAt && i.type === ItemType.Post),
        icon: DeleteOutlined,
        label: "Delete forever"
    }),
    makeAction("PAGE_TOGGLE_DELETE", "D", null),
    makeAction("ADD_POST", "a", [
        "Item"
    ], {
        standalone: true
    }),
    makeAction("ADD_DRAFT_POST", "A", null, {
        standalone: true
    }),
    makeAction("ADD_BLANK_POST", "A", null, {
        standalone: true
    }),
    makeAction("ADD_DIVIDER", "-", [
        "Item"
    ], {
        standalone: true
    }),
    makeAction("ADD_SECTION", "b", [
        "Item"
    ], {
        standalone: true
    }),
    makeAction("ADD_TODOLIST", "b", [
        "Item"
    ], {
        standalone: true
    }),
    makeAction("ADD_TODO", "a", [
        "Item"
    ], {
        standalone: true
    }),
    makeAction("EDIT_POST_DESCRIPTION", "e", [
        "Item"
    ], {
        label: "Edit Leaf",
        icon: EditOutlined,
        multiselect: (selection)=>/**
       * To support a singular action in the `MultiselectMenu`, we need to use
       * the `multiselect` option. But we can use the following conditions to
       * simulate a singular action.
       */ selection.length === 1 && selection.every((i)=>i.type === ItemType.Post)
    }),
    makeAction("EDIT_TODOLIST", "e", [
        "Item"
    ], {
        single: (item)=>item.type === ItemType.Checklist
    }),
    makeAction("EDIT_TODO", "e", [
        "Item"
    ], {
        icon: EditOutlined,
        single: (item)=>item.type === ItemType.ChecklistItem
    }),
    makeAction("EDIT_TOPIC", "e", [
        "Item"
    ], {
        single: (item)=>item.type === ItemType.Topic,
        label: "Edit",
        icon: EditOutlined
    }),
    makeAction("PAGE_EDIT_LABEL", "E", null, {
        label: "Rename",
        icon: EditOutlined
    }),
    makeAction("PAGE_COPY_TO_CLIPBOARD", "Z", null, {
        icon: Link
    }),
    makeAction("COPY_TO_CLIPBOARD", "z", [
        "Item"
    ], {
        icon: Link
    }),
    makeAction("PAGE_VIEW_ITEM_ACTIVITY", "+", null, {
        icon: Timeline
    }),
    makeAction("VIEW_ITEM_ACTIVITY", "=", [
        "Item"
    ], {
        icon: Timeline
    }),
    makeAction("SEARCH", "/", null),
    makeAction("SEARCH_MY_TOPICS", "; m", null, {
        label: "My Trees"
    }),
    makeAction("SEARCH_SPROUT", "; s", null, {
        label: "All of Sprout"
    }),
    makeAction("SEARCH_PEOPLE", "; p", null, {
        label: "Search People"
    }),
    makeAction("SEARCH_TOPIC", "; t", null),
    makeAction("VIEW_ACTIVITY", "o a", null),
    makeAction("VIEW_TRASH", "o t", null),
    makeAction("VIEW_ARCHIVE", "o x", null),
    makeAction("VIEW_MEMBERS", "o m", null),
    makeAction("VIEW_SETTINGS", "o s", null),
    makeAction("VIEW_LABELS", "o l", null),
    makeAction("GO_HOME", [
        "3",
        "g h"
    ], null),
    makeAction("GO_TREES", [
        "3",
        "g h"
    ], null),
    makeAction("GO_WORKSPACE", "g w", null),
    makeAction("GO_MY_TASKS", [
        "g k"
    ], null),
    makeAction("GO_TOPICS", [
        "g m"
    ], null),
    makeAction("GO_READ_LATER", [
        "g r"
    ], null),
    makeAction("GO_DRAFTS", [
        "g d"
    ], null),
    makeAction("GO_TOMORROW", [
        "g y"
    ], null),
    makeAction("GO_SET_ASIDE", [
        "g b"
    ], null),
    makeAction("GO_FOLLOWING", [
        "g f"
    ], null),
    makeAction("GO_AGENDA", [
        "1",
        "g a"
    ], null),
    makeAction("GO_ACTIVITY", [
        "g l"
    ], null),
    makeAction("GO_NOTIFICATIONS", [
        "g n"
    ], null),
    makeAction("GO_BOOKMARKS", "g b", null),
    makeAction("GO_PROFILE", "g o", null),
    makeAction("GO_RECENT", "g e", null),
    makeAction("GO_SETTINGS", "g g", null),
    makeAction("GO_PRIORITIES", "2", null),
    makeAction("GO_SHORTCUTS", "g z", null),
    makeAction("GO_MY_ASSIGNMENTS", "g s", null),
    makeAction("GO_MY_ASSIGNINGS", "g i", null),
    makeAction("GO_TRASH", "g t", null),
    makeAction("GO_ARCHIVED", "g x", null),
    makeAction("GO_PIN_1", "4", null),
    makeAction("GO_PIN_2", "5", null),
    makeAction("GO_PIN_3", "6", null),
    makeAction("CREATE_MY_TASKS_POST", "i", null),
    makeAction("CREATE_DEFAULT_TOPIC_POST", "q", null),
    makeAction("OPEN_JUMP_TO", [
        "h",
        "mod+j",
        "mod+h"
    ], null),
    makeAction("PAGE_EDIT_TOPIC", "E", null),
    makeAction("CONVERT_TODO_TO_POST", "m", [
        "Item"
    ], {
        label: "Move to Tree",
        icon: ArrowRightCircleOutlined,
        standalone: true,
        single: (item)=>item.type === ItemType.ChecklistItem
    }),
    makeAction("MOVE", "m", [
        "Item"
    ], {
        label: "Move",
        icon: ArrowRightCircleOutlined,
        multiselect: (selection)=>selection.every((i)=>i.type === ItemType.Post)
    }),
    makeAction("PAGE_MOVE", "M", null, {
        label: "Move",
        icon: ArrowRightCircleOutlined
    }),
    makeAction("PAGE_MOVE_DRAFT", "mod+o", null),
    makeAction("LABEL", "l", [
        "Item"
    ], {
        label: "Label",
        icon: LocalOfferOutlined,
        multiselect: (selection)=>selection.every((i)=>i.type === ItemType.Post),
        // `Post` label on `TopicPage` is done via `useActionRegistryGlobalHandler`
        // but on `PostPage` it is done via `performSigularAction`
        single: (item)=>item.type === ItemType.ChecklistItem
    }),
    makeAction("PAGE_LABEL", "L", null, {
        label: "Label",
        icon: LocalOfferOutlined
    }),
    makeAction("ASSIGN", "y", [
        "Item"
    ], {
        label: "Assign",
        icon: PersonAddOutlined,
        // `Post` assign on `TopicPage` is done via `useActionRegistryGlobalHandler`
        // but on `PostPage` it is done via `performSigularAction`
        multiselect: (selection)=>selection.every((i)=>i.type === ItemType.Post),
        single: (item)=>item.type === ItemType.ChecklistItem
    }),
    makeAction("PAGE_ASSIGN", "Y", null, {
        label: "Assign",
        icon: PersonAddOutlined
    }),
    makeAction("SELF_ASSIGN", ".", [
        "Item"
    ], {
        single: (item)=>[
                ItemType.Post,
                ItemType.ChecklistItem
            ].includes(item.type)
    }),
    makeAction("PAGE_SELF_ASSIGN", ">", null),
    makeAction("WHEN", "w", [
        "Item"
    ], {
        label: "When",
        icon: ScheduleOutlined,
        // `Post` schedule on `TopicPage` is done via `useActionRegistryGlobalHandler`
        // but on `PostPage` it is done via `performSigularAction`
        multiselect: (selection)=>selection.every((i)=>i.type === ItemType.Post),
        single: (item)=>[
                ItemType.Post,
                ItemType.ChecklistItem
            ].includes(item.type)
    }),
    makeAction("WHEN_CLEAR", "9", [
        "Item"
    ], {
        single: (item)=>[
                ItemType.Post,
                ItemType.ChecklistItem
            ].includes(item.type)
    }),
    makeAction("DEADLINE", "8", [
        "Item"
    ], {
        label: "Deadline",
        icon: TodayOutlined,
        // `Post` deadline on `TopicPage` is done via `useActionRegistryGlobalHandler`
        // but on `PostPage` it is done via `performSigularAction`
        multiselect: (selection)=>selection.every((i)=>i.type === ItemType.Post),
        single: (item)=>[
                ItemType.Post,
                ItemType.ChecklistItem
            ].includes(item.type)
    }),
    makeAction("PAGE_DEADLINE", "shift+8", null, {
        label: "Deadline",
        icon: TodayOutlined
    }),
    makeAction("PAGE_WHEN", "W", null, {
        label: "When",
        icon: ScheduleOutlined
    }),
    makeAction("PAGE_WHEN_CLEAR", "(", null, {}),
    makeAction("WHEN_TODAY", "s", [
        "Item"
    ], {
        single: (item)=>[
                ItemType.Post,
                ItemType.ChecklistItem
            ].includes(item.type)
    }),
    makeAction("PAGE_WHEN_TODAY", "S", null),
    makeAction("SET_SET_ASIDE", "u", [
        "Item"
    ], {
        label: "Bookmark",
        icon: BookmarkBorder,
        multiselect: multiselectFn({
            action: "some",
            type: ItemType.Post,
            condition: postSetAsideCondition
        }),
        single: postSetAsideCondition
    }),
    makeAction("PAGE_SET_SET_ASIDE", "U", null, {
        label: "Bookmark",
        icon: BookmarkBorder
    }),
    makeAction("SET_DONE", "u", [
        "Item"
    ], {
        label: "Unbookmark",
        icon: BookmarkRemoveOutlined,
        multiselect: multiselectFn({
            action: "every",
            type: ItemType.Post,
            condition: postSetDoneCondition
        }),
        single: postSetDoneCondition
    }),
    makeAction("PAGE_SET_DONE", "U", null, {
        label: "Unbookmark",
        icon: BookmarkRemoveOutlined
    }),
    makeAction("SET_INCOMPLETE", "f", [
        "Item"
    ], {
        label: "Mark Uncompleted",
        icon: DoneOutlineOutlined,
        multiselect: multiselectFn({
            action: "every",
            type: ItemType.Post,
            condition: (i)=>!!i.completed
        }),
        single: ({ type , completed  })=>[
                ItemType.Post,
                ItemType.ChecklistItem
            ].includes(type) && !!completed
    }),
    makeAction("SET_COMPLETED", "f", [
        "Item"
    ], {
        label: "Mark Done",
        icon: DoneOutlineOutlined,
        multiselect: multiselectFn({
            action: "some",
            type: ItemType.Post,
            condition: (i)=>!i.completed
        }),
        single: ({ type , completed  })=>[
                ItemType.Post,
                ItemType.ChecklistItem
            ].includes(type) && !completed
    }),
    makeAction("PAGE_TOGGLE_COMPLETED", "F", null),
    makeAction("PAGE_TOGGLE_PRIORITY", "P", null),
    makeAction("PAGE_SET_DRAFT", "mod+enter", null),
    makeAction("SHORTCUTS", "?", null),
    makeAction("NEW_TOPIC", "t", null, {
        label: "Tree",
        icon: NodeTree
    }),
    makeAction("GO_BACK", [
        "backspace",
        "mod+["
    ], null),
    makeAction("GO_FORWARD", "mod+]", null),
    makeAction("GO_UP", "0", null),
    makeAction("PREVIOUS", [
        "left"
    ], null),
    makeAction("NEXT", [
        "right"
    ], null),
    makeAction("PAGE_ENTER", "enter", null), 
];
export function multiselectActionsFor(selection) {
    const selectionTypes = uniq(selection.map((o)=>o.__typename));
    const appropriateActions = ACTIONS.filter((action)=>{
        const { types  } = action;
        if (action.multiselect === false) return false;
        else if (types != null && selectionTypes.some((t)=>!types.includes(t))) return false;
        else if (action.multiselect === true) return true;
        else if (typeof action.multiselect === "function") // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return action.multiselect(selection);
        else {
            console.error("Control should not have reached end of appropriateActions function");
            return false;
        }
    });
    return appropriateActions;
}
