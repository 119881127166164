import { Box } from "sprout-ui-primitives";
import styled from "styled-components";
const MenuGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 8px;
`;
MenuGrid.defaultProps = {
    mt: 3,
    ml: 3,
    mr: 3,
    mb: 3
};
export default MenuGrid;
