import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { searchPageRoutes } from "@sprout/client-routes";
import { useSearchContext } from "./SearchContext";
import { SearchScope } from "./types";
function useSearchQueryVariables() {
    const { searchScope  } = useSearchContext();
    const match = useRouteMatch({
        exact: true,
        path: searchPageRoutes
    });
    const topicId = match === null || match === void 0 ? void 0 : match.params.topicId;
    const view = match === null || match === void 0 ? void 0 : match.params.view;
    const operators = useMemo(()=>{
        // for `SearchPageViewsTopic`
        const _operators = [
            "kind:TOPIC",
            "kind:POST"
        ];
        // for non `SearchPageViewsTopic`
        if (!topicId) {
            if (searchScope === SearchScope["my-trees"]) return [
                ..._operators,
                "role: MEMBER"
            ];
            if (searchScope === SearchScope.people) return [];
            if (searchScope === SearchScope.sprout) return [
                "kind:TOPIC"
            ];
        }
        if (view === "daily" || view === "read-later") return [
            "kind:POST",
            `in:${view.toUpperCase()}`
        ];
        // for `SearchPageViewsSpecialTopic`
        return _operators;
    }, [
        searchScope,
        topicId,
        view
    ]);
    return useMemo(()=>{
        return {
            operators,
            topicId: topicId ? Number(topicId) : undefined,
            includeUsers: operators.length === 0,
            includeItems: operators.length !== 0
        };
    }, [
        operators,
        topicId
    ]);
}
export default useSearchQueryVariables;
