import imageCache from "sprout-lib/dist/image-cache";
import { findImagePlaceholder, imagePlaceholderPlugin } from "./imagePlaceholderPlugin";
import uploadImage from "./uploadImage";
let uploadId = 0;
export default function insertImage({ showSnackbar , files , view , pos , event  }) {
    event.preventDefault();
    const { schema  } = view.state;
    for(let index = 0; index < files.length; index++){
        const id = `upload-${uploadId++}`;
        const file = files[index];
        const transaction = view.state.tr.setMeta(imagePlaceholderPlugin, {
            add: {
                id,
                file,
                pos
            }
        });
        view.dispatch(transaction);
        const result = findImagePlaceholder(view.state, id);
        uploadImage(file).then((src)=>{
            const newImg = new Image();
            newImg.src = imageCache(src, {
                auto: "format"
            });
            return new Promise((resolve, reject)=>{
                newImg.onload = ()=>{
                    if (result === null) return;
                    const [from, to] = result;
                    const node = schema.nodes.image.create({
                        src
                    });
                    view.dispatch(view.state.tr.replaceWith(from, to || from, node).setMeta(imagePlaceholderPlugin, {
                        remove: {
                            id
                        }
                    }));
                    resolve(undefined);
                };
                newImg.onerror = (error)=>{
                    reject(error);
                };
            });
        }).catch((error)=>{
            const removeImagePlaceholderTransaction = view.state.tr.setMeta(imagePlaceholderPlugin, {
                remove: {
                    id
                }
            });
            view.dispatch(removeImagePlaceholderTransaction);
            console.error(error);
            if (error instanceof Error) showSnackbar({
                message: error.message
            });
            else showSnackbar.error();
        });
    }
};
