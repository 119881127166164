import { useMemo } from "react";
import { useBus } from "../Bus";
function useSnackbar() {
    const eventBus = useBus();
    return useMemo(()=>{
        const fn = ({ message , actionText , onAction  })=>{
            eventBus.emit("snackbar", {
                message,
                actionText,
                onAction
            });
        };
        fn.error = ()=>{
            eventBus.emit("snackbar", {
                message: "Something unexpected happened. Please try again later"
            });
        };
        return fn;
    }, [
        eventBus
    ]);
}
export default useSnackbar;
