import slugify from "../slugify";
import getItemUrl from "../getItemUrl";
import { getUserName, getTeamName, getTopicName, getPostDescription, mkTemplate, formatToText } from "./helpers";
const getRootPostParentTopicName = (item)=>{
    var ref18;
    const { parentTopic , rootPost  } = item;
    var ref16, ref17;
    // `Item` should be a `Todo` or `TodoList`
    if (rootPost) return (ref16 = (ref18 = rootPost.parentTopic) === null || ref18 === void 0 ? void 0 : ref18.title) !== null && ref16 !== void 0 ? ref16 : null;
    else // `Item` should be a `Post`
    return (ref17 = parentTopic === null || parentTopic === void 0 ? void 0 : parentTopic.title) !== null && ref17 !== void 0 ? ref17 : null;
};
const getCommentUrl = (comment)=>{
    if (!comment) return "/";
    const { post , id , parentCommentId  } = comment;
    if (post) return `${getItemUrl(post)}#comment-${id}${parentCommentId ? `-${parentCommentId}` : ""}`;
    return "/";
};
const getEmailFromTopicTitle = (item)=>{
    const { rootPost , parentTopic  } = item;
    // `Item` should be a `Todo` or `TodoList`
    if (rootPost && rootPost.parentTopic) return `Sprout (${rootPost.parentTopic.title})`;
    // `Item` should be a `Post`
    if (parentTopic) return `Sprout (${parentTopic.title})`;
    return "Sprout";
};
const getAppPostDescription = (post)=>{
    return (post === null || post === void 0 ? void 0 : post.description) || (post === null || post === void 0 ? void 0 : post.linkTitle) || "No description";
};
var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15;
export const TEMPLATES = {
    FOLLOWED_YOU: mkTemplate({
        priority: 100,
        pathname: ({ actor  })=>{
            return `/${(ref = actor === null || actor === void 0 ? void 0 : actor.username) !== null && ref !== void 0 ? ref : ""}`;
        },
        appMessageTemplate: "Followed you",
        appContext: ({ actor  })=>{
            return (ref1 = actor === null || actor === void 0 ? void 0 : actor.username) !== null && ref1 !== void 0 ? ref1 : null;
        },
        emailSubject: (notification)=>`${getUserName(notification.actor)} followed you on Sprout`,
        emailTitle: (notification)=>`${getUserName(notification.actor)} followed you`,
        emailButtonText: ()=>`View profile`
    }),
    FOLLOWED_YOUR_TOPIC: mkTemplate({
        priority: 50,
        pathname: ({ topic  })=>topic && topic.user ? `/${topic.user.username}/${topic.id}-${slugify(topic.title)}` : "/",
        appMessageTemplate: "Followed your tree: {topic}",
        appMessageVariables: ({ topic  })=>{
            return {
                topic: topic === null || topic === void 0 ? void 0 : topic.title
            };
        },
        appContext: ({ actor  })=>{
            return (ref2 = actor === null || actor === void 0 ? void 0 : actor.username) !== null && ref2 !== void 0 ? ref2 : null;
        },
        emailSubject: (notification)=>`${getUserName(notification.actor)} followed ${getTopicName(notification.topic)} on Sprout`,
        emailTitle: (notification)=>`${getUserName(notification.actor)} followed `,
        emailTitleContext: (notification)=>(ref3 = getTopicName(notification.topic)) !== null && ref3 !== void 0 ? ref3 : null,
        emailButtonText: ()=>`View tree`
    }),
    MENTIONED_YOU_IN_COMMENT: mkTemplate({
        pathname: ({ comment  })=>getCommentUrl(comment),
        appMessageTemplate: "Mentioned you in: {description}",
        appMessageVariables: ({ comment  })=>{
            var ref19;
            return {
                description: (comment === null || comment === void 0 ? void 0 : (ref19 = comment.post) === null || ref19 === void 0 ? void 0 : ref19.description) || "No description"
            };
        },
        appContext: ({ actor , comment  })=>{
            return [
                actor === null || actor === void 0 ? void 0 : actor.username,
                (comment === null || comment === void 0 ? void 0 : comment.post) ? getRootPostParentTopicName(comment.post) : null, 
            ].filter(Boolean).join(" • ");
        },
        appComment: ({ data , comment  })=>{
            return (comment === null || comment === void 0 ? void 0 : comment.text) ? comment.text : data.text ? String(data.text) : null;
        },
        emailFrom: (notification)=>`Sprout (${getTopicName(notification.topic)})`,
        emailSubject: (notification)=>`${getUserName(notification.actor)} mentioned you on Sprout`,
        emailTitle: (notification)=>`${getUserName(notification.actor)} mentioned you`,
        emailDescription: (notification)=>{
            var ref20;
            return getPostDescription((ref4 = (ref20 = notification.comment) === null || ref20 === void 0 ? void 0 : ref20.post) !== null && ref4 !== void 0 ? ref4 : null);
        },
        emailComment: (notification)=>`"${formatToText(notification.data.text ? String(notification.data.text) : "")}"`,
        emailButtonText: ()=>`Reply`
    }),
    MENTIONED_YOU_IN_ITEM: mkTemplate({
        // TODO: change `ourPost` to `item` ? since it can be a `TodoList` or `Todo` now
        pathname: ({ ourPost  })=>ourPost ? getItemUrl(ourPost) : "/",
        appMessageTemplate: "Mentioned you in: {description}",
        appMessageVariables: ({ ourPost  })=>({
                description: getAppPostDescription(ourPost)
            }),
        appContext: ({ actor , ourPost  })=>{
            return [
                actor === null || actor === void 0 ? void 0 : actor.username,
                ourPost ? getRootPostParentTopicName(ourPost) : null, 
            ].filter(Boolean).join(" • ");
        },
        emailFrom: ({ ourPost  })=>ourPost ? getEmailFromTopicTitle(ourPost) : undefined,
        emailSubject: (notification)=>`${getUserName(notification.actor)} mentioned ${notification.userId === notification.viewerId ? "you" : getUserName(notification.user)} on Sprout`,
        emailTitle: (notification)=>`${getUserName(notification.actor)} mentioned ${notification.userId === notification.viewerId ? "you" : getUserName(notification.user)}`,
        emailDescription: (notification)=>getPostDescription(notification.data),
        emailButtonText: ()=>`Reply`
    }),
    COMMENTED_ON_YOUR_LINK: mkTemplate({
        pathname: ({ ourPost , comment  })=>{
            if (comment) return getCommentUrl(comment);
            return ourPost ? getItemUrl(ourPost) : "/";
        },
        appMessageTemplate: "Re: {description}",
        appMessageVariables: ({ ourPost  })=>({
                description: getAppPostDescription(ourPost)
            }),
        appContext: ({ actor , ourPost  })=>{
            return [
                actor === null || actor === void 0 ? void 0 : actor.username,
                ourPost ? getRootPostParentTopicName(ourPost) : null, 
            ].filter(Boolean).join(" • ");
        },
        appComment: ({ data , comment  })=>{
            return (comment === null || comment === void 0 ? void 0 : comment.text) ? comment.text : data.comment ? String(data.comment) : null;
        },
        emailFrom: ({ ourPost  })=>ourPost ? getEmailFromTopicTitle(ourPost) : undefined,
        emailSubject: (notification)=>`${getUserName(notification.actor)} commented on Sprout`,
        emailTitle: (notification)=>`${getUserName(notification.actor)} commented`,
        emailDescription: (notification)=>getPostDescription(notification.ourPost),
        emailComment: (notification)=>`"${formatToText(notification.data.comment ? String(notification.data.comment) : "")}"`,
        emailButtonText: ()=>`Reply`
    }),
    YOU_WERE_INVITED_TO_TEAM: mkTemplate({
        // TODO: click path to join?
        pathname: ({ team  })=>{
            return `/${(ref5 = team === null || team === void 0 ? void 0 : team.username) !== null && ref5 !== void 0 ? ref5 : ""}`;
        },
        appMessageTemplate: "Invited you to join a workspace: {team}",
        appMessageVariables: ({ team  })=>{
            return {
                team: (team === null || team === void 0 ? void 0 : team.firstName) || (team === null || team === void 0 ? void 0 : team.username)
            };
        },
        appContext: ({ actor  })=>{
            return (ref6 = actor === null || actor === void 0 ? void 0 : actor.username) !== null && ref6 !== void 0 ? ref6 : null;
        },
        emailSubject: (notification)=>`${getUserName(notification.actor)} invited you to join ${getTeamName(notification.team)} on Sprout`,
        emailTitle: (notification)=>`${getUserName(notification.actor)} invited you to join `,
        emailTitleContext: (notification)=>getTeamName(notification.team),
        emailButtonText: ()=>`View invite`
    }),
    YOU_WERE_INVITED_TO_TOPIC: mkTemplate({
        // TODO: click path to join?
        pathname: ({ topic  })=>topic && topic.user ? `/${topic.user.username}/${topic.id}-${slugify(topic.title)}` : "/",
        appMessageTemplate: "Invited you to join a tree: {topic}",
        appMessageVariables: ({ topic  })=>{
            return {
                topic: topic === null || topic === void 0 ? void 0 : topic.title
            };
        },
        appContext: ({ actor  })=>{
            return (ref7 = actor === null || actor === void 0 ? void 0 : actor.username) !== null && ref7 !== void 0 ? ref7 : null;
        },
        emailSubject: (notification)=>`${getUserName(notification.actor)} invited you to join ${getTopicName(notification.topic)} on Sprout`,
        emailTitle: (notification)=>`${getUserName(notification.actor)} invited you to join `,
        emailTitleContext: (notification)=>getTopicName(notification.topic),
        emailButtonText: ()=>`View invite`
    }),
    POSTED_LINK_INTO_TOPIC: mkTemplate({
        pathname: ({ theirPost  })=>theirPost ? getItemUrl(theirPost) : "/",
        appMessageTemplate: "Added a post to: {topic}",
        appMessageVariables: ({ theirPost  })=>{
            var ref21;
            return {
                topic: theirPost === null || theirPost === void 0 ? void 0 : (ref21 = theirPost.parentTopic) === null || ref21 === void 0 ? void 0 : ref21.title
            };
        },
        appContext: ({ actor  })=>{
            return (ref8 = actor === null || actor === void 0 ? void 0 : actor.username) !== null && ref8 !== void 0 ? ref8 : null;
        },
        emailFrom: (notification)=>`Sprout (${getTopicName(notification.topic)})`,
        emailSubject: (notification)=>`${getUserName(notification.actor)} added to ${getTopicName(notification.topic)} on Sprout`,
        emailTitle: (notification)=>`${getUserName(notification.actor)} added to `,
        emailTitleContext: (notification)=>getTopicName(notification.topic),
        emailDescription: (notification)=>getPostDescription(notification.theirPost),
        emailButtonText: ()=>`View`
    }),
    USER_REQUESTED_TO_JOIN_TEAM: mkTemplate({
        // TODO: click path to join?
        pathname: ({ team  })=>team ? `/${team.username}/members` : "/",
        appMessageTemplate: "Requested to join workspace: {team}",
        appMessageVariables: ({ team  })=>{
            return {
                team: (ref10 = (ref9 = team === null || team === void 0 ? void 0 : team.firstName) !== null && ref9 !== void 0 ? ref9 : team === null || team === void 0 ? void 0 : team.username) !== null && ref10 !== void 0 ? ref10 : null
            };
        },
        appContext: ({ actor  })=>{
            return (ref11 = actor === null || actor === void 0 ? void 0 : actor.username) !== null && ref11 !== void 0 ? ref11 : null;
        },
        emailSubject: (notification)=>`${getUserName(notification.actor)} asked to join ${getTeamName(notification.team)} on Sprout`,
        emailTitle: (notification)=>`${getUserName(notification.actor)} asked to join `,
        emailTitleContext: (notification)=>getTeamName(notification.team),
        emailButtonText: ()=>`View request`
    }),
    USER_REQUESTED_TO_JOIN_TOPIC: mkTemplate({
        // TODO: click path to join?
        priority: 20,
        pathname: ({ topic  })=>topic && topic.user ? `/${topic.user.username}/${topic.id}-${slugify(topic.title)}/member` : "/",
        appMessageTemplate: "Requested to join tree: {topic}",
        appMessageVariables: ({ topic  })=>{
            return {
                topic: topic === null || topic === void 0 ? void 0 : topic.title
            };
        },
        appContext: ({ actor  })=>{
            return (ref12 = actor === null || actor === void 0 ? void 0 : actor.username) !== null && ref12 !== void 0 ? ref12 : null;
        },
        emailSubject: (notification)=>`${getUserName(notification.actor)} asked to join ${getTopicName(notification.topic)} on Sprout`,
        emailTitle: (notification)=>`${getUserName(notification.actor)} asked to join `,
        emailTitleContext: (notification)=>getTopicName(notification.topic),
        emailButtonText: ()=>`View request`
    }),
    USER_JOINED_TEAM: mkTemplate({
        pathname: ({ actor  })=>actor ? `/${actor.username}` : "/",
        appMessageTemplate: "Joined the workspace: {team}",
        appMessageVariables: ({ team  })=>{
            return {
                team: (team === null || team === void 0 ? void 0 : team.firstName) || (team === null || team === void 0 ? void 0 : team.username)
            };
        },
        appContext: ({ actor  })=>{
            return (ref13 = actor === null || actor === void 0 ? void 0 : actor.username) !== null && ref13 !== void 0 ? ref13 : null;
        },
        emailSubject: (notification)=>`${getUserName(notification.actor)} joined ${getTeamName(notification.team)} on Sprout`,
        emailTitle: (notification)=>`${getUserName(notification.actor)} joined ${getTeamName(notification.team)}`
    }),
    USER_JOINED_TOPIC: mkTemplate({
        pathname: ({ topic  })=>topic && topic.user ? `/${topic.user.username}/${topic.id}-${slugify(topic.title)}` : "/",
        priority: 10,
        appMessageTemplate: "Joined the tree: {topic}",
        appMessageVariables: ({ topic  })=>{
            return {
                topic: topic === null || topic === void 0 ? void 0 : topic.title
            };
        },
        appContext: ({ actor  })=>{
            return (ref14 = actor === null || actor === void 0 ? void 0 : actor.username) !== null && ref14 !== void 0 ? ref14 : null;
        },
        emailSubject: (notification)=>`${getUserName(notification.actor)} is now a member of ${getTopicName(notification.topic)} on Sprout`,
        emailTitle: (notification)=>`${getUserName(notification.actor)} is now a member of ${getTopicName(notification.topic)}`
    }),
    ASSIGNED_ITEM: mkTemplate({
        pathname: ({ ourPost  })=>ourPost ? getItemUrl(ourPost) : "/",
        appMessageTemplate: "Assigned: {item}",
        appMessageVariables: ({ ourPost  })=>({
                item: getAppPostDescription(ourPost)
            }),
        appContext: ({ actor , ourPost  })=>{
            return [
                actor === null || actor === void 0 ? void 0 : actor.username,
                ourPost ? getRootPostParentTopicName(ourPost) : null, 
            ].filter(Boolean).join(" • ");
        },
        emailFrom: ({ ourPost  })=>ourPost ? getEmailFromTopicTitle(ourPost) : undefined,
        emailSubject: (notification)=>`${getUserName(notification.actor)} assigned ${getPostDescription(notification.ourPost)} to ${notification.userId === notification.viewerId ? "you" : getUserName(notification.user)} on Sprout`,
        emailTitle: (notification)=>`${getUserName(notification.actor)} assigned ${getPostDescription(notification.ourPost)} to ${notification.userId === notification.viewerId ? "you" : getUserName(notification.user)}`,
        emailDescription: (notification)=>getPostDescription(notification.ourPost),
        emailButtonText: ()=>`View`
    }),
    COMPLETED_ASSIGNED: mkTemplate({
        pathname: ({ ourPost  })=>ourPost ? getItemUrl(ourPost) : "/",
        appMessageTemplate: "Completed: {item}",
        appMessageVariables: ({ ourPost  })=>({
                item: getAppPostDescription(ourPost)
            }),
        appContext: ({ actor , ourPost  })=>{
            return [
                actor === null || actor === void 0 ? void 0 : actor.username,
                ourPost ? getRootPostParentTopicName(ourPost) : null, 
            ].filter(Boolean).join(" • ");
        },
        emailFrom: ({ ourPost  })=>ourPost ? getEmailFromTopicTitle(ourPost) : undefined,
        emailSubject: (notification)=>`${getUserName(notification.actor)} completed assigned item ${getPostDescription(notification.ourPost)} on Sprout`,
        emailTitle: (notification)=>`${getUserName(notification.actor)} completed assigned item ${getPostDescription(notification.ourPost)}`,
        emailDescription: (notification)=>getPostDescription(notification.ourPost),
        emailButtonText: ()=>`View`
    }),
    /**
   * Notification types below are technically not used in production for emails
   * TODO: disable them?
   */ YOU_JOINED_TOPIC: mkTemplate({
        pathname: ({ topic  })=>topic ? getItemUrl(topic, topic.user) : "/",
        priority: 25,
        appContext: ({ actor  })=>{
            return (ref15 = actor === null || actor === void 0 ? void 0 : actor.username) !== null && ref15 !== void 0 ? ref15 : null;
        },
        emailSubject: (notification)=>`You are now a member of ${getTopicName(notification.topic)} on Sprout`,
        emailTitle: (notification)=>`You are now a member of ${getTopicName(notification.topic)}`
    }),
    ITEM_WHEN_SOON: mkTemplate({
        // TODO: change `ourPost` to `item` ? since it can be a `TodoList` or `Todo` now
        pathname: ({ ourPost  })=>getItemUrl(ourPost),
        appMessageTemplate: "Reminder: {description}",
        appMessageVariables: ({ ourPost  })=>({
                description: getAppPostDescription(ourPost)
            }),
        appContext: ({ ourPost  })=>{
            return [
                "Sprout",
                ourPost ? getRootPostParentTopicName(ourPost) : null
            ].filter(Boolean).join(" • ");
        },
        emailFrom: ({ ourPost  })=>ourPost ? getEmailFromTopicTitle(ourPost) : undefined,
        emailSubject: ({ ourPost  })=>`Reminder: ${getPostDescription(ourPost)}`,
        emailTitle: ()=>"Reminder",
        emailDescription: ({ ourPost  })=>getPostDescription(ourPost),
        emailButtonText: ()=>`View`
    }),
    ITEM_DEADLINE_SOON_1: mkTemplate({
        // TODO: change `ourPost` to `item` ? since it can be a `TodoList` or `Todo` now
        pathname: ({ ourPost  })=>getItemUrl(ourPost),
        appMessageTemplate: "Due soon: {description}",
        appMessageVariables: ({ ourPost  })=>({
                description: getAppPostDescription(ourPost)
            }),
        appContext: ({ ourPost  })=>{
            return [
                "Sprout",
                ourPost ? getRootPostParentTopicName(ourPost) : null
            ].filter(Boolean).join(" • ");
        },
        emailFrom: ({ ourPost  })=>ourPost ? getEmailFromTopicTitle(ourPost) : undefined,
        emailSubject: ({ ourPost  })=>`Due soon: ${getPostDescription(ourPost)}`,
        emailTitle: ()=>"Due soon",
        emailDescription: ({ ourPost  })=>getPostDescription(ourPost),
        emailButtonText: ()=>`View`
    }),
    ITEM_DEADLINE_SOON_2: mkTemplate({
        // TODO: change `ourPost` to `item` ? since it can be a `TodoList` or `Todo` now
        pathname: ({ ourPost  })=>getItemUrl(ourPost),
        appMessageTemplate: "Due today: {description}",
        appMessageVariables: ({ ourPost  })=>({
                description: getAppPostDescription(ourPost)
            }),
        appContext: ({ ourPost  })=>{
            return [
                "Sprout",
                ourPost ? getRootPostParentTopicName(ourPost) : null
            ].filter(Boolean).join(" • ");
        },
        emailFrom: ({ ourPost  })=>ourPost ? getEmailFromTopicTitle(ourPost) : undefined,
        emailSubject: ({ ourPost  })=>`Due today: ${getPostDescription(ourPost)}`,
        emailTitle: ()=>"Due today",
        emailDescription: ({ ourPost  })=>getPostDescription(ourPost),
        emailButtonText: ()=>`View`
    })
};
export * from "./helpers";
